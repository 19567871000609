import React, { useState } from 'react';
import './Navigation.css';
import { BrowserRouter, Route, NavLink, Switch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { HashLink } from 'react-router-hash-link';

import Home from '../../pages/Home/Home';
import FAQ from '../../pages/FAQ/FAQ';
import About from '../../pages/About/About';
import Customs from '../../pages/Customs/Customs';
import Escrow from '../../pages/Escrow/Escrow';
import Ranking from '../../pages/Ranking/Ranking';
import News from '../../pages/News/News';
import Rewards from '../../pages/Rewards/Rewards';
import Tutorials from '../../pages/Tutorials/Tutorials';
import Privacy from '../../pages/Privacy/Privacy';
import Terms from '../../pages/Terms/Terms';
import Licence from '../../pages/Licence/Licence';
import Green from '../../pages/Green/Green';
import ScrollToTop from './ScrollToTop'
import DownloadApp from '../../pages/DownloadApp/DownloadApp'
import NotFound from '../../pages/NotFound/NotFound';

const Navigation = () => {
  const { t } = useTranslation();
  const [ menuExpanded, setMenuExpanded ] = useState(false);

  const toggleMenu = () => {
    setMenuExpanded(!menuExpanded);
  }

  const hideMenu = () => {
    setMenuExpanded(false);
  }

  const menuClass = menuExpanded ? "fa fa-times" : "fa fa-bars";

  return (
    <BrowserRouter>
      <ScrollToTop />
      {window.location.pathname !== '/green' && <header>
        <nav className="navbar navbar-custom navbar-top navbar-fixed-top sticky-navigation" >
          <div className="container">
            <div className="navbar-header">
              <button type="button" className="navbar-toggle" data-toggle="collapse" data-target=".navbar-main-collapse">
                <i className={menuClass} onClick={() => toggleMenu()}/>
              </button>
              <NavLink exact={true} className="navbar-brand nav-logo page-scroll" activeClassName='active' to="/">
                <img src="/images/logo_xtaypro_20170705_2210.png" alt="XtayPro"/>
              </NavLink>
            </div>
            <div className={["collapse navbar-collapse navbar-main-collapse", menuExpanded && "in"].join(' ')}>
              <ul onClick={() => hideMenu()} className="nav navbar-nav">
                <li><HashLink to="/#intro">{t('navigation.home')}</HashLink></li>
                <li><HashLink to="/#screenshots">{t('navigation.screenshots')}</HashLink></li>
                <li><NavLink exact={true} activeClassName='active' to="/news">{t('navigation.news')}</NavLink></li>
                <li><NavLink exact={true} activeClassName='active' to="/faq">{t('navigation.faq')}</NavLink></li>
                <li><NavLink exact={true} activeClassName='active' to="/tutorials">{t('navigation.tutorials')}</NavLink></li>
                <li><NavLink exact={true} activeClassName='active' to="/escrow">{t('navigation.escrow')}</NavLink></li>
                <li><NavLink exact={true} activeClassName='active' to="/ranking">{t('navigation.ranking')}</NavLink></li>
                <li><NavLink exact={true} activeClassName='active' to="/customs">{t('navigation.customs')}</NavLink></li>
                <li><NavLink exact={true} activeClassName='active' to="/rewards">{t('navigation.rewards')}</NavLink></li>
                <li><NavLink exact={true} activeClassName='active' to="/about">{t('navigation.about')}</NavLink></li>
              </ul>
            </div>
          </div>
        </nav>
      </header>}
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/news" component={News} />
        <Route path="/faq" component={FAQ} />
        <Route path="/tutorials" component={Tutorials} />
        <Route path="/escrow" component={Escrow} />
        <Route path="/ranking" component={Ranking} />
        <Route path="/customs" component={Customs} />
        <Route path="/rewards" component={Rewards} />
        <Route path="/about" component={About} />
        <Route path="/privacy" component={Privacy} />
        <Route path="/terms" component={Terms} />
        <Route path="/app" component={DownloadApp} />
        <Route path="/licence_acknowledgement" component={Licence} />
        <Route path="/green" component={Green} />
        <Route component={NotFound} />
      </Switch>
    </BrowserRouter>
  );
}

export default Navigation;
