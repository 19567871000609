import React from 'react';
import { useTranslation } from 'react-i18next';

const Licence = () => {
  const { t } = useTranslation();

  return (
    <section class="section">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="titleSection">
              <h2>{t('licence.heading')}</h2>
              <div class="coloredLine"></div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-offset-1 col-sm-10 col-xs-12">
            <div class="row text-center">
              <div class="col-xs-offset-3 col-xs-6">
                <a href="/licences/freepik.txt" download class="list-group-item">Freepik</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Licence;
