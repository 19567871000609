import React from 'react';

const PrivacyEn = () => {
  return (
    <>
      <section id="privacy" className="section noselect" >
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="titleSection">
                <h2>USER PRIVACY STATEMENT</h2>
                <div className="coloredLine"></div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-8 col-md-offset-2 col-sm-10 col-sm-offset-1 col-xs-12">
              <div className="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                <div className="panel panel-default">
                  <div className="panel-body">
                    <p>Updated: July 1st, 2017</p>
                    <p>XtayPro collects information about you when you use our mobile applications, websites, and other online products and services (collectively, the “Services”) and through other interactions and communications you have with us. The Services are provided by XtayPro and its affiliates and this Privacy Statement applies to information collected and used by XtayPro. (XtayPro are referred to herein collectively as “XtayPro” or “we”).</p>
                  </div>
                </div>
                <div className="panel panel-default">
                  <div className="panel-heading">
                   <h4 className="panel-title">Scope and Application</h4>
                  </div>
                  <div className="panel-body">
                    This Privacy Statement (“Statement") applies to persons anywhere in the world who use our apps or Services to request or offer delivery, or other on-demand services (“Xtayers” and “Xtayees” or collectively “Users”). This Statement does not apply to information we collect from or about couriers, partner transportation companies, or any other persons who use the XtayPro platform under license (collectively “Partners”). If you interact with the Services as both a User and a Partner, the respective privacy statements apply to your different interactions.
                  </div>
                </div>
                <div className="panel panel-default">
                  <div className="panel-heading">
                   <h4 className="panel-title">Collection of Information</h4>
                  </div>
                  <div className="panel-body">
                   <p className="bold">Information You Provide to Us</p>
                   <p>We collect information you provide directly to us, such as when you create or modify your account, request on-demand services, contact customer support, or otherwise communicate with us. This information may include: name, email, phone number, postal address, profile picture, payment method, items requested (for delivery services), itinerary, travel information, delivery notes, and other information you choose to provide.</p>
                  </div>
                  <div className="panel-body">
                   <p className="bold">Information We Collect Through Your Use of Our Services</p>
                   <p>When you use our Services, we collect information about you in the following general categories:</p>
                   <ul>
                    <li><span className="bold">Registration as a XtayPro Member:</span> In connection with your registration as a XtayPro Member, you may be asked to provide certain personal information, such as your name, email address, mailing address, date of birth or age, and a picture of a document such as your driver’s license or other form of ID.</li>
                    <li><span className="bold">Sign-Up for Emails:</span> If you sign up on one of our Sites to receive email communications from us, you may be asked to provide us with your name or e-mail address.</li>
                    <li><span className="bold">Location Information:</span> When you use the Services for delivery, or other on-demand services, we collect precise location data about the trip from the XtayPro app used by the Partner. If you permit the XtayPro app to access location services through the permission system used by your mobile operating system (“platform”), we may also collect the precise location of your device when the app is running in the foreground or background. We may also derive your approximate location from your IP address.</li>
                    <li><span className="bold">Contacts Information:</span> If you permit the XtayPro app to access the address book on your device through the permission system used by your mobile platform, we may access and store names and contact information from your address book to facilitate social interactions through our Services and for other purposes described in this Statement or at the time of consent or collection.</li>
                    <li><span className="bold">Transaction Information:</span> We collect transaction details related to your use of our Services, including the type of service requested, date and time the service was provided. All content that you create, share or post in audio, video, text, images, and other media or software files that you provide on or through the Services, or that are provided on your behalf, including information in or about the content you provide, such as the location of a photo or the date a file was created; When engage in any financial transaction on one of our Sites, you might be asked to provide information necessary to facilitate the transaction, such as your credit card or debit card number, associated security code, telephone number, and billing address. Additionally, if someone uses your promo code, we may associate your name with that person.</li>
                    <li><span className="bold">Usage and Preference Information:</span> We collect information about how you and site visitors interact with our Services, preferences expressed, and settings chosen. In some cases we do this through the use of cookies, pixel tags, and similar technologies that create and maintain unique identifiers. To learn more about these technologies, please see our <a href="#cookie">Cookie Statement</a>.</li>
                    <li><span className="bold">Device Information:</span> We may collect information about your mobile device, including, for example, the hardware model, operating system and version, software and file names and versions, preferred language, unique device identifier, advertising identifiers, serial number, device motion information, and mobile network information.</li>
                    <li><span className="bold">Call and SMS Data:</span> Our Services facilitate communications between Users and Partners. In connection with facilitating this service, we receive call data, including the date and time of the call or SMS message, the parties’ phone numbers, and the content of the SMS message.</li>
                    <li><span className="bold">Log Information:</span> When you interact with the Services, we collect server logs, which may include information like device IP address, access dates and times, app features or pages viewed, app crashes and other system activity, type of browser, and the third-party site or service you were using before interacting with our Services.</li>
                    <li><span className="bold">Contests, Sweepstakes, and Other Promotions:</span> We may collect information about you when you participate in a promotion that we sponsor, such as a contest or sweepstakes (which we may sponsor alone or co-sponsor with one or more third parties). To participate in one of our promotions, you may be asked to provide your name and your email address, mailing address and/or other contact information. For some promotions, we may request other information about you as well.</li>
                    <li><span className="bold">Interactive Forums:</span> You and other Members may be allowed to post comments and other content on certain portions of our Sites, such as on a discussion board, blog or other interactive forum. This includes parts of our Sites where Members can post offers and requests for peer-to-peer shipping services. This will often include travel itineraries or a listing of goods intended to be shipped. Any such information posted by a Member will be considered as posted publicly with consent. Members must be mindful of the information they post and understand that once content is posted, it is viewable by other Members. We may collect any information that you or another user posts about you on one of our Sites, including personal information about you, and it may be available to all users who have access to the Site. Please keep this in mind when considering what information to post to our Sites.</li>
                    <li><span className="bold">Social Networking Activities:</span> Our Sites may include the ability for you and other users to link to one or more social networking platforms (such as Facebook or Twitter). We may receive information about you when you choose to post or otherwise share information about us on any of these social networking platforms. Note that any information that you or others post to a social networking platform will be accessible by any users of such platform, subject to its terms of service and privacy policy.</li>
                    <li><span className="bold">Social Sign-In:</span> Our Sites may incorporate functionality enabling you to sign-on using your login information from a social networking platform, in lieu of creating a new login account specifically for the Site. If we incorporate such functionality and you use it to sign into any of our Sites, we may collect information about you from the social networking platform, such as your list of friends, “likes,” and interests, as well as your contact information.</li>
                    <li><span className="bold">Third-Party Integrated Services:</span> We may incorporate third-party ads and other third-party content on our Sites, and integrate other third-party offerings into the Sites, enabling certain such third parties to collect information about you or your online activities across other websites and over time through your use of our Sites. Any information collected through such integrated technologies is collected directly by these third parties for use by such third parties and other authorized parties, subject to the privacy policies of those other parties.</li>
                    <li><span className="bold">Online Inquiries and Correspondence:</span> When you submit an inquiry or otherwise correspond through one of our Sites, you may be asked to provide your name, email address, and/or other personal information to us.</li>
                   </ul>
                  </div>
                  <div className="panel-body">
                   <p className="bold">Important Information about Platform Permissions</p>
                   <p>Most mobile platforms (iOS, Android, etc.) have defined certain types of device data that apps cannot access without your consent. And these platforms have different permission systems for obtaining your consent. The iOS platform will alert you the first time the XtayPro app wants permission to access certain types of data and will let you consent (or not consent) to that request. Android devices will notify you of the permissions that the XtayPro app seeks before you first use the app, and your use of the app constitutes your consent. Sometimes these permissions require more explanation than the platforms themselves provide, and the permissions we request will change over time.</p>
                  </div>
                  <div className="panel-body">
                   <p className="bold">Information We Collect From Other Sources</p>
                   <p>We may also receive information from other sources and combine that with information we collect through our Services. For example:</p>
                   <ul>
                    <li>If you choose to link, create, or log in to your XtayPro account with a payment provider (e.g., Google Wallet) or social media service (e.g., Facebook), or if you engage with a separate app or website that uses our API (or whose API we use), we may receive information about you or your connections from that site or app.</li>
                    <li>If your employer uses one of our enterprise solutions, such as XtayPro for Business, we may receive information about you from your employer.</li>
                    <li>When you request on demand services, our Partners may provide us with a User rating after providing services to you.</li>
                    <li>If you also interact with our Services in another capacity, for instance as a Partner or user of other apps we provide, we may combine or associate that information with information we have collected from you in your capacity as a XtayPro User or Partner.</li>
                   </ul>
                  </div>
                </div>
                <div className="panel panel-default">
                  <div className="panel-heading">
                   <h4 className="panel-title">Use of Information</h4>
                  </div>
                  <div className="panel-body">
                   <p>We may use information about you collected through our Sites for a variety of commercial purposes.</p>
                   <p>Without limitation, we may use such information to:</p>
                   <ul>
                     <li>Provide, maintain, and improve our Services, including, for example, to facilitate payments, send receipts, provide products and services you request (and send related information), develop new features, provide customer support to Users and Partners, develop safety features, authenticate users, and send product updates and administrative messages;</li>
                     <li>Perform internal operations, including, for example, to prevent fraud and abuse of our Services; to troubleshoot software bugs and operational problems; to conduct data analysis, testing, and research; and to monitor and analyze usage and activity trends;</li>
                     <li>Send or facilitate communications (i) between you and a Partner, such as estimated expense, or (ii) between you and a contact of yours at your direction in connection with your use of certain features, such as referrals, invites, split capacity requests, or cost estimation sharing;</li>
                     <li>Send you communications we think will be of interest to you, including information about products, services, promotions, news, and events of XtayPro and other companies, where permissible and according to local applicable laws; and to process contest, sweepstake, or other promotion entries and fulfill any related awards;</li>
                     <li>Personalize and improve the Services, including to provide or recommend features, content, social connections, referrals, and advertisements.</li>
                   </ul>
                  </div>
                  <div className="panel-body">
                   We may transfer the information described in this Statement to, and process and store it in, Singapore and other countries, some of which may have less protective data protection laws than the region in which you reside. Where this is the case, we will take appropriate measures to protect your personal information in accordance with this Statement.
                  </div>
                </div>
                <div className="panel panel-default">
                 <div className="panel-heading">
                  <h4 className="panel-title">Sharing of Information</h4>
                 </div>
                 <div className="panel-body">
                  We may share the information we collect about you as described in this Statement or as described at the time of collection or sharing, including as follows:
                 </div>
                 <div className="panel-body">
                  <p className="bold">Through Our Services</p>
                  <p>We may share your information:</p>
                  <ul>
                   <li>With Partners to enable them to provide the Services you request. For example, we share your name, photo (if you provide one), average User rating given by Partners, and pickup and/or delivery locations with Partners;</li>
                   <li>With other Xtayers if you use a route-sharing service like XtayPro Transit; and with other people, as directed by you, such as when you want to share your transport capacity, request or split expense with a friend;</li>
                   <li>With third parties to provide you a service you requested through a partnership or promotional offering made by a third party or us;</li>
                   <li>With the general public if you submit content in a public forum, such as blog comments, social media posts, or other features of our Services that are viewable by the general public;</li>
                   <li>With third parties with whom you choose to let us share information, for example other apps or websites that integrate with our API or Services, or those with an API or Service with which we integrate; and</li>
                   <li>With your employer (or similar entity) and any necessary third parties engaged by us or your employer (e.g., an expense management service provider), if you participate in any of our enterprise solutions such as XtayPro for Business.</li>
                  </ul>
                 </div>
                 <div className="panel-body">
                  <p className="bold">Other Important Sharing</p>
                  <p>We may share your information:</p>
                  <ul>
                   <li>With XtayPro subsidiaries and affiliated entities that provide services or conduct data processing on our behalf, or for data centralization and / or logistics purposes;</li>
                   <li>With vendors, consultants, marketing partners, and other service providers who need access to such information to carry out work on our behalf;</li>
                   <li>In response to a request for information by a competent authority if we believe disclosure is in accordance with, or is otherwise required by, any applicable law, regulation, or legal process;</li>
                   <li>With law enforcement officials, government authorities, or other third parties if we believe your actions are inconsistent with our User agreements, Terms of Service, or policies, or to protect the rights, property, or safety of XtayPro or others;</li>
                   <li>In connection with, or during negotiations of, any merger, sale of company assets, consolidation or restructuring, financing, or acquisition of all or a portion of our business by or into another company;</li>
                   <li>If we otherwise notify you and you consent to the sharing; and</li>
                   <li>In an aggregated and/or anonymized form which cannot reasonably be used to identify you.</li>
                  </ul>
                 </div>
                </div>
                <div className="panel panel-default">
                 <div className="panel-heading">
                  <h4 className="panel-title">Social Sharing Features</h4>
                 </div>
                 <div className="panel-body">
                  The Services may integrate with social sharing features and other related tools which let you share actions you take on our Services with other apps, sites, or media, and vice versa. Your use of such features enables the sharing of information with your friends or the public, depending on the settings you establish with the social sharing service. Please refer to the privacy policies of those social sharing services for more information about how they handle the data you provide to or share through them.
                 </div>
                </div>
                <div className="panel panel-default">
                 <div className="panel-heading">
                  <h4 className="panel-title">Analytics and Advertising Services Provided by Others</h4>
                 </div>
                 <div className="panel-body">
                  We may allow others to provide audience measurement and analytics services for us, to serve advertisements on our behalf across the Internet, and to track and report on the performance of those advertisements. These entities may use cookies, web beacons, SDKs, and other technologies to identify your device when you visit our site and use our Services, as well as when you visit other online sites and services. For more information about these technologies and service providers, please refer to our <a href="#cookie">Cookie Statement</a>.
                 </div>
                </div>
                <div className="panel panel-default">
                 <div className="panel-heading">
                  <h4 className="panel-title">Security Measures for Information Collected Through Our Sites</h4>
                 </div>
                 <div className="panel-body">
                  <p>To help protect the privacy of information collected through our Sites, we employ security measures that are intended to be consistent with industry best practices. In addition, we take commercially reasonable steps to store such information with the objective of protecting such data against unauthorized access. Notwithstanding the foregoing, please be aware that our Sites are operated on software, hardware and networks, any component of which may, from time to time, experience breaches of security or other problems beyond our control and that may compromise the security of information collected about you.</p>
                  <p>Please also be aware that despite our best intentions and the guidelines outlined in this privacy policy, no data transmission over the Internet or via a Wi-Fi network, or any encryption method, can be guaranteed to be completely secure. You understand that it may be possible for third parties not under XtayPro’s control to intercept or access your personal information transmitted through one of our Sites or via email. Accordingly, although we undertake commercially reasonable steps to protect the security and integrity of all information you provide through our Sites, due to the inherent nature of the Internet, we cannot guarantee that any such information will be absolutely safe from intrusion by others who do not have your authorization. Any such transmission of information about you to XtayPro is at your own risk.</p>
                 </div>
                </div>
                <div className="panel panel-default">
                 <div className="panel-heading">
                  <h4 className="panel-title">Children</h4>
                 </div>
                 <div className="panel-body">
                  Our Sites are not designed to appeal to persons under 13 years old (“children”). We do not knowingly collect or maintain personal information from children through our Sites, except for persistent identifiers that may be collected through children’s sections of our Sites which (as permitted by applicable law) we will use solely to support the internal operations of the Sites. If we learn that any unauthorized personal information of a child has been collected, we will take appropriate steps to delete such information. If you are a parent or guardian and discover that your child has provided any such personal information to us, then you may contact us at the email or mailing address noted at the end of this privacy policy below and request that we delete that information from our systems.
                 </div>
                </div>
                <div className="panel panel-default">
                 <div className="panel-heading">
                  <h4 className="panel-title">Your Choices</h4>
                 </div>
                 <div className="panel-body">
                  <p className="bold">Account Information</p>
                  <p>You may correct your account information at any time by logging into your online or in-app account. If you wish to cancel your account, please email us at <a href="mailto:info@xtaypro.com">info@XtayPro.com</a>. Please note that in some cases we may retain certain information about you as required by law, or for legitimate business purposes to the extent permitted by law. For instance, if you have a standing credit or debt on your account, or if we believe you have committed fraud or violated our Terms, we may seek to resolve the issue before deleting your information.</p>
                 </div>
                 <div className="panel-body">
                  <p className="bold">Access Rights</p>
                  <p>XtayPro will comply with individual’s requests regarding access, correction, and/or deletion of the personal data it stores in accordance with applicable law.</p>
                 </div>
                 <div className="panel-body">
                  <p className="bold">Location Information</p>
                  <p>We request permission for our app’s collection of precise location from your device per the permission system used by your mobile operating system. If you initially permit the collection of this information, you can later disable it by changing the location settings on your mobile device. However, this will limit your ability to use certain features of our Services. Additionally, disabling our app’s collection of precise location from your device will not limit our ability to collect your trip location information from a Partner's device nor our ability to derive approximate location from your IP address.</p>
                 </div>
                 <div className="panel-body">
                  <p className="bold">Contact Information</p>
                  <p>We may also seek permission for our app’s collection and syncing of contact information from your device per the permission system used by your mobile operating system. If you initially permit the collection of this information, iOS users can later disable it by changing the contacts settings on your mobile device. The Android platform does not provide such a setting.</p>
                 </div>
                 <div className="panel-body">
                  <p className="bold">Promotional Communications</p>
                  <p>You may opt out of receiving promotional messages from us by following the instructions in those messages. If you opt out, we may still send you non-promotional communications, such as those about your account, about Services you have requested, or our ongoing business relations.</p>
                 </div>
                 <div className="panel-body">
                  <p className="bold">Cookies and Advertising</p>
                  <p>Please refer to our <a href="#cookie">Cookie Statement</a> for more information about your choices around cookies and related technologies.</p>
                 </div>
                </div>
                <div className="panel panel-default">
                 <div className="panel-heading">
                  <h4 className="panel-title">Privacy Policies of any Linked Websites or Other Parties</h4>
                 </div>
                 <div className="panel-body">
                  This privacy policy only addresses XtayPro’s practices for the personal (and other) information that we may obtain about you through any of the Sites. Please be aware that when you use or access any of the Sites, you may be directed to other websites that are beyond our control, and XtayPro is not responsible for the privacy practices of third parties or the content of linked websites. We would expect most, if not all, of these third parties will collect information about you when you use their offerings. However, XtayPro is not responsible for the privacy practices of third parties, regardless whether they are linked or otherwise connected to our Sites. We encourage you to read the posted privacy policy and terms of use whenever interacting with any third-party website, app, or other offering.
                 </div>
                </div>
                <div className="panel panel-default">
                 <div className="panel-heading">
                  <h4 className="panel-title">Governing Law</h4>
                 </div>
                 <div className="panel-body">
                  By choosing to visit any of our Sites, you agree that any dispute over privacy or the terms contained in this privacy policy will be governed by the law of the country of destination, without reference to the choice of law or conflicts of law principles thereof, and will be subject to the dispute resolution clause contained in the terms of use applicable to the particular Site. You also agree to abide by any limitation on damages contained in the terms of use applicable to the Site or any other agreement that we have with you.
                 </div>
                </div>
                <div className="panel panel-default">
                 <div className="panel-heading">
                  <h4 className="panel-title">Changes to the Statement</h4>
                 </div>
                 <div className="panel-body">
                  We may change this Statement from time to time. If we make significant changes in the way we treat your personal information, or to the Statement, we will provide you notice through the Services or by some other means, such as email. Your continued use of the Services after such notice constitutes your consent to the changes. We encourage you to periodically review the Statement for the latest information on our privacy practices.
                 </div>
                </div>
                <div className="panel panel-default">
                 <div className="panel-heading">
                  <h4 className="panel-title">Contact Us</h4>
                 </div>
                 <div className="panel-body">
                  If you have any questions about this Privacy Statement, please contact us at <a href="mailto:info@xtaypro.com">info@XtayPro.com</a>, or write us at XtayPro.
                 </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="cookie" className="section" >
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="titleSection">
                <h2>COOKIE STATEMENT (GLOBAL)</h2>
                <div className="coloredLine"></div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-8 col-md-offset-2 col-sm-10 col-sm-offset-1 col-xs-12">
              <div className="panel-group">
                <div className="panel panel-default">
                  <div className="panel-body">
                   We and our affiliates, third parties, and other partners use cookies and other identification technologies on our websites, mobile applications, electronic communications, advertisements, and other online services (collectively, the "Services") for a number of purposes, including: authenticating users, remembering user preferences and settings, determining the popularity of content, delivering and measuring the effectiveness of advertising campaigns, analyzing site traffic and trends, and generally understanding the online behaviors and interests of people who interact with our Services. You can read more here about the types of cookies we use, why we use them, and how you can exercise your choices.
                  </div>
                </div>
                <div className="panel panel-default">
                  <div className="panel-heading" role="tab" id="headingOne">
                   <h4 className="panel-title">
                    Cookies Overview
                   </h4>
                  </div>
                  <div className="panel-body">
                   Cookies are small text files that are stored on your browser or device by websites, apps, online media, and advertisements. There are different types of cookies. Cookies served by the entity that operates the domain you are visiting are called “first party cookies.” So cookies served by XtayPro while you are on <a href="https://www.xtaypro.com">www.XtayPro.com</a> are first party cookies. Cookies served by companies that are not operating the domain you’re visiting are called “third party cookies.” So, we may allow Google to set a cookie on your browser while you visit <a href="https://www.xtaypro.com">www.XtayPro.com</a>, and that would be a third party cookie. Cookies may also endure for different periods of time. “Session Cookies” only last only as long as your browser is open. These are deleted automatically once you close your browser. Other cookies are “persistent cookies” meaning that they survive after your browser is closed. For example, they may recognize your device when you re-open your browser and browse the internet again.
                  </div>
                </div>
                <div className="panel panel-default">
                  <div className="panel-heading" role="tab" id="headingOne">
                   <h4 className="panel-title">
                    Other Identification Technologies
                   </h4>
                  </div>
                  <div className="panel-body">
                   We call this a Cookie Statement, but the statement addresses cookies and other identification technologies we may use or permit on our Services. “Pixel tags” (also called beacons or pixels) are small blocks of code installed on (or called by) a webpage, app, or advertisement which can retrieve certain information about your device and browser, including for example: device type, operating system, browser type and version, website visited, time of visit, referring website, IP address, and other similar information, including the small text file (the cookie) that uniquely identifies the device. Pixels provide the means by which third parties can set and read browser cookies from a domain that they do not themselves operate and collect information about visitors to that domain, typically with the permission of the domain owner. “Local storage” refers generally to other places on a browser or device where information can be stored by websites, ads, or third parties (such as HTML5 local storage and browser cache). “Software Development Kits” (also called SDKs) function like pixels and cookies, but operate in the mobile app context where pixels and cookies cannot always function. The primary app developer can install pieces of code (the SDK) from partners in the app, and thereby allow the partner to collect certain information about user interaction with the app and information about the user device and network information.
                  </div>
                </div>
                <div className="panel panel-default">
                  <div className="panel-heading" role="tab" id="headingOne">
                   <h4 className="panel-title">
                    Your Choices
                   </h4>
                  </div>
                  <div className="panel-body">
                   <p>You have the right to choose whether or not to accept cookies. However, they are an important part of how our Services work, so you should be aware that if you choose to refuse or remove cookies, this could affect the availability and functionality of the Services.</p>
                   <p>Most web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your browser to remove or reject browser cookies. To do so, please follow the instructions provided by your browser which are usually located within the "Help" or “Preferences” menu. Some third parties also provide the ability to refuse their cookies directly by clicking on an opt-out link, and we have indicated where this is possible in the table above.</p>
                   <p>Removing or rejecting browser cookies does not necessarily affect third-party flash cookies which may be used by us or our partners in connection with our Services. To delete or disable flash cookies please visit this site for more information. For further information about cookies, including how to see what cookies have been set on your device and how to manage and delete them, you can visit <a href="www.allaboutcookies.org" rel="nofollow">www.allaboutcookies.org</a> and <a href="www.youronlinechoices.eu" rel="nofollow">www.youronlinechoices.eu</a>.</p>
                  </div>
                </div>
                <div className="panel panel-default">
                  <div className="panel-heading" role="tab" id="headingOne">
                   <h4 className="panel-title">
                    Types and Purposes of Cookies
                   </h4>
                  </div>
                  <div className="panel-body">
                   The following table sets out the different categories of cookies that our Services use and why we use them. The lists of third party cookie providers are intended merely as illustrative not comprehensive.
                   <table className="table table-striped table-bordered">
                    <thead>
                     <tr>
                      <td>Type of Cookie</td>
                      <td>Purpose</td>
                      <td>Who Serves (for example)</td>
                     </tr>
                    </thead>
                    <tbody>
                     <tr>
                      <td>Authentication Cookies</td>
                      <td>These cookies (including local storage and similar technologies) tell us when you’re logged in, so we can show you the appropriate experience and features such as your account information, trip history, and to edit your account settings.</td>
                      <td>
                       <ul>
                        <li>XtayPro</li>
                       </ul>
                      </td>
                     </tr>
                     <tr>
                      <td>Security and site integrity cookies</td>
                      <td>We use these cookies to support or enable security features to help keep XtayPro safe and secure. For example, they enable us to remember when you are logged into a secure area of the Services andhelp protect your account from being accessed by anyone other than you.</td>
                      <td>
                       <ul>
                        <li>XtayPro</li>
                       </ul>
                      </td>
                     </tr>
                     <tr>
                      <td>Localization</td>
                      <td>These help XtayPro provide a localized experience. For example, we may store information in a cookie that is placed on your browser or device so you will see the site in your preferred language.</td>
                      <td>
                       <ul>
                        <li>XtayPro</li>
                       </ul>
                      </td>
                     </tr>
                     <tr>
                      <td>Site features and Services</td>
                      <td>These provide functionality that help us deliver products and Services. For example, cookies help you log in by pre-filling fields. We may also use cookies and similar technologies to help us provide you and others with social plugins and other customized content and experiences, such as making suggestions to you and others.</td>
                      <td>
                       <ul>
                        <li>XtayPro</li>
                        <li>Facebook</li>
                        <li>Twitter</li>
                        <li>Google</li>
                       </ul>
                      </td>
                     </tr>
                     <tr>
                      <td>Analytics and research</td>
                      <td>These are used to understand, improve, and research products and Services, including when you access the XtayPro website and related websites and apps from a computer or mobile device. For example, we may use cookies to understand how you are using site features, and segmenting audiences for feature testing. We and our partners may use these technologies and the information we receive to improve and understand how you use websites, apps, products, services and ads.</td>
                      <td>
                       <ul>
                        <li>Google</li>
                        <li>MixPanel</li>
                        <li>Optimizely</li>
                       </ul>
                      </td>
                     </tr>
                     <tr>
                      <td>Advertising</td>
                      <td>Things like cookies and pixels are used to deliver relevant ads, track ad campaign performance and efficiency. For example, we and our ad partners may rely on information gleaned through these cookies to serve you ads that may be interesting to you on other websites. Similarly, our partners may use a cookie, attribution service or another similar technology to determine whether we’ve served an ad and how it performed or provide us with information about how you interact with them. For more information about interest-based ads, or to opt out of having your web-browsing information used for behavioral advertising purposes, please visit www.aboutads.info/choices if you are in the United States, or http://youronlinechoices.eu/ if you are outside the United States.</td>
                      <td>
                       <ul>
                        <li>XtayPro</li>
                        <li>Google</li>
                        <li>Facebook</li>
                        <li>AOL</li>
                        <li>Microsoft</li>
                        <li>Yahoo</li>
                        <li>Drawbridge</li>
                        <li>Indeed</li>
                        <li>Recruitics</li>
                        <li>RocketFuel</li>
                        <li>Simplyhired</li>
                        <li>Twitter</li>
                        <li>Ziprecruiter</li>
                        <li>Mixpanel</li>
                        <li>HasOffers/Tune</li>
                        <li>Adjust</li>
                        <li>AdRoll</li>
                        <li>Quantcast</li>
                        <li>Kenshoo</li>
                       </ul>
                      </td>
                     </tr>
                    </tbody>
                   </table>
                  </div>
                </div>
                <div className="panel panel-default">
                  <div className="panel-heading" role="tab" id="headingOne">
                   <h4 className="panel-title">
                    Contact Us
                   </h4>
                  </div>
                  <div className="panel-body">
                   If you have any questions about our use of cookies, please contact us at <a href="mailto:info@xtaypro.com">info@XtayPro.com</a>, or write us at XtayPro.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default PrivacyEn;
