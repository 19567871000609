import React from 'react';
import { useTranslation } from 'react-i18next';

const FeaturesSection = () => {
  const { t } = useTranslation();

  return (
    <section id="features" className="section">
      <div className="container">
        <div className="row">
          <div className="col-xs-12">
            <div className="titleSection">
              <h2>{t('home.introTitle2')}</h2>
              <h3>{t('home.introContent2')}</h3>
              <div className="coloredLine"></div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-xs-12">
            <img src="/images/categories_2.png" alt="XtayPro" className="img-responsive"/>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FeaturesSection;
