import React, { Fragment } from 'react';
import Avatar from '../Avatar/Avatar';

class MemberCard extends React.Component {
  render() {
    const { avatar, name, roles, desc } = this.props;

    const renderRoles = roles => {
      return roles.map((role, index) => {
        if (index === roles.length - 1) return role;
        return (
          <Fragment key={index}>
            {role}<br />
          </Fragment>
        );
      });
    }

    return (
      <div className="member-item col-md-3 col-xs-12">
        <Avatar src={avatar.src} alt={avatar.alt} />
        <span className="name">{name}</span>
        <br />
        <span className="roles">
          {renderRoles(roles)}
        </span>
        <p>{desc}</p>
      </div>
    );
  }
}

export default MemberCard;
