export const webDownloadButtons = [
  {
    href: 'https://itunes.apple.com/app/xtaypro/id1255462896',
    src: '/images/downloads/apple_en.png',
    alt: 'apple'
  },
  {
    href: 'https://play.google.com/store/apps/details?id=com.xtaypro.android',
    src: '/images/downloads/android_en.png',
    alt: 'android'
  }
];

export const androidDownloadButton = {
  href: 'https://play.google.com/store/apps/details?id=com.xtaypro.android',
  src: '/images/downloads/share_mobile_download_button.png',
  alt: 'apple'
}

export const iosDownloadButton = {
  href: 'https://itunes.apple.com/app/xtaypro/id1255462896',
  src: '/images/downloads/share_mobile_download_button.png',
  alt: 'apple'
}

export const flags = [
  {
    locale: 'vi',
    flagClass: 'vn'
  },
  {
    locale: 'en',
    flagClass: 'us'
  },
  {
    locale: 'kr',
    flagClass: 'kr'
  },
  {
    locale: 'de',
    flagClass: 'de'
  },
]
