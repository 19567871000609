import React from 'react';

const Loader = () => {
  return (
    <div className="preloader">
      <div className="status">
        <img src="/images/logo_xtaypro_20171107.png" height="34" alt="loading..."/>
      </div>
    </div>
  );
}

export default Loader;
