import React from 'react';
import { Panel } from 'react-bootstrap';

class FaqItem extends React.Component {
  state = {
    singleExpanded: false,
    allExpanded: false
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.allExpanded !== prevState.allExpanded) {
      return {
        singleExpanded: nextProps.allExpanded,
        allExpanded: !prevState.allExpanded
      };
    }
    return null;
  }

  toggleExpand = () => {
    this.setState(prevState => {
      return {singleExpanded: !prevState.singleExpanded}
    });
  }

  render() {
    const { question, answer } = this.props;

    return (
      <Panel expanded={this.state.singleExpanded}>
        <Panel.Heading onClick={this.toggleExpand}>
          <Panel.Title toggle>
            {question}
          </Panel.Title>
        </Panel.Heading>
        <Panel.Body collapsible>
          {answer}
        </Panel.Body>
      </Panel>
    );
  }
}

export default FaqItem;
