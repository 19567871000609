import React from 'react';
import { useTranslation } from 'react-i18next';

const NotFound = () => {
  const { t } = useTranslation();

  return (
    <section className="section">
      <div className="container">
        <div className="row">
          <div className="col-xs-offset-1 col-xs-10">
            <div className="titleSection">
              <h2>404</h2>
              <h3>{t('notFound.warn')}</h3>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default NotFound;
