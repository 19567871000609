import React from 'react';
import './FbCommentSection.css';
import { FacebookProvider, Comments } from 'react-facebook';

const FbCommentSection = () => {
  return (
    <section className={window.location.pathname === '/green' && 'comments'}>
      <div className="container">
        <div className="row">
          <div className="col-sm-10 col-sm-offset-1 col-xs-12">
            <div className="panel-group">
              <FacebookProvider appId="1717564955151865">
                <Comments href="https://welcome.xtaypro.com/" width="100%" numposts="5" />
              </FacebookProvider>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FbCommentSection;
