import React from 'react';
import {isAndroid, isIOS} from 'react-device-detect';

import './DownloadButtonList.css';
import { webDownloadButtons, androidDownloadButton, iosDownloadButton } from '../../config/constants'

const DownloadButton = ({ href, src, alt }) => {
  return (
    <a href={href}
       target="_blank"
       rel="noopener noreferrer" >
      <img src={src} alt={alt} />
    </a>
  );
}

const androidDownload = <DownloadButton
                          href={androidDownloadButton.href}
                          src={androidDownloadButton.src}
                          alt={androidDownloadButton.alt}
                        />

const iosDownload = <DownloadButton
                      href={iosDownloadButton.href}
                      src={iosDownloadButton.src}
                      alt={iosDownloadButton.alt}
                    />

const webDownload = webDownloadButtons.map((btn, index) => {
  return (
    <DownloadButton
      key={index}
      href={btn.href}
      src={btn.src}
      alt={btn.alt}
    />
  );
});

const renderDownloadButtons = () => {
  if (isAndroid) {
    return androidDownload
  }
  if (isIOS) {
    return iosDownload
  }
  return webDownload
}

const DownloadButtonList = () => {
  const greenPageClass = window.location.pathname === '/green' && 'green-page'
  return (
    <div className={['downloads', greenPageClass].join(' ')}>
      {renderDownloadButtons()}
    </div>
  );
}

export default DownloadButtonList;
