import React from 'react';
import { useTranslation } from 'react-i18next';

const OverviewSection = () => {
  const { t } = useTranslation();

  return (
    <section id="overview" className="section">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="titleSection">
              <h2>{t('home.introTitle1')}</h2>
              <h3>{t('home.introContent1')}</h3>
              <div className="coloredLine"></div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-4 col-sm-6 col-sm-offset-0 col-xs-10 col-xs-offset-1">
            <img src="/images/phone_V1.png" alt="XtayPro" className="img-responsive"/>
          </div>

          <div className="col-md-4 col-sm-6 col-sm-offset-0 col-xs-10 col-xs-offset-1">
            <img src="/images/phone_V2.png" alt="XtayPro" className="img-responsive"/>
          </div>

          <div className="col-md-4 col-md-offset-0 col-sm-6 col-sm-offset-3 col-xs-10 col-xs-offset-1">
            <img src="/images/phone_V3.png" alt="XtayPro" className="img-responsive"/>
          </div>

        </div>
      </div>
    </section>
  );
}

export default OverviewSection;
