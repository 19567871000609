import React from 'react';

class Avatar extends React.Component {
  render() {
    const { src, alt } = this.props;

    return <img className="img-responsive" src={src} alt={alt} />;
  }
}

export default Avatar;
