import React from 'react';

export default [
  {
    question: 'How can I be sure that the other party is honest and will not cheat me?',
    answer: <>Check their profiles to see how many transactions they have successfully carried out and what other users are talking about them. Also, honest users tend to write a decent self-introduction and are willing to share more about themselves. A good rule of thumb is to check their user rank; usually, a user of rank Gold is safe enough to do transactions with. Click <a href="/ranking" target="_blank">here</a> to see how user ranks differ. If you still want to be completely sure that nothing will go wrong, use our <a href="/escrow" target="_blank">ESCROW</a> service, which is described in details in our <a href="/faq" target="_blank">FAQ</a> section.</>
  },
  {
    question: 'I usually post my trips and requests on Facebook, now I have to post another one on your platform? Save me the hassle!',
    answer: "No! We are actually saving you the hassle while enabling you to dual-post on XtayPro and other social networks as well. You just have to share your posts by clicking the helpful little Share button on our app. We are also working with many Facebook groups to increase your probability of getting a match."
  },
  {
    question: 'Will I get penalized for finishing the business outside XtayPro?',
    answer: <>
              Of course not. We’ve built this platform to help everyone get in touch and help each other, not to hoard users, since we will also use our own service. However, we strongly suggest that you complete your transactions using our service, because:
              <ul>
                <li>You will get a +1 in your profile after every successful transaction. Based on this rating, other users will be able to evaluate and choose to do business with you, and vice versa.</li>
                <li>We will have the evidence to assist you in case of fraud or dispute. For how otherwise can we solve a case with only a chat log or a screenshot not from our system?</li>
                <li>You can use our ESCROW service for your transactions in which we will temporarily hold an amount of money from one or both sides until the transaction is confirmed successful.</li>
              </ul>
            </>
  }
]
