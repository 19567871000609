import React from 'react';

export default [
  {
    question: '왜 Xtaypro 일까요?',
    answer: <>&lt;간편한 구매요청&gt;<br />
                    그동안 복잡하고 어려웠던 배송절차와 달리 [상품정보입력] -&gt; [운송자결정]-&gt;[결제] 3단계 절차를 통해 간편하게 해외제품을 구매할 수 있습니다.<br />
                    &lt;정확하고 안전한 배송&gt;<br />
                    모든 거래는 100% 안전거래 에스크로로 이뤄지고 있습니다.<br />
                    구매자가 제품을 받지 못했거나 파손되었을 경우 Xtaypro 약관에 따른 보상이 이뤄집니다.</>
  },
  {
    question: 'Xtaypro에 대한 자세한 사용방법이 궁금해요!',
    answer: <>
              <ul>
                <li>
                  Xtaypro에서 운송자 등록하는 방법<br />
                  [새오퍼만들기] -&gt; [운송자등록 (여행일정,출국일,귀국일 등) 입력] -&gt; [등록완료]
                </li>
                <li>
                  Xtaypro에서 구매요청 등록하는 방법<br />
                  [요청만들기] -&gt; [정보입력] -&gt; [운송자와 대화하기] -&gt; [완료]
                </li>

                <br />

                <li>
                  SHIPPERS : 운송자들의 여행일정을 확인하고 메시지를 통해 구매요청 및 세부사항을 조정할 수 있습니다.<br />
                  요청 : 구매요청자들의 구매요청내역을 한 눈에 확인하고 메시지 기능을 통해 세부사항을 조정할 수 있습니다.
                </li>
                <li>
                  여행자의 운송비는 직거래의 경우, 구매자의 수령확인 후 1~2일 이후 서비스 이용수수료를 제외한 운송비가 바로 입금 완료되며, 국내택배배송의 경우.
                  운송장번호 기입 후 1~2일 이후 입금 완료됩니다.
                </li>
                <li>
                  거래내역 : 구매요청 및 운송에 대한 히스토리를 한 눈에 확인할 수 있습니다.<br />
                  What’s hot : 해외 여행자들이 직접 해외 인기 아이템 소식을 제공받고 구매할 수 있습니다.
                </li>
              </ul>
            </>
  },
  {
    question: '운송자의 운송비는 언제, 어떻게 지급되나요?',
    answer: <>
              <ul>
                <li>
                  여행자의 운송비는 직거래의 경우, 구매자의 수령확인 후 1~2일 이후 서비스 이용수수료를 제외한 운송비가 바로 입금 완료되며, 국내택배배송의 경우 운송장번호 기입 후 1~2일 이후 입금 완료됩니다.
                </li>
              </ul>
            </>
  }
]
