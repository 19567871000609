import React from 'react';
import { withTranslation } from 'react-i18next';

import FbStartSection from './components/FbStartSection';
import OverviewSection from './components/OverviewSection';
import FeaturesSection from './components/FeaturesSection';
import FaqSection from './components/FaqSection';
import CarouselSection from './components/CarouselSection';
import IntroSection from './components/IntroSection';

const $ = window.$;

class Home extends React.Component {
  render() {
    const { t, i18n } = this.props;

    let aioCarouselRenderOptions = {
      skin: 'sweet',
      width: 1000,
      height: 800,
      responsive: true,
      resizeImages:true,
      autoHideBottomNav: false,
      elementsVerticalSpacing: 50,
      verticalAdjustment: 0,
      animationTime: 0.5,
      circleLeftPositionCorrection: 50,
      circleTopPositionCorrection: 20,
      circleLineWidth: 1,
      circleColor: '#29282B',
      behindCircleColor: '#999999',
      nextPrevMarginTop: 25,
      bottomNavMarginBottom: -50,
    };

    if ($(window).width() < 768) {
      aioCarouselRenderOptions = {
        ...aioCarouselRenderOptions,
        autoPlay: 3,
        numberOfVisibleItems:3,
      };
    } else {
      aioCarouselRenderOptions = {
        ...aioCarouselRenderOptions,
        autoPlay: 5,
        numberOfVisibleItems:5,
      };
    }

    const aioCarouselProps = {
      items: t('home.carousels', { returnObjects: true }),
      options: aioCarouselRenderOptions
    };

    return (
      <>
        <IntroSection />
        <CarouselSection aioCarouselProps={aioCarouselProps}/>
        {
          i18n.language !== 'kr' &&
          <>
            <FbStartSection />
            <OverviewSection />
            <FeaturesSection />
          </>
        }
        <FaqSection />
      </>
    );
  }
}

export default withTranslation()(Home);
