import React from 'react';

const Terms = () => {
  return (
    <>
      <section id="privacy" className="section noselect">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="titleSection">
                <h2>TERMS OF SERVICE</h2>
                <div className="coloredLine"></div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-8 col-md-offset-2 col-sm-10 col-sm-offset-1 col-xs-12">
              <div className="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                <div className="panel panel-default">
                  <div className="panel-body">
                    <p>Updated: July 1st, 2017</p>
                    <p>PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY AS THEY CONTAIN IMPORTANT INFORMATION REGARDING YOUR LEGAL RIGHTS, REMEDIES AND OBLIGATIONS. THESE INCLUDE VARIOUS LIMITATIONS AND EXCLUSIONS, AND A CLAUSE THAT GOVERNS THE JURISDICTION AND VENUE OF DISPUTES.</p>
                  </div>
                </div>
                <div className="panel panel-default">
                  <div className="panel-heading">
                   <h2 className="panel-title">PART 1 – XTAYPRO SERVICE TERMS</h2>
                  </div>
                </div>
                <div className="panel panel-default">
                  <div className="panel-heading">
                   <h4 className="panel-title">Description of XtayPro</h4>
                  </div>
                  <div className="panel-body">
                    The XtayPro application is a web application located at <a href="www.xtaypro.com">www.xtaypro.com</a>, iOS and Android app, both published by XtayPro Joint Stock Company (collectively, “XtayPro”, “we” and “us”). Each such website and mobile application is referred to in this Terms of Service as a “Site”.
                  </div>
                </div>
                <div className="panel panel-default">
                  <div className="panel-heading">
                   <h4 className="panel-title">Purpose of XtayPro</h4>
                  </div>
                  <div className="panel-body">
                   XtayPro is a platform that enables and facilitates peer-to-peer delivery services involving Xtayers and Xtayees, hereinafter referred to as "Xtayers" and "Xtayees" respectively. In order to use XtayPro as either a Xtayer or a Xtayee, you must first become a User by establishing a XtayPro account. Once you are a User, you may post offers on the Site to transport items as a Xtayer. You may also post requests on the Site to ask for the transport of items as a Xtayee. Transactions are established between Xtayers and Xtayees with the presence of their mutual consent (collectively, the “Services”), which Services are accessible at <a href="www.xtaypro.com">www.xtaypro.com</a> and any other mobile app or websites through which XtayPro makes the Services available (collectively, the “Site”) and as an application for mobile devices (the “Application”, or "App"). By using the Site and Application, you agree to comply with and be legally bound by the terms and conditions of these Terms of Service (“Terms“).
                  </div>
                </div>
                <div className="panel panel-default">
                  <div className="panel-heading">
                   <h4 className="panel-title">Definition</h4>
                  </div>
                  <div className="panel-body">
                   <p><strong>“Xtay”</strong> is a homophone of the word "stay" in English and its derivatives are also pronounced similarly.</p>
                   <p><strong>“XtayPro Content”</strong> means all Contents that XtayPro makes available through the Site, Application, or Services, including any Content licensed from a third party, but excluding User Content.</p>
                   <p><strong>“Collective Content”</strong> means User Content and XtayPro Content.</p>
                   <p><strong>“Content”</strong> means text, graphics, images, music, software (excluding the Application), audio, video, information or other materials.</p>
                   <p><strong>“Xtayee”</strong> means a User who posts a request of one or multiple items on the Site, Application or Services, or a User who contacts a Xtayer and asks him or her to transport and/or deliver items in his stead. Tips may be given by the Xtayee to the Xtayer as a "Thank you" or as agreed upon by both parties. A Xtayee willingly enters into contracts, carries the burden of ordering Items, closes Transactions on his or her own discretion, and exempts XtayPro from any liability and excuses XtayPro for any mistakes, violations of trusts, and counterfeit actions happened between the time of Transaction beginning till its end made via the Site, Application, and Services.</p>
                   <p><strong>“Xtayer”</strong> means a User who shares information of his or her travel plans and consents that upon establishing a Transaction with a Xtayee, he or she is willing to carry one or multiple items to transport to the respective Xtayee or a designated collection center. There may be a gratuity of any amount on voluntary basis, or as agreed in their mutual Transaction decisions via the Site, Application and Services. A Xtayer willingly enters into contracts, carries the burden of transporting the Items, closes Transactions on his or her own discretion, and exempts XtayPro from any liability, infringements on customs regulations such as declaration of Taxes and bringing in of prohibited items. The Xtayer also excuses XtayPro for any mistakes, violations of trusts, and counterfeit actions happened between the time of Transaction beginning till its end made via the Site, Application, and Services.</p>
                   <p><strong>“Transaction”</strong> means an agreement between a Xtayee and a Xtayer regarding one or multiple requested Items and the respective gratuity.</p>
                   <p><strong>“Trip”</strong> means a sharing of travel information by a Xtayer.</p>
                   <p><strong>“User”</strong> means a person who completes XtayPro’s account registration (signup or login) process, Including, but not limited to Xtayer and Xtayee.</p>
                   <p><strong>“User Content”</strong> means all Content that a User posts, uploads, publishes, submits or transmits to be made available through the Site, Application or Services.</p>
                   <p><strong>“Tax”</strong> or <strong>“Taxes”</strong> mean any sales taxes, value added taxes (VAT), Items and services taxes and other similar municipal, state and federal indirect or other withholding and personal or corporate income taxes. XtayPro requires every User to understand, be aware of, and carefully abide tax regulations of any country he or she is travelling to and from while carrying Items, purposefully to transport to Xtayees via the Site, Applications, and Services. Customs fees, tax fees, and other tax-related costs occur during the time of making a Transaction via the Site, Applications, and Services have to be paid legally according to the laws and regulations of related countries as agreed upon by the Users.</p>
                   <p><strong>Take note:</strong> Destination country relief and duty free concessions for bona fide Xtayers using XtayPro to purchase items on behalf of other Users are not applicable as they will be bringing the Items into the country on behalf of others and the Items are not for their personal use/consumption.</p>
                   <p>All XtayPro’s Users are reminded to declare their purchases and pay the relevant taxes applicable in destination countries. For more details on customs and regulations, please inform yourself and the other User prior to each trip.</p>
                  </div>
                </div>
                <div className="panel panel-default">
                 <div className="panel-heading">
                  <h4 className="panel-title">The Transaction between a Xtayer and a Xtayee</h4>
                 </div>
                 <div className="panel-body">
                  In order to facilitate the process of a Xtayer  and a Xtayee coming to agreement on a transaction, XtayPro has posted <a href="#xtayee_terms">Xtayees’ Terms</a> and <a href="#xtayer_terms">Xtayers’ Terms</a>.
                 </div>
                </div>
                <div className="panel panel-default">
                 <div className="panel-heading">
                  <h4 className="panel-title">Fees for Services</h4>
                 </div>
                 <div className="panel-body">
                  The Services provided by XtayPro are free of charge. XtayPro will charge Users any amount that occurs during Transactions which use third-party services. Gratuities and/or any fees are negotiated by the parties to a transaction. XtayPro reserves the right to introduce a fee for the provision of the Services. If XtayPro decides to introduce such a fee, it will inform the Users accordingly and allow you to either continue or terminate your access to the Services through the Sites at your option.
                 </div>
                </div>
                <div className="panel panel-default">
                 <div className="panel-heading">
                  <h4 className="panel-title">Payment and Reimbursement Terms</h4>
                 </div>
                 <div className="panel-body">
                  The Users will be reimbursed the amount owing to them for the transaction by using payment methods agreed upon between them. By agreeing to Terms of Service or continuing to operate as a User on XtayPro, Users agree to be bound by the XtayPro Escrow Services Agreement, as the same may be modified by XtayPro from time to time. As a condition of XtayPro enabling payment processing services through third-party service providers, Users agree to provide XtayPro with accurate and complete information about themselves, and authorize XtayPro to share with third-party service providers that information and transaction information related to Users' use of the payment processing services provided by third-party service providers.
                 </div>
                </div>
                <div className="panel panel-default">
                 <div className="panel-heading">
                  <h4 className="panel-title">Delivery of an Item</h4>
                 </div>
                 <div className="panel-body">
                  XtayPro strongly recommends that all in-person meetings between a Xtayer and a Xtayee take place in a public and/or secure location.
                 </div>
                </div>
                <div className="panel panel-default">
                 <div className="panel-heading">
                  <h4 className="panel-title">Risk of Loss of an Item; Insurance Option</h4>
                 </div>
                 <div className="panel-body">
                  In most jurisdictions, the safeguard of the Item and any force majeure should be discussed and agreed upon between the Users prior to actual transactions/transportation. A party is not liable for failure to perform the party's obligations if such failure is as a result of Acts of God (including fire, flood, earthquake, storm, hurricane or other natural disaster), war, invasion, act of foreign enemies, hostilities (regardless of whether war is declared), civil war, rebellion, revolution, insurrection, military or usurped power or confiscation, terrorist activities, nationalization, government sanction, blockage, embargo, labor dispute, strike, lockout or interruption or failure of electricity or telephone service. In such cases, XtayPro and both parties should work together to find and agree on a solution.
                 </div>
                </div>
                <div className="panel panel-default">
                 <div className="panel-heading">
                  <h4 className="panel-title">Safety Violations</h4>
                 </div>
                 <div className="panel-body">
                  In case you experience a safety concern as either a Xtayer or a Xtayee that you believe was caused by the other party to a Transaction arranged through XtayPro, please notify XtayPro immediately by email at <a href="mailto:report@xtaypro.com">report@xtaypro.com</a>, by telephone or in person. XtayPro will investigate the Incident, either by itself or with appropriate authorities and may terminate the account of the User whose conduct led to the safety concern, and in serious cases, help the other party to file a lawsuit against the violator.
                 </div>
                </div>
                <div className="panel panel-default">
                 <div className="panel-heading">
                  <h4 className="panel-title">Prohibited Items</h4>
                 </div>
                 <div className="panel-body">
                  It is the responsibility of all Users to inspect any Item being transported, making a reasonable attempt to confirm that it is not a prohibited or dangerous Item. This inspection may require the Xtayer to unwrap the cover/open the container of an Item that cannot be identified otherwise. The definition of prohibited items may vary between countries and include (but not limited to):
                  <ul>
                    <li>Any item that is prohibited by law in the place of origin, the destination, or any jurisdiction through which the item will be transported</li>
                    <li>Hazardous material as defined by airlines and laws of destination country</li>
                    <li>Reproduction of copyright publications, video tapes, video compact discs, laser discs, records or cassettes</li>
                    <li>Items imported into the destination country without a customs declaration, if required</li>
                    <li>Seditious and treasonable materials</li>
                    <li>Stolen properties</li>
                    <li>Firearms, ammunition, explosives</li>
                    <li>Human remains</li>
                    <li>Illegal Drugs</li>
                    <li>Any Item or item related to illegal activities, such as the Production of illegal drugs</li>
                    <li>Endangered species of wildlife and their by-products</li>
                  </ul>
                 </div>
                </div>
                <div className="panel panel-default">
                 <div className="panel-heading">
                  <h4 className="panel-title">Limitation of Liability</h4>
                 </div>
                 <div className="panel-body">
                  To the extent permitted by applicable law, XtayPro will not be liable to you in respect of any acts or omissions of its employees, agents or sub-contractors, whether such liability arises in contract (by way of indemnity or otherwise), tort (Including negligence), misrepresentation, breach of statutory duty, restitution or otherwise, provided that nothing in these Terms of Service will limit or exclude XtayPro's liability to you for personal injury or death caused directly by XtayPro's negligence.
                 </div>
                </div>
                <div className="panel panel-default">
                 <div className="panel-heading">
                  <h4 className="panel-title">Applicable Law</h4>
                 </div>
                 <div className="panel-body">
                  <p>The Services and the Service Terms set out in this Part 1, and all non-contractual obligations arising in any way whatsoever out of or in connection with the Service Terms shall be governed by, construed and take effect in accordance with the international laws and laws of Users' countries of residence.</p>
                  <p>Any dispute, claim or matter of difference arising out of or relating to the Services or Service Terms is subject to the exclusive jurisdiction of the courts of Users' countries of residence.</p>
                 </div>
                </div>
                <div className="panel panel-default">
                  <div className="panel-heading">
                   <h2 className="panel-title">PART 2 –TERMS OF USE</h2>
                  </div>
                </div>
                <div className="panel panel-default">
                 <div className="panel-body">
                  These Terms of Use (“Terms”) apply to your visit to and your use of the Website and the App as well as to all other information, recommendations and/or services provided to you on or through the Website and the App, but for the avoidance of doubt these User Terms do not apply to the Booking Services defined and described in Part 1 above. However, defined terms used in this Part 2 shall have the meaning given in Part 1 unless otherwise specified.
                 </div>
                </div>
                <div className="panel panel-default">
                 <div className="panel-heading">
                  <h4 className="panel-title">1. ACKNOWLEDGEMENT AND ACCEPTANCE OF AGREEMENT</h4>
                 </div>
                 <div className="panel-body">
                  PLEASE READ THESE TERMS CAREFULLY BEFORE ACCESSING OR USING THE SERVICES.
                 </div>
                 <div className="panel-body">
                  <p>Your access and use of the Services constitutes your agreement to be bound by these Terms, which establishes a contractual relationship between you and XtayPro. If you do not agree to these Terms, you may not access or use the Services. These Terms expressly supersede prior agreements or arrangements with you. XtayPro may terminate these Terms or any Services with respect to you, or generally cease offering or deny access to the Services or any portion thereof, at any time for any reason. XtayPro will provide you with as much notice as it reasonably can of such termination, cessation or denial. However, XtayPro reserves the right to terminate these Terms or any Services with respect to you, or generally cease offering or deny access to the Services or any portion thereof, immediately at any time without notice if (i) you are in breach of these Terms, (ii) it is impractical to give such notice in the circumstances, or (iii) in XtayPro’s opinion, any delay in such termination would expose XtayPro or a third party to significant risk of harm or damage.</p>
                  <p>Our collection and use of personal information in connection with the Services is as provided in XtayPro’s Privacy and Cookie Policy located at <a href="/privacy" target="_blank">welcome.xtaypro.com/privacy</a>. XtayPro may provide to a claims processor or an insurer any necessary information (Including your contact information) if there is a complaint, dispute or conflict, which may Include an accident, involving you and a third-party service provider (Including a transportation provider) and such information or data is necessary to resolve the complaint, dispute or conflict.</p>
                  <p>Certain areas of the Site and Application (and your access to or use of certain aspects of the Services or Collective Content) may have supplemental terms and conditions posted or may require you to agree with and accept additional terms and conditionsSupplemental terms are in addition to, and shall be deemed a part of, the Terms for the purposes of the applicable Services. Supplemental terms shall prevail over these Terms in the event of a conflict with respect to the applicable Services.</p>
                  <p>YOU ACKNOWLEDGE AND AGREE THAT, BY ACCESSING OR USING THE SITE, APPLICATION OR SERVICES OR BY DOWNLOADING OR POSTING ANY CONTENT FROM OR ON THE SITE. YOU ARE INDICATING THAT YOU HAVE READ, AND THAT YOU UNDERSTAND AND AGREE TO BE BOUND BY THESE TERMS, WHETHER OR NOT YOU HAVE REGISTERED WITH THE SITE AND APPLICATION. IF YOU DO NOT AGREE TO THESE TERMS, THEN YOU HAVE NO RIGHT TO ACCESS OR USE THE SITE, APPLICATION, SERVICES, OR COLLECTIVE CONTENT.</p>
                  <p>If you accept or agree to these Terms on behalf of a company or other legal entity, you represent and warrant that you have the authority to bind that company or other legal entity to these Terms and, in such event, “you” and “your” will refer and apply to that company or other legal entity.</p>
                 </div>
                </div>
                <div className="panel panel-default">
                 <div className="panel-heading">
                  <h4 className="panel-title">2. MODIFICATION</h4>
                 </div>
                 <div className="panel-body">
                  <p>XtayPro reserves the right, at its sole discretion, to modify the Site, Application or Services or to modify these Terms, Including the Service Fees, at any time and without prior notice. If we modify these Terms, we will provide you with notice of the modification. We will also update the “Last Updated Date” at the top of these Terms. By continuing to access or use the Site, Application or Services after we have posted a modification on the Site or via the Application or have provided you with notice of a modification, you are indicating that you agree to be bound by the modified Terms. If the modified Terms are not acceptable to you, your only recourse is to cease using the Site, Application and Services.</p>
                  <p>XtayPro makes available a peer-to-peer platform with related technology for Xtayers and Xtayees to connect and arrange for Transactions between them. XtayPro is not an owner or seller or retailer or broker or commissioned agent of including, but not limited to, any product and services made and marketed by third-party corporations, companies, individual sellers or government service providers, nor is it a local or international insurance or shipping service. XtayPro does not own, sell, resell, furnish, provide, rent, re-rent, manage and/or control properties, including, but not limited to, shipping, insurance or transportation or travel services. XtayPro’s responsibilities are limited to: facilitating the availability of the Site.</p>
                  <p>PLEASE NOTE THAT, AS STATED ABOVE, THE SITE, APPLICATION AND SERVICES ARE INTENDED TO BE USED TO FACILITATE THE BOOKING OF TRANSACTIONS. XTAYPRO CANNOT AND DOES NOT CONTROL THE CONTENT CONTAINED IN ANY LISTINGS AND THE CONDITION, LEGALITY OR SUITABILITY OF ANY TRANSACTION. XTAYPRO IS NOT RESPONSIBLE FOR AND DISCLAIMS ANY AND ALL LIABILITY RELATED TO ANY AND ALL LISTINGS AND TRANSACTIONS. ACCORDINGLY, ANY TRANSACTIONS WILL BE MADE AT THE USERS' OWN RISK.</p>
                 </div>
                </div>
                <div className="panel panel-default">
                 <div className="panel-heading">
                  <h4 className="panel-title">3. NO ENDORSEMENT</h4>
                 </div>
                 <div className="panel-body">
                  <p>XtayPro does not endorse any User or any commercial authenticity of any business entity. In addition, although these Terms require Users to provide accurate information, we do not attempt to confirm, and do not confirm, any User’s purported identity. You are responsible for determining the identity and suitability of others who you contact via the Site, Application and Services. We will not be responsible for any damage or harm resulting from your interactions with other Users.</p>
                  <p>By using the Site, Application or Services, you agree that any legal remedy or liability that you seek to obtain for actions or omissions of other Users or other third parties will be limited to a claim against the particular Users or other third parties who caused you harm and you agree not to attempt to impose liability on, or seek any legal remedy from XtayPro with respect to such actions or omissions. Accordingly, we encourage you to communicate directly with other Users on the Site and Services regarding any Transaction made by you.</p>
                 </div>
                </div>
                <div className="panel panel-default">
                 <div className="panel-heading">
                  <h4 className="panel-title">4. YOUR USE OF THE SERVICES</h4>
                 </div>
                 <div className="panel-body">
                  <p>THE SERVICES</p>
                  <p>The Services constitute a technology platform that enables and facilitates peer-to-peer delivery services involving Xtayers and Xtayees using XtayPro’s mobile applications or websites provided as part of the Services (each, an “Application”), through which Xtayee may choose and post items to be purchased and/or transported, a Xtayer with the mutual consent on the final price agreed on the Transaction to purchase and/or transport the items. The employment of any independent third-party providers of such services, including independent third-party transportation providers and independent third-party logistics providers under agreement with XtayPro or certain of XtayPro’s affiliates (“Third-Party Providers”) are to be agreed between Xtayers and Xtayees. Unless otherwise agreed by XtayPro in a separate written agreement with you, the Services are made available solely for your personal use. XtayPro is not a transportation agency, retailer or distribution of the above mentioned items and has no control over the conduct of Xtayers and Xtayees. YOU ACKNOWLEDGE THAT XTAYPRO DOES NOT PROVIDE TRANSPORTATION OR LOGISTICS SERVICES, RETAIL OR DISTRIBUTION SERVICES OF MENTIONED ITEMS OR FUNCTION AS A TRANSPORTATION PROVIDER OR CARRIER, RETAILER, DISTRIBUTOR OF MENTIONED ITEMS AND THAT ALL SUCH TRANSPORTATION OR LOGISTICS SERVICES, RETAIL OR DISTRIBUTION SERVICES OF MENTIONED ITEMS ARE PROVIDED BY INDEPENDENT THIRD-PARTY CONTRACTORS WHO ARE NOT EMPLOYED BY XTAYPRO OR ANY OF ITS AFFILIATES.</p>
                 </div>
                 <div className="panel-body">
                  <p>USER ACCOUNTS</p>
                  <p>In order to use the Services, you must register for and maintain an active personal user Services account (“Account”). You must be at a legal age in your country of residence to sign a legally binding contract and to obtain an Account. Account registration requires you to submit to XtayPro certain personal information, such as your names, addresses, mobile phone numbers and date and place of birth. You agree to maintain accurate, complete, and up-to-date information in your Account. Your failure to maintain accurate, complete, and up-to-date Account information, Including having an invalid address on file, may result in your inability to access and use the Services or XtayPro’s termination of this Agreement with you. You are responsible for all activity that occurs under your Account, and you agree to maintain the security and secrecy of your Account username and password at all times. As you complete the registration process, you will be asked to attest that you have read the XtayPro Terms of Use and the XtayPro Privacy Policy and agree to be bound by them.</p>
                  <p>You may establish a XtayPro account through some social network services. The information about yourself provided to XtayPro through these services must be accurate, and you must keep it accurate in case it changes. If you register through these services, you consent to XtayPro having access to information in your account profiles created by these services. You also consent to XtayPro using these accounts to send messages regarding your XtayPro account.</p>
                 </div>
                 <div className="panel-body">
                  <p>LICENSE</p>
                  <p>Subject to your compliance with these Terms, XtayPro grants you a limited, non-exclusive, non-sub-licensable, revocable, non-transferrable license to: (i) access and use the Applications on your personal device solely in connection with your use of the Services; and (ii) access and use any content, information and related materials that may be made available through the Services, in each case solely for your personal use. Any rights not expressly granted herein are reserved by XtayPro and XtayPro’s licensors. We may terminate it on the terms stated herein. You may not copy anything on the Site or make any derivative works. In case you post any content on the Site, you grant us a license to copy that content and make derivative works. We may also perform, display, and distribute the copies and derivative works. Our license from you is perpetual and world-wide.</p>
                 </div>
                 <div className="panel-body">
                  <p>USER CONDUCTS AND RESTRICTIONS</p>
                  <p>You may not assign or otherwise transfer your Account to any other person or entity. You agree to comply with all applicable laws when using the Services, and you may only use the Services for lawful purposes. You will not, in your use of the Services, cause nuisance, annoyance, inconvenience, or property damage, whether to the Third-Party Provider or any other party. In certain instances you may be asked to provide proof of identity to access or use the Services, and you agree that you may be denied access to or use of the Services if you refuse to provide proof of identity.</p>
                  <p>You may not: (i) remove any copyright, trademark or other proprietary notices from any portion of the Services; (ii) reproduce, modify, prepare derivative works based upon, distribute, license, lease, sell, resell, transfer, publicly display, publicly perform, transmit, stream, broadcast or otherwise exploit the Services except as expressly permitted by Xtay; (iii) decompile, reverse engineer or disassemble the Services except as may be permitted by applicable law; (iv) link to, mirror or frame any portion of the Services; (v) cause or launch any programs or scripts for the purpose of scraping, indexing, surveying, or otherwise data mining any portion of the Services or unduly burdening or hindering the operation and/or functionality of any aspect of the Services; or (vi) attempt to gain unauthorized access to or impair any aspect of the Services or its related systems or networks.</p>
                  <p>You understand and agree that you are solely responsible for compliance with any and all laws, rules, regulations, and tax obligations that may apply to your use of the Site, Application, Services and Content. In connection with your use of our Site, Application and Services, you may not and you agree that you will not:</p>
                  <ul>
                    <li>violate any local, state, provincial, national, or other law or regulation, or any order of a court, Including, without limitation, zoning restrictions and tax regulations;</li>
                    <li>use the Site, Application or Services for any commercial or other purposes that are not expressly permitted by these Terms;</li>
                    <li>copy, store or otherwise access any information contained on the Site, Application, Services or Content for purposes not expressly permitted by these Terms;</li>
                    <li>infringe the rights of any person or entity, including without limitation, their intellectual property, privacy, publicity or contractual rights;</li>
                    <li>use our Site, Application or Services to transmit, distribute, post or submit any information concerning any other person or entity, including without limitation, photographs of others without their permission, personal contact information or credit, debit, calling card or account numbers;</li>
                    <li>use the Site, Application or Services in connection with the distribution of unsolicited commercial emails ("spam") or advertisements unrelated to lodging in a private residence;</li>
                    <li>harass any other user of our Site, Application, or Services or collect or store any personally identifiable information about any other user other than for purposes of transacting as an Xtayer or Xtayee;</li>
                    <li>contact another User for any purpose other than asking a question related to a Transaction, such User’s Requests or Trips;</li>
                    <li>when acting as a visitor or otherwise, recruit or otherwise solicit any User to join third-party services or websites that are competitive to XtayPro, without XtayPro’s prior written approval;</li>
                    <li>impersonate any person or entity, or falsify or otherwise misrepresent yourself or your affiliation with any person or entity;</li>
                    <li>use the Site, Application and Services to find a Xtayer or Xtayee and then complete a booking of a Transaction independent of the Site, Application or Services in order to circumvent the obligation to pay any Service Fees related to XtayPro’s provision of the Services;</li>
                    <li>as a Xtayer, submit any listing with a false or misleading price information, or submit any listing with information that you do not intend to honor;</li>
                    <li>post, upload, publish, submit or transmit any Content that: (i) infringes, misappropriates or violates any laws. Such as a third-party’s patent, copyright, trademark, trade secret, moral rights or other intellectual property rights, or rights of publicity or privacy; (ii) violates, or encourages any conduct that would violate, any applicable law or regulation or would give rise to civil liability; (iii) is fraudulent, false, misleading or deceptive; (iv) is defamatory, obscene, pornographic, vulgar or offensive; (v) promotes discrimination, bigotry, racism, hatred, harassment or harm against any individual or group; (vi) is violent or threatening or promotes violence or actions that are threatening to any other person; or (vii) promotes illegal or harmful activities or substances;</li>
                  </ul>
                  <p>XtayPro will have the right to investigate, ban and remove any Content and prosecute violations of any of the above to the fullest extent of the law. XtayPro may involve and cooperate with law enforcement authorities in prosecuting Users who violate these Terms. You acknowledge that XtayPro has no obligation to monitor your access to or use of the Site, Application, Services or Collective Content or to review or edit any User Content, but has the right to do so for the purpose of operating the Site, Application and Services, to ensure your compliance with these Terms, or to comply with applicable law or the order or requirement of a court, administrative agency or other governmental body. XtayPro reserves the right, at any time and without prior notice, to remove or disable access to any Collective Content that XtayPro, at its sole discretion, considers to be objectionable for any reason, in violation of these Terms or otherwise harmful to the Site, Application or Services.</p>
                 </div>
                 <div className="panel-body">
                  <p>TEXT MESSAGING</p>
                  <p>By creating an Account, you agree that the Services may send you informational text (SMS) messages as part of the normal business operation of your use of the Services.</p>
                 </div>
                 <div className="panel-body">
                  <p>PROMOTIONAL CODES</p>
                  <p>XtayPro may, in XtayPro’s sole discretion, create promotional codes that may be redeemed for Account credit, or other features or benefits related to the Services and/or a Third-Party’s services, subject to any additional terms that XtayPro establishes on a per promotional code basis (“Promo Codes”). You agree that Promo Codes: (i) must be used for the intended audience and purpose, and in a lawful manner; (ii) may not be duplicated, sold or transferred in any manner, or made available to the general public (whether posted to a public forum or otherwise), unless expressly permitted by XtayPro; (iii) may be disabled by XtayPro at any time for any reason without liability to XtayPro; (iv) may only be used pursuant to the specific terms that XtayPro establishes for such Promo Code; (v) are not valid for cash; and (vi) may expire prior to your use. XtayPro reserves the right to withhold or deduct credits or other features or benefits obtained through the use of Promo Codes by you or any other user in the event that XtayPro determines or believes that the use or redemption of the Promo Code was in error, fraudulent, illegal, or in violation of the applicable Promo Code terms or these Terms.</p>
                 </div>
                 <div className="panel-body">
                  <p>USER PROVIDED CONTENT</p>
                  <p>We may, in our sole discretion, permit Users to post, upload, publish, submit or transmit User Content made available to XtayPro through the Services textual, audio, and/or visual content and information, including commentary and feedback related to the Services, initiation of support requests, and submission of entries for competitions and promotions (“User Content”). Any User Content provided by you remains your property. However, by providing User Content to XtayPro, you hereby grant to XtayPro a worldwide, irrevocable, perpetual, non-exclusive, transferable, royalty-free license, with the right to sublicense, to use, copy, modify, create derivative works of, distribute, publicly display, publicly perform, and otherwise exploit in any manner such User Content in all formats and distribution channels now known or hereafter devised (including in connection with the Services and XtayPro’s business and on third-party sites and services), without further notice to or consent from you, and without the requirement of payment to you or any other person or entity.</p>
                  <p>You represent and warrant that: (i) you either are the sole and exclusive owner of all User Content or you have all rights, licenses, consents and releases necessary to grant XtayPro the license to the User Content as set forth above; and (ii) neither the User Content nor your submission, uploading, publishing or otherwise making available of such User Content nor XtayPro’s use of the User Content as permitted herein will infringe, misappropriate or violate a third party’s intellectual property or proprietary rights, or rights of publicity or privacy, or result in the violation of any applicable law or regulation.</p>
                  <p>You agree to not provide User Content that is defamatory, libelous, hateful, violent, obscene, pornographic, unlawful, or otherwise offensive, as determined by XtayPro in its sole discretion, whether or not such material may be protected by law. XtayPro may, but shall not be obligated to, review, monitor, or remove User Content, at XtayPro’s sole discretion and at any time and for any reason, without notice to you.</p>
                 </div>
                 <div className="panel-body">
                  <p>THIRD PARTIES SERVICES AND CONTENTS</p>
                  <p>The Services may be made available or accessed in connection with third-party services and content (including advertising) that XtayPro does not control. You acknowledge that different Terms of Use and Privacy Policies may apply to your use of such third-party services and content. XtayPro does not endorse such third-party services and content and in no event shall XtayPro be responsible or liable for any products or services of such third-party providers. Additionally, Apple Inc., Google Inc., Microsoft Corporation or BlackBerry Limited and/or their applicable international subsidiaries and affiliates will be third-party beneficiaries to this contract if you access the Services using Applications developed for Apple iOS, Google Android, Microsoft Windows, or Blackberry-powered mobile devices, respectively. These third-party beneficiaries are not parties to this contract and are not responsible for the provision or support of the Services in any manner. Your access to the Services using these devices is subject to terms set forth in the applicable third-party beneficiary’s terms of service.</p>
                 </div>
                 <div className="panel-body">
                  <p>NETWORK ACCESS AND DEVICES</p>
                  <p>You are responsible for obtaining the data network access necessary to use the Services. Your mobile network’s data and messaging rates and fees may apply if you access or use the Services from a wireless-enabled device and you shall be responsible for such rates and fees. You are responsible for acquiring and updating compatible hardware or devices necessary to access and use the Services and Applications and any updates thereto. XtayPro does not guarantee that the Services, or any portion thereof, will function on any particular hardware or devices. In addition, the Services may be subject to malfunctions and delays inherent in the use of the Internet and electronic communications.</p>
                 </div>
                 <div className="panel-body">
                  <p>OWNERSHIP</p>
                  <p>The Services and all rights therein are and shall remain XtayPro’s property or the property of XtayPro’s licensors. Neither these Terms nor your use of the Services convey or grant to you any rights: (i) in or related to the Services except for the limited license granted above; or (ii) to use or reference in any manner XtayPro’s company names, logos, product and service names, trademarks or services marks or those of XtayPro’s licensors.</p>
                 </div>
                </div>
                <div className="panel panel-default">
                 <div className="panel-heading">
                  <h4 className="panel-title">5. CURRENCY CONVERSION AND PAYMENT</h4>
                 </div>
                 <div className="panel-body">
                  <p>XtayPro will not be liable for any loss of conversion in payout due to the rate of currency conversion and any fees charge based on the payment partner, such as PayPal, GIRO Bank Transfer, TT or any other payment method employed.</p>
                  <p>XtayPro is also not liable to refund any service fee. For accountability and to prevent any disputes, Users should agree on registered mail to track and confirm receipt of product or to transaction at the designated collection centers.</p>
                  <p>XtayPro will assist to the best ability to resolve and mediate any dispute if necessary and has the final decision in such cases.</p>
                 </div>
                </div>
                <div className="panel panel-default">
                 <div className="panel-heading">
                  <h4 className="panel-title">6. DISCLAIMER; LIMITATION OF LIABILITY; INDEMNITY</h4>
                 </div>
                 <div className="panel-body">
                  <p><strong>DISCLAIMER.</strong></p>
                  <p>THE SERVICES ARE PROVIDED “AS IS” AND “AS AVAILABLE.” XTAYPRO DISCLAIMS ALL REPRESENTATIONS AND WARRANTIES, EXPRESS, IMPLIED OR STATUTORY, NOT EXPRESSLY SET OUT IN THESE TERMS, INCLUDING THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT. IN ADDITION, XTAYPRO MAKES NO REPRESENTATION, WARRANTY, OR GUARANTEE REGARDING THE RELIABILITY, TIMELINESS, QUALITY, SUITABILITY OR AVAILABILITY OF THE SERVICES OR ANY LISTING SERVICES OR GOODS REQUESTED THROUGH THE USE OF THE SERVICES, OR THAT THE SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE. XTAYPRO DOES NOT GUARANTEE THE QUALITY, SUITABILITY, SAFETY OR ABILITY OF THIRD-PARTY PROVIDERS. YOU AGREE THAT THE ENTIRE RISK ARISING OUT OF YOUR USE OF THE SERVICES, AND ANY SERVICE OR GOOD REQUESTED IN CONNECTION THEREWITH, REMAINS SOLELY WITH YOU, TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW. IF YOU CHOOSE TO USE THE SITE, APPLICATION, SERVICES AND PARTICIPATE IN THE REFERRAL PROGRAM, YOU DO SO AT YOUR SOLE RISK. YOU ACKNOWLEDGE AND AGREE THAT XTAYPRO DOES NOT HAVE AN OBLIGATION TO CONDUCT BACKGROUND CHECKS ON ANY USER, INCLUDING, BUT NOT LIMITED TO, XTAYERS AND XTAYEES, BUT MAY CONDUCT SUCH BACKGROUND CHECKS IN ITS SOLE DISCRETION. THE SITE, APPLICATION, SERVICES, COLLECTIVE CONTENT AND REFERRAL PROGRAM ARE PROVIDED “AS IS”, WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESS OR IMPLIED. WITHOUT LIMITING THE FOREGOING, XTAYPRO EXPLICITLY DISCLAIMS ANY WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, QUIET ENJOYMENT OR NON-INFRINGEMENT, AND ANY WARRANTIES ARISING OUT OF COURSE OF TRANSACTIONING OR USAGE OF TRADE.</p>
                  <p>NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED FROM XTAYPRO OR THROUGH THE SITE, APPLICATION, SERVICES OR COLLECTIVE CONTENT, WILL CREATE ANY WARRANTY NOT EXPRESSLY MADE HEREIN.</p>
                  <p>YOU ARE SOLELY RESPONSIBLE FOR ALL OF YOUR COMMUNICATIONS AND INTERACTIONS WITH OTHER USERS OF THE SITE, APPLICATION OR SERVICES AND WITH OTHER PERSONS WITH WHOM YOU COMMUNICATE OR INTERACT AS A RESULT OF YOUR USE OF THE SITE, APPLICATION OR SERVICES, INCLUDING, BUT NOT LIMITED TO, ANY XTAYER AND XTAYEE.</p>
                  <p>YOU UNDERSTAND THAT XTAYPRO DOES NOT MAKE ANY ATTEMPT TO VERIFY THE STATEMENTS OF USERS OF THE SITE, APPLICATION OR SERVICES.</p>
                  <p>XTAYPRO MAKES NO REPRESENTATIONS OR WARRANTIES AS TO THE CONDUCT OF USERS OF THE SITE, APPLICATION OR SERVICES OR THEIR COMPATIBILITY WITH ANY CURRENT OR FUTURE USERS OF THE SITE, APPLICATION OR SERVICES.</p>
                  <p>YOU AGREE TO TAKE REASONABLE PRECAUTIONS IN ALL COMMUNICATIONS AND INTERACTIONS WITH OTHER USERS OF THE SITE, APPLICATION OR SERVICES AND WITH OTHER PERSONS WITH WHOM YOU COMMUNICATE OR INTERACT AS A RESULT OF YOUR USE OF THE SITE, APPLICATION OR SERVICES, INCLUDING, BUT NOT LIMITED TO, XTAYERS AND XTAYEES, PARTICULARLY IF YOU DECIDE TO MEET IN PERSON REGARDLESS OF WHETHER SUCH MEETINGS ARE ORGANIZED BY XTAYPRO.</p>
                 </div>
                 <div className="panel-body">
                  <p><strong>LIMITATION OF LIABILITY.</strong></p>
                  <p>XTAYPRO SHALL NOT BE LIABLE FOR INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, PUNITIVE OR CONSEQUENTIAL DAMAGES, INCLUDING LOST PROFITS, LOST DATA, PERSONAL INJURY OR PROPERTY DAMAGE RELATED TO, IN CONNECTION WITH, OR OTHERWISE RESULTING FROM ANY USE OF THE SERVICES, EVEN IF XTAYPRO HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. XTAYPRO SHALL NOT BE LIABLE FOR ANY DAMAGES, LIABILITY OR LOSSES ARISING OUT OF: (i) YOUR USE OF OR RELIANCE ON THE SERVICES OR YOUR INABILITY TO ACCESS OR USE THE SERVICES; OR (ii) ANY TRANSACTION OR RELATIONSHIP BETWEEN YOU AND ANY THIRD-PARTY PROVIDER, EVEN IF XTAYPRO HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. XTAYPRO SHALL NOT BE LIABLE FOR DELAY OR FAILURE IN PERFORMANCE RESULTING FROM CAUSES BEYOND XTAYPRO’S REASONABLE CONTROL.</p>
                  <p>XTAYPRO’S SERVICES MAY BE USED BY YOU TO REQUEST AND SCHEDULE TRANSPORTATION, GOODS OR LOGISTICS SERVICES WITH THIRD-PARTY PROVIDERS, BUT YOU AGREE THAT XTAYPRO HAS NO RESPONSIBILITY OR LIABILITY TO YOU RELATED TO ANY TRANSPORTATION, GOODS OR LOGISTICS SERVICES PROVIDED TO YOU BY THIRD-PARTY PROVIDERS OTHER THAN AS EXPRESSLY SET FORTH IN THESE TERMS.</p>
                  <p>THE LIMITATIONS AND DISCLAIMER IN THIS SECTION 5 DO NOT PURPORT TO LIMIT LIABILITY OR ALTER YOUR RIGHTS AS A CONSUMER THAT CANNOT BE EXCLUDED UNDER APPLICABLE LAW.</p>
                 </div>
                 <div className="panel-body">
                  <p><strong>INDEMNITY.</strong></p>
                  <p>YOU AGREE TO INDEMNIFY AND HOLD XTAYPRO AND ITS OFFICERS, DIRECTORS, EMPLOYEES AND AGENTS HARMLESS FROM ANY AND ALL CLAIMS, DEMANDS, LOSSES, LIABILITIES, AND EXPENSES (INCLUDING ATTORNEYS’ FEES) ARISING OUT OF OR IN CONNECTION WITH: (I) YOUR USE OF THE SERVICES OR SERVICES OR GOODS OBTAINED THROUGH YOUR USE OF THE SERVICES OR ANY OTHER CLAIM THAT ASSOCIATED WITH THE SITE FROM YOUR USAGE, INCLUDING, BUT NOT LIMITED TO, ANY ERRORS OR INCOMPLETENESS IN ANY CONTENT, OR LOSS OR DAMAGE OF ANY KIND ARISING AS RESULT OF USE OF THE CONTENT OR ANY PRODUCT THAT POSTED, DISTRIBUTED, OR OTHERWISE MADE AVAILABLE THROUGH THE SITE, EVEN IF THE POSSIBILITY OF SUCH DAMAGES AND CLAIMS HAVE BEEN ADVISED; (II) YOUR BREACH OR VIOLATION OF ANY OF THESE TERMS; (III) XTAYPRO’S USE OF YOUR USER CONTENT; OR (IV) YOUR VIOLATION OF THE RIGHTS OF ANY THIRD PARTY, INCLUDING THIRD PARTY PROVIDERS.</p>
                  <p>XTAYPRO DOES NOT EXPRESS AND MAKES NO WARRANTY THAT XTAYPRO WILL BE FREE FROM LOSS, CORRUPTION, ATTACK, VIRUSES, INTERFERENCE, HACKING, OR OTHER SECURITY INTRUSION, AND XTAYPRO DISCLAIMS ANY LIABILITY RELATING THERETO. YOU WILL BE SOLELY RESPONSIBLE FOR BACKING UP YOUR OWN SYSTEM.</p>
                 </div>
                </div>
                <div className="panel panel-default">
                 <div className="panel-heading">
                  <h4 className="panel-title">Changes to these Terms of Use</h4>
                 </div>
                 <div className="panel-body">
                  XtayPro may change these Terms of Use at any time. When that happens, XtayPro will mark the Terms of Use with the date on which they were most recently updated.
                 </div>
                </div>
                <div className="panel panel-default">
                 <div className="panel-heading">
                  <h4 className="panel-title">Governing Law; Arbitration</h4>
                 </div>
                 <div className="panel-body">
                  These Terms of Use are governed by and shall be interpreted in accordance with the laws of the Users' countries of residence and countries of destination. As they apply to contracts made and performed entirely within these countries.
                 </div>
                </div>
                <div className="panel panel-default">
                 <div className="panel-heading">
                  <h4 className="panel-title">The Role of XtayPro in Any Transaction</h4>
                 </div>
                 <div className="panel-body">
                  XtayPro’s only role in any Transaction between a Xtayer and a Xtayee for the delivery of Items is that of a facilitator and intermediary whose App has allowed the Users to locate each other and work out the details of a delivery transaction that meets their respective needs. XtayPro has no responsibility for any party’s performance of its obligations in that transaction.
                 </div>
                </div>
                <div className="panel panel-default">
                 <div className="panel-heading">
                  <h4 className="panel-title">Digital Millennium Copyright Act</h4>
                 </div>
                 <div className="panel-body">
                  If you believe that work residing or accessible on or through XtayPro infringes a copyright, please send a notice of copyright infringement to XtayPro’s Agent for Notice with the following information (your “Notice”):
                  <ul>
                    <li>Identification of the work or material that you claim has been infringed. If this material exists online, please provide a URL</li>
                    <li>Identification of the material that is claimed to be infringing, Including its location on XtayPro, with sufficient detail so that XtayPro is capable of finding the material and verifying its existence (e.g., in most circumstances, we will need a URL)</li>
                    <li>Contact information, Including your name, address, telephone number, and email address</li>
                    <li>A statement by you that you have a good faith belief that the disputed use is not authorized by the copyright owner, its agent or law</li>
                    <li>A statement by you that the above information in your Notice is accurate and that, under penalty of perjury, you are the copyright owner or authorized to act on the copyright owner’s behalf</li>
                    <li>A physical or electronic signature of the owner of the copyright that has been allegedly infringed or a person authorized to act on behalf of the owner</li>
                   </ul>
                 </div>
                </div>
                <div className="panel panel-default">
                 <div className="panel-heading">
                  <h4 className="panel-title">How to Contact XtayPro</h4>
                 </div>
                 <div className="panel-body">
                  <p>If you have any questions or feedbacks, you can contact us by email, telephone, or in person as provided on our website at <a href="www.xtaypro.com">www.xtaypro.com</a>.</p>
                  <p>You grant us a perpetual, world-wide, fully-paid license to copy, perform, and distribute that feedback, to create derivative works of the feedback, and to copy, perform, and distribute those derivative works.</p>
                 </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="xtayer_terms" className="section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="titleSection">
                <h2>Xtayers’ Terms</h2>
                <div className="coloredLine"></div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-8 col-md-offset-2 col-sm-10 col-sm-offset-1 col-xs-12">
              <div className="panel-group">
                <div className="panel panel-default">
                  <div className="panel-heading">
                   <h4 className="panel-title">Transaction Steps</h4>
                  </div>
                  <div className="panel-body">
                   Xtayer sees the proposed reward for the delivery of items and other details of the request. Xtayer can begin exchange with Xtayee by starting a negotiation with the latter.
                  </div>
                  <div className="panel-body">
                   After mutual consent has been reached on terms, Xtayer agrees to perform the service and establish a transaction in exchange for the reward by clicking “Agree”. Xtayer agrees hereby to pay any duties, taxes or other similar charges that may be due for importing the item.
                  </div>
                  <div className="panel-body">
                   During negotiation, both sides may agree to ensure the success of the transaction by depositing an amount into XtayPro’s Escrow Services. XtayPro will remit this amount back to Xtayer and Xtayee after both sides have confirmed the success of the transaction. Users will bear all banking fees and similar charges which arise during the escrow process. Xtayer and Xtayee agree that XtayPro will use this escrow amount to mediate in case of dispute and remit part or all of it to the damaged party.
                  </div>
                </div>
                <div className="panel panel-default">
                  <div className="panel-heading">
                   <h4 className="panel-title">Import Restrictions and Charges</h4>
                  </div>
                  <div className="panel-body">
                   The reward, which Xtayee and Xtayer agree upon during negotiation, will be the final reward that Xtayee is required to give Xtayer. Before agreeing to the terms of each transaction, please make sure you have acknowledged any import duties that could be associated with that transaction. Xtayer will be responsible for any legal charges that may occur during transaction.
                  </div>
                </div>
                <div className="panel panel-default">
                  <div className="panel-heading">
                   <h4 className="panel-title">Dispute Resolution</h4>
                  </div>
                  <div className="panel-body">
                   Upon request by Xtayee or Xtayer, XtayPro will assist in dispute resolution between the parties by offering non-binding mediation services. Both parties agree to participate reasonably in such dispute resolution process, which will typically be performed via email, phone or instant messaging services. In order to request dispute resolution by XtayPro, you can either use our built-in report button, call our number, register the case at our office or send an email to <a href="mailto:report@xtaypro.com">report@xtaypro.com</a>.
                  </div>
                </div>
                <div className="panel panel-default">
                  <div className="panel-heading">
                   <h4 className="panel-title">XtayPro’s Limited Roles; No Liability</h4>
                  </div>
                  <div className="panel-body">
                   XtayPro’s only roles in the transaction between Xtayee and Xtayer are: (a) to connect Xtayee with Xtayer, to enable Xtayee and Xtayer to voluntarily enter into the transaction on terms of their own choosing; and (b) to provide limited dispute resolution services. XtayPro shall not be responsible for any customs or import problems that may arise from the transaction, or for any loss, injury, or damage that either Xtayee or Xtayer may experience in connection with or arising out of the transaction.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="xtayee_terms" className="section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="titleSection">
                <h2>Xtayees’ Terms</h2>
                <div className="coloredLine"></div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-8 col-md-offset-2 col-sm-10 col-sm-offset-1 col-xs-12">
              <div className="panel-group">
                <div className="panel panel-default">
                  <div className="panel-heading">
                   <h4 className="panel-title">Transaction Steps</h4>
                  </div>
                  <div className="panel-body">
                   Xtayee sets the price of the item plus applicable taxes, customs duties, etc. (manually, based on his knowledge), affirms that the item is not anything that cannot be imported lawfully into the destination country. Xtayee enters his name, delivery address and other necessary information, sets the reward for the Xtayer (manually).
                  </div>
                  <div className="panel-body">
                   Xtayee sees proposals for the requests and can exchange with Xtayer(s) on specific terms and rewards. After finding mutual consent with Xtayer(s), both sides may agree to establish transaction.
                  </div>
                  <div className="panel-body">
                   During negotiation, both sides may agree to ensure the success of the transaction by depositing an amount into XtayPro’s Escrow Services. XtayPro will remit this amount back to Xtayer and Xtayee after both sides have confirmed the success of the transaction. Users will bear all banking fees and similar charges which arise during the escrow process. Xtayer and Xtayee agree that XtayPro will use this escrow amount to mediate in case of dispute and remit part or all of it to the damaged party.
                  </div>
                </div>
                <div className="panel panel-default">
                  <div className="panel-heading">
                   <h4 className="panel-title">Import Restrictions and Charges</h4>
                  </div>
                  <div className="panel-body">
                   The reward, which Xtayee and Xtayer agree upon during negotiation, will be the final reward that Xtayee is required to give Xtayer. Before agreeing to the terms of each transaction, please make sure you have acknowledged any import duties that could be associated with that transaction. Xtayer will be responsible for any legal charges that may occur during transaction.
                  </div>
                </div>
                <div className="panel panel-default">
                  <div className="panel-heading">
                   <h4 className="panel-title">Dispute Resolution</h4>
                  </div>
                  <div className="panel-body">
                   Upon request by Xtayee or Xtayer, XtayPro will assist in dispute resolution between the parties by offering non-binding mediation services. Both parties agree to participate reasonably in such dispute resolution process, which will typically be performed via email, phone or instant messaging services. In order to request dispute resolution by XtayPro, you can either use our built-in report button, call our number, register the case at our office or send an email to <a href="mailto:report@xtaypro.com">report@xtaypro.com</a>.
                  </div>
                </div>
                <div className="panel panel-default">
                  <div className="panel-heading">
                   <h4 className="panel-title">XtayPro’s Limited Roles; No Liability</h4>
                  </div>
                  <div className="panel-body">
                   XtayPro’s only roles in the transaction between Xtayee and Xtayer are: (a) to connect Xtayee with Xtayer, to enable Xtayee and Xtayer to voluntarily enter into the transaction on terms of their own choosing; and (b) to provide limited dispute resolution services. XtayPro shall not be responsible for any customs or import problems that may arise from the transaction, or for any loss, injury, or damage that either Xtayee or Xtayer may experience in connection with or arising out of the transaction.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Terms;
