import React from 'react';

export default [
  {
    question: 'Làm sao tôi biết được người kia có đàng hoàng và không lừa đảo tôi hay không?',
    answer: <>Bạn hãy xem tài khoản của bên kia xem họ đã thực hiện thành công bao nhiêu giao dịch rồi và xem những người dùng khác đánh giá họ ra sao. Ngoài ra, những người dùng thật thường có xu hướng giới thiệu khá đàng hoàng về bản thân và cũng sẵn lòng chia sẻ nhiều hơn về họ. Nói chung thì hạng người dùng cao hơn sẽ an toàn hơn, người dùng hạng Gold là tương đối chắc chắn rồi. Bạn có thể vào <a href="/ranking" target="_blank">link này</a> để xem các phân hạng người dùng khác nhau như thế nào. Nếu bạn muốn chắc 100% thì có thể dùng dịch vụ <a href="/escrow">THANH TOÁN ĐẢM BẢO</a> của chúng tôi trong phần <a href="/faq">CÂU HỎI THƯỜNG GẶP</a> nhé.</>
  },
  {
    question: 'Tôi vẫn hay đăng chuyến đi và yêu cầu trên Facebook, bây giờ lại phải đăng thêm trên sàn XtayPro à? Phiền quá!',
    answer: "Không đâu! Chúng tôi còn giảm phiền hà cho bạn nữa là khác. Bạn có thể đăng song song trên XtayPro và các mạng xã hội khác bằng cách dùng nút Chia sẻ của app XtayPro. Chúng tôi đang phối hợp với nhiều nhóm Facebook để tăng xác suất tìm được người phù hợp cho bạn."
  },
  {
    question: 'Tôi có bị phạt nếu hoàn thành các giao dịch ở ngoài XtayPro không?',
    answer: <>
              Tất nhiên là không. Chúng tôi xây dựng công cụ này để giúp đỡ mọi người đến với nhau và chia sẻ thông tin chứ không phải để gom người dùng một cách vô nghĩa, bởi vì chính chúng tôi cũng cần dùng dịch vụ này. Tuy nhiên, chúng tôi vẫn khuyên bạn nên hoàn thành giao dịch ở trong XtayPro, bởi vì:
              <ul>
                <li>Bạn được +1 điểm cho mỗi giao dịch thành công. Bằng cách này, mọi người có thể đánh giá và lựa chọn giao dịch với bạn, và ngược lại.</li>
                <li>Chúng tôi có thể hỗ trợ bạn khi có sự cố hay tranh chấp. Khi bạn giao dịch bên ngoài, chúng tôi không thể giúp bạn với một đoạn chat hay ảnh chụp màn hình mà chúng tôi không thể xác thực được.</li>
                <li>Bạn có thể lựa chọn giải pháp THANH TOÁN ĐẢM BẢO của chúng tôi, khi đó, chúng tôi sẽ tạm giữ một số tiền của một hoặc cả hai bên cho đến khi giao dịch được xác nhận là thành công.</li>
              </ul>
            </>
  }
]
