import React from 'react';

export default {
  expandButtonText: {
    false: 'Xem tất cả câu trả lời',
    true: 'Đóng tất cả câu trả lời'
  },
  promise: "Khi người dùng đặt thêm câu hỏi thì chúng tôi lại cập nhật bảng này để phục vụ bạn tốt hơn!",
  faqs: [
    {
      question: '“Xtayer” và “xtayee” nghĩa là gì vậy?',
      answer: '“Xtayer” là người xách đồ, hay còn gọi là shipper. Còn “xtayee” là người nhờ xách đồ. Thông thường thì xtayee cũng hay nhờ xtayer mua hàng giúp luôn.'
    },
    {
      question: 'Làm sao tôi tìm được người đi/yêu cầu tuyến đường đó?',
      answer: <>Mời bạn xem hướng dẫn chi tiết <a href="https://www.facebook.com/pg/xtayprocom/photos/?tab=album&album_id=1655155991166638" target="_blank" rel="nofollow noopener noreferrer">tại đây</a> nhé.</>
    },
    {
      question: 'Tôi không tìm được người phù hợp với nhu cầu, bây giờ sao đây?',
      answer: <>Bạn có thể bấm nút X góc dưới bên phải để tạo đơn hàng/chuyến đi mới. Sẽ có người thấy và liên hệ với bạn sau thôi. Còn nếu không có thì đành “<span role="img" aria-label="emoji">🎼</span>Nghẹn ngào giây phút ta chấp nhận sống không cần nhauuu <span role="img" aria-label="emoji">♬</span>” thôi, hix</>
    },
    {
      question: 'XtayPro có những hình thức thanh toán nào?',
      answer: 'Chúng tôi không trực tiếp tham gia vào quy trình thanh toán. Tất cả tùy thuộc vào bạn và bên kia thôi; các bạn có thể thanh toán tiền khi nhận hàng, có thể chuyển khoản trước, thậm chí bạn có thể chỉ yêu cầu 1 ly cà phê và nhờ người kia dẫn đi xem 1 vòng thành phố cũng được nữa. Suy cho cùng thì chúng ta xây dựng cộng đồng này để kết nối mọi người và để chia sẻ văn hóa mà. Tuy nhiên nếu bạn muốn thật chắc chắn thì chúng tôi cũng có cung cấp dịch vụ THANH TOÁN ĐẢM BẢO, bạn xem thông tin ở dưới nhé.'
    },
    {
      question: 'Tôi đã tìm được người rồi, làm sao liên lạc với họ?',
      answer: <>Khi bạn tìm được đơn hàng/chuyến đi phù hợp với nhu cầu của bạn, bạn có thể xuống dưới cùng của màn hình và bấm nút “Thương lượng với người này”. Cứ thoải mái, trước khi bấm nút CHẤP NHẬN ở bên trong màn hình giao dịch thì bạn <strong>không có nghĩa vụ</strong> phải làm gì cả.</>
    },
    {
      question: 'Tôi nên hẹn gặp người khác ở đâu để nhận/giao hàng?',
      answer: 'An toàn nhất thì bạn có thể hẹn gặp tại những nơi công cộng như sân bay, quán cà phê, công viên v.v… Ngoài ra bạn cũng có thể hẹn gặp và giao hàng tại văn phòng giao dịch của chúng tôi.'
    },
    {
      question: 'Tôi không điền địa điểm và thời gian có sao không?',
      answer: 'Theo kinh nghiệm của chúng tôi thì nhiều khi người mua không quan tâm lắm là hàng được đưa từ nước A hay nước B về hay thời gian giao hàng là lúc nào, miễn là hàng thật và giá tốt là được. Tuy nhiên thì các đơn hàng và chuyến đi có xác định thời gian và địa điểm sẽ được ưu tiên hơn khi tìm kiếm.'
    },
    {
      question: 'Giá vận chuyển như thế nào thì hợp lý?',
      answer: 'Điều này phụ thuộc rất nhiều vào tuyến đường, thời điểm trong năm và sự chuyên nghiệp của những người dùng khác. Bạn có quyền đề nghị giá, thương thảo và chọn người mà bạn cảm thấy tốt nhất.'
    },
    {
      question: 'Tại sao tôi không đăng bài mới hoặc bắt đầu giao dịch với ai được?',
      answer: 'Bạn kiểm tra lại đã kích hoạt tài khoản theo hướng dẫn mà chúng tôi gửi email đến cho bạn chưa nhé.'
    },
    {
      question: 'Tôi có phải đăng ký tài khoản mới không?',
      answer: 'Bạn cũng có thể đăng nhập bằng tài khoản Facebook. Chúng tôi không phân biệt người dùng.'
    },
    {
      question: 'Tôi có thể dùng dịch vụ không cần đăng ký tài khoản gì hết được không?',
      answer: 'Bạn có thể chọn “Dùng thử không cần đăng ký” để xem tất cả nội dung. Tuy nhiên bạn sẽ không thể đăng bài hoặc liên hệ với người dùng khác nếu không đăng nhập.'
    },
    {
      question: 'Tôi có bị phạt nếu hoàn thành các giao dịch ở ngoài XtayPro không?',
      answer: <>
                Tất nhiên là không. Chúng tôi xây dựng công cụ này để giúp đỡ mọi người đến với nhau và chia sẻ thông tin chứ không phải để gom người dùng một cách vô nghĩa, bởi vì chính chúng tôi cũng cần dùng dịch vụ này. Tuy nhiên, chúng tôi vẫn khuyên bạn nên hoàn thành giao dịch ở trong XtayPro, bởi vì:
                <ul>
                  <li>Bạn được +1 điểm cho mỗi giao dịch thành công. Bằng cách này, mọi người có thể đánh giá và lựa chọn giao dịch với bạn, và ngược lại.</li>
                  <li>Chúng tôi có thể hỗ trợ bạn khi có sự cố hay tranh chấp. Khi bạn giao dịch bên ngoài, chúng tôi không thể giúp bạn với một đoạn chat hay ảnh chụp màn hình mà chúng tôi không thể xác thực được.</li>
                  <li>Bạn có thể lựa chọn giải pháp THANH TOÁN ĐẢM BẢO của chúng tôi, khi đó, chúng tôi sẽ tạm giữ một số tiền của một hoặc cả hai bên cho đến khi giao dịch được xác nhận là thành công.</li>
                </ul>
              </>
    },
    {
      question: 'Tôi có thể thương lượng các điều khoản của 1 giao dịch được không?',
      answer: <>Tất nhiên là được, đây là điểm nhấn của dịch vụ này mà. Bạn cứ thoải mái thương thảo các điều kiện của giao dịch <strong>trước khi</strong> bấm nút CHẤP NHẬN là được. Sau khi cả hai bên đã chấp nhận thì các bạn phải thực hiện đúng những gì mình hứa nhé!</>
    },
    {
      question: 'Làm sao tôi biết được người kia có đàng hoàng và không lừa đảo tôi hay không?',
      answer: <>Bạn hãy xem tài khoản của bên kia xem họ đã thực hiện thành công bao nhiêu giao dịch rồi và xem những người dùng khác đánh giá họ ra sao. Ngoài ra, những người dùng thật thường có xu hướng giới thiệu khá đàng hoàng về bản thân và cũng sẵn lòng chia sẻ nhiều hơn về họ. Nói chung thì hạng người dùng cao hơn sẽ an toàn hơn, người dùng hạng Gold là tương đối chắc chắn rồi. Bạn có thể vào <a href="/ranking" target="_blank" rel="noopener noreferrer">link này</a> để xem các phân hạng người dùng khác nhau như thế nào. Nếu bạn muốn chắc 100% thì có thể dùng dịch vụ THANH TOÁN ĐẢM BẢO của chúng tôi ở dưới nhé.</>
    },
    {
      question: 'Dịch vụ THANH TOÁN ĐẢM BẢO là gì?',
      answer: <>Rất đơn giản: Cả hai bên sẽ chuyển một khoản tiền nào đó vào tài khoản ngân hàng của XtayPro cho đến khi giao dịch thành công tốt đẹp. Gửi 20% hay đến tận 100% giá trị món hàng là tùy vào thỏa thuận của hai bên. Sau khi giao dịch đã kết thúc thành công, XtayPro sẽ gửi trả lại số tiền đảm bảo này mà không tính thêm bất kỳ phí dịch vụ nào. Khi chuyển khoản, bạn vui lòng ghi chú thích theo mẫu sau: XtayPro Mãsốgiaodịch Mãsốngườidùngcủabạn. Vào link <a href="/escrow" target="_blank" rel="noopener noreferrer">này</a> để xem tài khoản ngân hàng của chúng tôi.</>
    },
    {
      question: 'Tôi nhận lại tiền cọc đảm bảo như thế nào?',
      answer: 'Nếu bạn mới sử dụng dịch vụ thanh toán đảm bảo lần đầu tiên, sau khi giao dịch thành công, chúng tôi sẽ liên hệ với bạn để xin số tài khoản và chuyển trả lại số tiền cọc này. Với những giao dịch sau thì quy trình này sẽ tự được thực hiện. Nếu muốn, bạn cũng có thể nhận lại tiền mặt nếu bạn đến văn phòng giao dịch của chúng tôi. Tuy nhiên chúng tôi vẫn thích chuyển khoản hơn.'
    },
    {
      question: 'Tôi lỡ tay báo xấu một giao dịch hoặc/và một người dùng khác. Có tệ lắm không?',
      answer: '*cau mày* Hmm, chúng tôi tin rằng bạn không cố ý làm vậy. Nhưng mong bạn hiểu cho, chúng tôi xử lý rất nghiêm khắc các báo cáo giả và các hành vi lừa đảo. Nếu bạn cứ tiếp tục phạm lỗi này thì chúng tôi buộc phải khóa tài khoản của bạn để bảo vệ sự trong sạch cho cộng đồng.'
    },
    {
      question: 'Làm sao tôi biết được họ sẽ giao hàng thật hay hàng giả được sản xuất tại nước khác?',
      answer: <>Nếu bạn nhờ mua hàng hộ và ship về thì nên yêu cầu có hóa đơn mua hàng gốc. Ngay cả trước khi họ bắt đầu khởi hành, bạn có thể yêu cầu họ chụp ảnh hóa đơn mua hàng và gửi cho bạn, tốt nhất là có mặt của họ. Bạn chú ý là hình ảnh phải được gửi trong app XtayPro nhé, không là chúng tôi không thể giúp bạn khi có sự cố được. Nếu bạn chỉ nhờ ship hộ mà không mua thì hãy nhờ ai đó đánh dấu hàng của bạn trước khi đưa cho họ và kiểm tra dấu này khi bạn nhận hàng. Chúng tôi nhắc bạn nên xem tài khoản và phân hạng người dùng của họ trước khi giao dịch! Với chính sách <strong>không khoan nhượng</strong> hành vi lừa đảo, chúng tôi chắc chắn sẽ trừng trị những kẻ gian xảo kể cả chỉ vi phạm MỘT lần.</>
    },
    {
      question: 'Thuế và phí hải quan thì sao?',
      answer: 'Bạn nên làm rõ điểm này khi thương thảo với bên kia. Thông thường thì xtayer sẽ chịu trách nhiệm về phần này và cộng sẵn vào tổng chi phí. Mức giá mà hai bên đồng ý khi bấm nút CHẤP NHẬN sẽ là mức giá cuối cùng.'
    },
    {
      question: 'Hàng bị hải quan giữ lại, làm gì bây giờ?',
      answer: 'Trước đi yêu cầu hoặc đồng ý đem một món đồ đến một nước khác, bạn phải nắm được luật và quy định của các nước có liên quan. Xin đừng yêu cầu hoặc mang đồ bất hợp pháp và hãy tuân thủ luật pháp. Chúng tôi sẽ xử lý cụ thể từng trường hợp để xem có giúp được gì cho bạn không.'
    },
    {
      question: 'Tôi đã giao/nhận hàng rồi, giờ sao?',
      answer: 'Sau khi giao/nhận hàng, hai bên kiểm tra xem mọi thứ đều đúng như thỏa thuận hết không, ví dụ như thời gian giao hàng, chất lượng hàng hóa, giá vận chuyển. Sau khi hai bên đã bấm nút THÀNH CÔNG thì chúng tôi sẽ coi như là giao dịch đã kết thúc tốt đẹp và chuyển trả lại tiền cọc đảm bảo cho mỗi bên nếu các bạn sử dụng hình thức THANH TOÁN ĐẢM BẢO. Nếu bạn cảm thấy hài lòng với người dùng kia thì hãy đánh giá họ 5 sao và bình luận tốt cho họ nhé. Họ cũng sẽ làm thế lại với bạn vì sự nhiệt tình của bạn đấy!'
    },
    {
      question: 'Tôi nghe nói rằng khi giới thiệu người khác thì cả hai sẽ được thưởng?',
      answer: <>Đúng vậy, nhưng còn tùy vào đợt tặng thưởng của chúng tôi. Vui lòng xem thêm thông tin <a href="/rewards" target="_blank" rel="noopener noreferrer">tại đây</a>.</>
    },
    {
      question: 'Làm sao để tôi được thăng hạng người dùng?',
      answer: <>Tại thời điểm này thì bạn có thể đăng ký thăng hạng bằng email hoặc điện thoại. Nếu dùng email thì bạn hãy gửi thư đến <a href="mailto:admin@xtaypro.com">admin@xtaypro.com</a> với tiêu đề [Yêu cầu thăng hạng XtayPro] và cung cấp Mã số người dùng (UserID) của bạn, chúng tôi sẽ trả lời và hướng dẫn chi tiết. Chúng tôi cũng dùng nhiều dịch vụ tin nhắn phổ biến như Facebook, Zalo, Viber, LINE, Whatsapp v.v… Số điện thoại của chúng tôi là +841234567890. Trang Facebook của chúng tôi là <a href="https://www.facebook.com/xtaypro.com" rel="nofollow noopener noreferrer" target="_blank">www.facebook.com/XtayPro.com</a></>
    },
    {
      question: 'Tôi vẫn hay đăng chuyến đi và yêu cầu trên Facebook, bây giờ lại phải đăng thêm trên sàn XtayPro à? Phiền quá!',
      answer: 'Không đâu! Chúng tôi còn giảm phiền hà cho bạn nữa là khác. Bạn có thể đăng song song trên XtayPro và các mạng xã hội khác bằng cách dùng nút Chia sẻ của app XtayPro. Chúng tôi đang phối hợp với nhiều nhóm Facebook để tăng xác suất tìm được người phù hợp cho bạn.'
    },
    {
      question: 'App này dùng tiện phết và tôi cũng có thêm vài ý tưởng hay cho các bạn thì liên hệ ai?',
      answer: <>Bạn có thể gửi email cho chúng tôi đến <a href="mailto:info@xtaypro.com">info@xtaypro.com</a> với tiêu đề [XtayPro improvement]. Bạn cũng có thể gửi tin nhắn Facebook cho chúng tôi tại <a href="https://facebook.com/xtaypro.com" target="_blank" rel="nofollow noopener noreferrer">https://facebook.com/xtaypro.com</a> và các app nhắn tin khác thông qua số +84987654321. Bản thân chúng tôi cũng sử dụng dịch vụ của mình nên chúng tôi rất cảm ơn đóng góp của bạn cho app ngày càng tốt hơn!</>
    },
    {
      question: 'Tôi gửi quà sinh nhật về cho bạn nhưng họ đòi mở gói quà?',
      answer: 'Việc này tùy thuộc vào thỏa thuận giữa hai bên trước khi bấm nút CHẤP NHẬN. Nhưng trừ khi bên kia có nói rõ rằng họ sẽ không mở gói quà của bạn ra, thông thường thì họ sẽ phải mở ra để kiểm tra trước chuyến bay theo quy định và pháp luật. Xin bạn đừng phiền lòng, chúng tôi chắc chắn rằng họ không có ý xấu đâu.'
    },
    {
      question: 'Mô hình của các bạn nhìn giống Uber nhỉ, khác nhau thế nào?',
      answer: 'Đây thật là một câu hỏi thú vị và cũng hơi lạc đề một chút. Riêng câu hỏi này cũng phải cần một bài thuyết trình rồi. Chúng tôi có thể chỉ ra một điểm dễ nhìn thấy nhất, đấy là sự khấu hao tài sản (và chi phí cơ hội nếu bạn đi sâu vào nữa, nhưng ở đây thì chúng tôi nêu đơn giản thôi). Khi một người hợp tác với Uber, họ có thể thoải mái lựa chọn giờ lái cho phù hợp với kế hoạch của họ. Nhưng khi bạn bay mà chưa dùng hết số cân miễn phí thì bạn đang lãng phí một chút. Và trong nhiều trường hợp chúng tôi biết thì thậm chí mua thêm cân để chuyển hàng hộ vẫn có lãi tương đối.'
    },
    {
      question: 'Tôi có cần phải sử dụng tên thật không?',
      answer: 'Nên làm. Thông thường sẽ tốt hơn nếu bạn sử dụng tên thật và có thêm thông tin về mình hoặc công ty của bạn trong phần tài khoản người dùng. Điều này củng cố tín nhiệm giữa người dùng với nhau và làm cộng đồng của chúng ta tốt hơn.'
    },
    {
      question: 'Công ty có đang mở rộng chuỗi chi nhánh không?',
      answer: 'XtayPro đặt mục tiêu tạo thuận lợi cho người dùng đến mức tối đa. Vì vậy chúng tôi rất hân hạnh được hợp tác với bạn. Nếu bạn quan tâm đến mô hình hoạt động của XtayPro, vui lòng liên hệ trực tiếp với Ban giám đốc của chúng tôi.'
    },
    {
      question: 'Có người trong khu vực tôi sống đang yêu cầu mua hộ một sản phẩm mà tôi đang có sẵn. Tôi có thể tiếp cận họ để bán trực tiếp không?',
      answer: 'Chúng tôi thấy không có vấn đề gì. Khi người mua có thể tiết kiệm được thời gian chờ và chi phí thấp hơn thì họ có thể sẽ mua luôn của bạn. Chỉ xin bạn nhớ rằng chúng tôi tuyệt đối KHÔNG dung dưỡng hành vi lừa đảo và gian lận nhé.'
    },
    {
      question: 'Dịch vụ xách tay này có cạnh tranh và đe dọa doanh thu của các dịch vụ bưu điện hiện hữu không?',
      answer: 'Lại một câu hỏi khó nữa! Chúng tôi biết rằng dịch vụ của chúng tôi sẽ có ảnh hưởng đến các phương thức chuyển hàng hiện hữu, nhưng việc này lại tùy thuộc nhiều vào sự biến đổi linh hoạt của các đơn vị vận tải truyền thống. Nó giống như cách mà dịch vụ nhắn tin miễn phí (Viber, Whatsapp…) đã và đang ảnh hưởng đến các công ty cung cấp dịch vụ viễn thông vậy. Tuy nhiên các nhà mạng nên nhìn thấy đây là cơ hội tăng doanh thu từ các gói cước dữ liệu, và thông qua đó, trở thành đối tác chiến lược với các dịch vụ tin nhắn miễn phí. Chúng tôi cũng rất hoan nghênh các công ty vận tải hiện nay phối hợp chặt chẽ và trở thành đối tác chiến lược lâu dài của chúng tôi.'
    },
    {
      question: 'Giải pháp của các bạn khá hay, nhưng liệu nó có tạo điều kiện cho việc buôn lậu và các hành vi phạm pháp khác không?',
      answer: 'Không hề. Nói như vậy chẳng khác nào quy kết Facebook và Google tội “câu kết với khủng bố” khi mà các phần tử khủng bố sử dụng tin nhắn Facebook và Gmail để liên lạc. Hoặc là bắt Wüsthof chịu trách nhiệm khi có người tấn công người khác bằng con dao cắt bánh mì của họ!'
    }
  ]
}
