import React, { Suspense } from 'react';
import { FacebookProvider, CustomChat } from 'react-facebook';

import Navigation from '../components/Navigation/Navigation';
import DownloadButtonList from '../components/DownloadButtonList/DownloadButtonList';
import Footer from '../components/Footer/Footer';
import LanguageSwitcher from '../components/LanguageSwitcher/LanguageSwitcher';
import Loader from '../components/Loader/Loader';
import FbCommentSection from '../components/FbCommentSection/FbCommentSection';

const App = () => {
  return (
    <Suspense fallback={<Loader />}>
      <Navigation />
      {window.location.pathname === '/green' && <DownloadButtonList />}
      <FbCommentSection />
      <Footer />
      {window.location.pathname !== '/green' && <LanguageSwitcher />}
      <FacebookProvider xfbml={true} version="v4.0" chatSupport={true} appId="1717564955151865">
        <CustomChat
          pageId="1165348193480756"
          themeColor="#1d6354"
          loggedInGreeting="Hi there! Welcome to XtayPro. How can we help you?"
          loggedOutGreeting="Hi there! Welcome to XtayPro. How can we help you?"
        />
      </FacebookProvider>
    </Suspense>
  );
}

export default App;
