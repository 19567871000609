import React from 'react';

export default [
  {
    question: 'Wie kann ich sicherstellen, dass andere Benutzer ehrlich sind und mich nicht betrügen werden?',
    answer: <>Überprüfen Sie bitte ihre Profile, um zu sehen, wie viele Transaktionen erfolgreich ausgeführt wurden und wie andere Benutzer ihren Service bewerten. Ehrliche Benutzer neigen dazu, eine anständige Selbsteinleitung zu schreiben und sind bereit, mehr über sich selbst zu erzählen. Eine gute Faustregel ist, ihren Benutzerrang zu überprüfen. Normalerweise ist ein Benutzer mit dem Rang Gold glaubhaft genug. Diese <a href="/ranking" target="_blank">link</a> zeigt, wie sich die Benutzerränge unterscheiden. Wenn Sie immer noch ganz sicher sein wollen, dass nichts schief geht, nutzen Sie unseren <a href="/escrow" target="_blank">ESCROW</a> Service, das hierunten im Detail in unseren <a href="/faq" target="_blank">FAQs</a> beschrieben wird.</>
  },
  {
    question: 'Ich poste meine Reisen und Anfragen in der Regel auf Facebook, jetzt muss ich einen weiteren Beitrag auf Ihrer Plattform veröffentlichen?',
    answer: "Nein! Wir sparen Ihnen den Ärger und ermöglichen Ihnen gleichzeitig, auf XtayPro und anderen sozialen Netzwerken zu posten. Sie müssen Ihre Beiträge nur teilen, indem Sie in unserer App auf die hilfreiche Schaltfläche 'Teilen' klicken. Wir arbeiten auch mit vielen Facebook-Gruppen zusammen, um Ihre Wahrscheinlichkeit zu erhöhen, ein Match zu bekommen."
  },
  {
    question: 'Werde ich bestraft, wenn ich das Geschäft außerhalb von XtayPro abgeschlossen habe?',
    answer: <>
              Natürlich nicht. Wir haben diese Plattform entwickelt, um allen zu helfen, miteinander in Kontakt zu treten und einander zu helfen, nicht um Benutzer zu horten, da wir auch unseren eigenen Service nutzen werden. Wir empfehlen jedoch dringend, dass Sie Ihre Transaktionen mit unserem Service abschließen, da:
              <ul>
                <li>Nach jeder erfolgreichen Transaktion erhalten Sie in Ihrem Profil eine Bewertung. Basierend auf dieser Bewertung können andere Nutzer grobschätzen und sich entscheiden, zukünftig mit Ihnen Geschäfte zu machen und umgekehrt.</li>
                <li>Wir werden die Beweise haben, um Ihnen im Falle von Betrug oder Streit zu helfen. Ein Chatverlauf oder Screenshot außerhalb unseres Systems können wir als Beweise leider nicht betrachten. Ohne konkrete Beweise werden wir Ihnen nicht helfen können.</li>
                <li>Sie können unseren ESCROW-Service für Ihre Transaktionen verwenden, bei denen wir vorübergehend einen Geldbetrag von einer oder beiden Seiten halten, bis die Transaktion als erfolgreich bestätigt wurde.</li>
              </ul>
            </>
  }
]
