import React from 'react';
import { appendScript } from '../../utils/dom.js';

const $ = window.$;

class AIOCarouselItem extends React.Component {
  render() {
    const { title, thumb, src } = this.props;

    return (
      <li data-title={title} data-bottom-thumb={thumb}>
        <img src={src} alt={title} />
      </li>
    );
  }
}

class AIOCarousel extends React.Component {
  componentWillMount() {
    appendScript('/js/allinone_carousel.js');
  }

  componentDidMount() {
    const { id, options } = this.props;
    $(`#${id}`).allinone_carousel(options);
  }

  render() {
    const { id, items } = this.props;

    var dom = (
      <div className="aio-carousel" id={id} ref={el => this.el = el}>
        <div className="myloader"></div>
        <ul className="allinone_carousel_list">
          {items.map((item, index) => (
            <AIOCarouselItem key={index}
                             title={item.title}
                             thumb={item.thumb}
                             src={item.src} />
          ))}
        </ul>
      </div>
    );

    return dom;
  }
}

export default AIOCarousel;
