import React from 'react';

const customsLinks = [
  {
    country: 'VIETNAM',
    href: 'https://customs.gov.vn/default.aspx'
  },
  {
    country: 'SINGAPORE',
    href: 'https://www.customs.gov.sg'
  },
  {
    country: 'USA',
    href: 'https://www.cbp.gov'
  },
  {
    country: 'GERMANY',
    href: 'https://www.zoll.de/EN/Home/home_node.html'
  },
  {
    country: 'JAPAN',
    href: 'http://www.customs.go.jp/english/index.htm'
  },
  {
    country: 'KOREA',
    href: 'https://www.customs.go.kr/kcshome/site/index.do?layoutSiteId=english'
  },
  {
    country: 'AUSTRALIA',
    href: 'https://www.australia.gov.au/information-and-services/passports-and-travel/customs-and-quarantine'
  },
  {
    country: 'UNITED KINGDOM',
    href: 'https://www.gov.uk/government/organisations/hm-revenue-customs'
  },
  {
    country: 'FRANCE',
    href: 'http://www.douane.gouv.fr/articles/a13001-french-customs-for-business'
  },
  {
    country: 'CHINA',
    href: 'http://english.customs.gov.cn'
  },
  {
    country: 'THAILAND',
    href: 'http://www.customs.go.th/index.php?lang=en'
  },
  {
    country: 'CANADA',
    href: 'https://www.cbsa-asfc.gc.ca/menu-eng.html'
  },
  {
    country: 'TAIWAN',
    href: 'https://eweb.customs.gov.tw'
  },
  {
    country: 'MALAYSIA',
    href: 'http://www.customs.gov.my/en'
  },
  {
    country: '  SPAIN',
    href: 'https://www.agenciatributaria.es/AEAT.internet/en_gb/Inicio/La_Agencia_Tributaria/Aduanas_e_Impuestos_Especiales/_Presentacion/La_Aduana_espanola/La_Aduana_espanola.shtml'
  },
  {
    country: 'INDONESIA',
    href: 'http://www.beacukai.go.id/websitenewV2/index.html'
  },
  {
    country: 'ITALY',
    href: 'https://www.adm.gov.it/portale/en/web/guest/home-english'
  },
  {
    country: 'RUSSIA',
    href: 'http://eng.customs.ru'
  },
  {
    country: 'HONG KONG',
    href: 'https://www.customs.gov.hk/en/home/index.html'
  },
  {
    country: 'NETHERLANDS',
    href: 'https://www.belastingdienst.nl/wps/wcm/connect/en/customs/customs'
  },
  {
    country: 'MYANMAR',
    href: 'http://www.myanmarcustoms.gov.mm'
  },
  {
    country: 'INDIA',
    href: 'https://www.fca.gov.ae/en/uae-customs/pages/customsinuae.aspx'
  },
  {
    country: 'CAMBODIA',
    href: 'http://www.cbic.gov.in/htdocs-cbec/customs'
  },
  {
    country: 'UNITED ARAB EMIRATES',
    href: 'http://www.customs.gov.kh/en_gb'
  },
];

const CustomsItem = ({link}) => {
  return (
    <a href={link.href}
       className="list-group-item"
       target="_blank"
       rel="noopener noreferrer">{link.country}
    </a>
  );
}

const CustomList = () => {
  return (
    <div className="row text-center">
      <div className="col-xs-12 col-md-6 list-group">
        {
          customsLinks.slice(0, 12).map((link, index) => {
            return (
              <CustomsItem key={index} link={link}/>
            );
          })
        }
      </div>
      <div className="col-xs-12 col-md-6 list-group">
        {
          customsLinks.slice(12).map((link, index) => {
            return (
              <CustomsItem key={index} link={link}/>
            );
          })
        }
      </div>
    </div>
  );
}

export default CustomList;
