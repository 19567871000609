import React from 'react';
import { useTranslation } from 'react-i18next';

const Rewards = () => {
  const { t } = useTranslation();

  return (
    <section className="section noselect">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="titleSection">
              <h2>{t('rewards.heading')}</h2>
              <div className="coloredLine" />
            </div>
          </div>
        </div>
        <div className="row">
          <img src="/images/rewards.png" className="img-responsive" alt="XtayPro Rewards"/>
        </div>
        <div className="row">
          <div className="col-md-8 col-md-offset-2 col-sm-10 col-sm-offset-1 col-xs-12">
            <div className="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
              <div className="panel panel-default">
                <div className="panel-body">
                  <ul>
                    {t('rewards.requirements', { returnObjects: true })
                      .map((requirement, index) => <li key={index}>{requirement}</li>)}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Rewards;
