import React from 'react';
import './Green.css';

const Green = () => {
  return (
    <section className="green-page text-center">
      <div className="container custom-container">
        <h2 className="text-center green-heading"><span style={{color: 'black'}}>What is <br className="hidden-lg"/></span><strong>XTAYPRO?</strong></h2>
        <div className="row">
          <p className="col-xs-7 col-sm-6 text-center">There are people <br className="visible-lg visible-md"/>who need <br className="visible-lg visible-md"/>products from overseas.</p>
          <p className="col-xs-5 col-sm-5 text-center" style={{borderLeft: '2px solid black'}}>There are people <br className="visible-lg visible-md"/>who fly<br className="visible-lg visible-md visible-sm"/> everyday.</p>
        </div>
        <div style={{marginTop: "30px"}}>
          <strong>We connect them, and make sure nothing goes wrong.</strong>
        </div>
        <img style={{marginTop: "50px"}} className="img-responsive" src="/images/green/xtaypro.png" alt="" />
      </div>

      <div className="container custom-container">
        <h2 className="text-center green-heading"><span style={{color: 'black'}}>What is <br className="hidden-lg"/></span><strong>GREEN?</strong></h2>
        <p className="text-left">Apart from our business, we are also building a community for people who are interested in a green, sustainable and environment-friendly lifestyle with a hope that together with YOU, we can contribute more towards saving the earth and building a sustainable future.</p>
        <img style={{marginTop: "50px"}} className="img-responsive" src="/images/green/earth.png" alt="" />
      </div>

      <div className="container custom-container">
        <h2 className="text-center green-heading"><span style={{color: 'black'}}>The <br className="hidden-lg"/></span><strong>RICE STRAWS</strong></h2>
        <p className="text-left">In case that you need a drinking straw, but are concerned about <strong style={{fontSize: '30px'}}>PLASTIC WASTE</strong>. Go ahead and try these. They are:</p>
        <ul style={{textAlign: 'left'}}>
          <li>Made from pure rice.</li>
          <li>Eco-friendly.</li>
          <li>100% biodegradable.</li>
          <li>Proudly made in Vietnam.</li>
        </ul>
        <img className="img-responsive" src="/images/green/straws.png" alt="" style={{width: '400px', marginTop: '50px'}} />
        <p className="text-left" style={{marginTop: '100px'}}>We don’t produce those straws, we’re just sharing cool alternatives that will have positive impact on the environment. If you’re reading this, chances are you have received a free pack from us, as thanks for some <strong>great things</strong> you’ve done.</p>
        <p className="text-left" style={{marginTop: '40px', marginBottom: '100px'}}>If you like them and want to have more, simply make a request on our app <a href="/app"><strong style={{color: '#1d6354'}}>XtayPro</strong></a>. Travelers heading your way will be happy to bring some for you, in exchange for a cup of coffee and a new great friend. This is how we’ve built our community. <strong>Thank you</strong> and happy “xtaying”!</p>
      </div>
    </section>
  );
}

export default Green;
