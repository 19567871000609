import React, {useEffect} from 'react';

import IntroSection from '../Home/components/IntroSection';

const DownloadApp = () => {
  useEffect(() => {
    setTimeout(function() {
      getMobileOperatingSystem();
    }, 1000);

    function getMobileOperatingSystem() {
      var userAgent = navigator.userAgent || navigator.vendor || window.opera;

      if (/android/i.test(userAgent)) {
        window.location.href = 'https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=com.xtaypro.android';
      }

      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        window.location.href = 'https://itunes.apple.com/app/xtaypro/id1255462896';
      }
    }
  }, []);

  return <IntroSection />
}

export default DownloadApp;
