import React from 'react';
import { useTranslation } from 'react-i18next';

import AIOCarousel from '../../../components/AIOCarousel/AIOCarousel';

const CarouselSection = ({ aioCarouselProps }) => {
  const { t } = useTranslation();

  return (
    <section id="screenshots" className="text-center" style={{backgroundImage: 'url(/images/bg.jpg)'}}>
      <div className="container">
        <div className="row">
          <div className="col-xs-12">
            <img alt="XtayPro" src="/images/big_logo_xtaypro_20170613_0040b.png" className="img-responsive"/>
            <h2>
              {t('home.overview')}
            </h2>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="row">
          <div className="col-xs-12">
            <div className="slider-3d">
              <AIOCarousel id='allinone_carousel_sweet'
                           {...aioCarouselProps} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default CarouselSection;
