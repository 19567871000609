import React from 'react';
import { useTranslation } from 'react-i18next';

const Tutorials = () => {
  const { t } = useTranslation();

  return (
    <section className="section">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="titleSection">
              <h2>{t('tutorials.heading')}</h2>
              <div className="coloredLine"></div>
            </div>
          </div>
        </div>
        <div className="row text-center">
          <div className="col-sm-offset-1 col-sm-10 col-xs-12">
            <h3>{t('tutorials.homeTut')}</h3>
            <div className="embed-responsive embed-responsive-16by9">
              <iframe
                className="embed-responsive-item"
                width="560"
                height="315"
                src="https://www.youtube.com/embed/AAmA0GN3yis"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="home-tutorial"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Tutorials;
