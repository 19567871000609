import React from 'react';
import { PanelGroup, Panel } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import vi from '../faqsData/vi';
import en from '../faqsData/en';
import de from '../faqsData/de';
import kr from '../faqsData/kr';

const FaqSection = () => {
  const { t, i18n } = useTranslation();
  const faqsData = { vi, en, de, kr };

  return (
    <section id="faq" className="section">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="titleSection">
              <h2>{t('home.faqsTitle')}</h2>
              <h3>{t('home.faqsPropose')}</h3>
              <div className="coloredLine"></div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-10 col-sm-offset-1 col-xs-12">
            <PanelGroup accordion id="accordion" defaultActiveKey="1">
              {
                faqsData[i18n.language].map((faq, index) => {
                  return (
                    <Panel key={index} eventKey={index + 1}>
                      <Panel.Heading>
                        <Panel.Title toggle>
                          {faq.question}
                        </Panel.Title>
                      </Panel.Heading>
                      <Panel.Body collapsible>
                        {faq.answer}
                      </Panel.Body>
                    </Panel>
                  );
                })
              }
            </PanelGroup>

            <div className="view-more-faq">
              <a href="/faq">{t('home.faqSeeMore')}</a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FaqSection;
