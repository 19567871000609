import React from 'react';
import { useTranslation } from 'react-i18next';
import { FacebookProvider, Page } from 'react-facebook';

const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer>
      <div className="container">
        <div className="row footer-information">
          <div className="col-sm-7 col-xs-12">
            <h3>XTAYPRO</h3>
            <p>{t('footer.companyName')}</p>
            <p><i className="fa fa-home fa-lg" aria-hidden="true"></i> <span>{t('footer.address')}</span></p>
            <p><i className="fa fa-phone fa-lg" aria-hidden="true"></i> <span>+84 2877 797979</span></p>
            <p><i className="fa fa-mobile fa-lg" aria-hidden="true"></i> <span>+84 96 29 08254</span></p>
            <p><i className="fa fa-envelope fa-lg" aria-hidden="true"></i> <span>info@xtaypro.com</span></p>
          </div>
          <div className="col-sm-5 col-xs-12">
            <p><i className="fa fa-facebook-square fa-lg" aria-hidden="true"></i> <span>{t('footer.facebookInvitation')}</span></p>
            <FacebookProvider appId="123456789">
              <Page href="https://www.facebook.com/xtayprocom" />
            </FacebookProvider>
          </div>
        </div>

        <div className="row footer-bct">
          <div className="col-xs-12 text-center">
            <p>
              MST: 0314106254 do Sở KH&amp;ĐT TPHCM cấp ngày 10/11/2016. <a href="/privacy">{t('footer.policy')}</a> <a href="/terms">{t('footer.terms')}</a> <a href="/licence_acknowledgement">{t('footer.licence')}</a>
              <br/>
              <a href="http://online.gov.vn/HomePage/WebsiteDisplay.aspx?DocId=47766" rel="nofollow noopener noreferrer">
                <img style={{marginTop: '5px'}} alt="registered link" height="50" src="/images/dadangky.png"/>
              </a>
            </p>
          </div>
        </div>
      </div>

      <div className="container-fluid copyright">
        <span>&copy; XtayPro {new Date().getFullYear()}</span>
      </div>
    </footer>
  );
}

export default Footer;
