import React from 'react';
import { useTranslation } from 'react-i18next';

import PrivacyEn from './PrivacyEn';
import PrivacyDe from './PrivacyDe';

const Privacy = () => {
  const { i18n } = useTranslation();

  return (
    <>
      {i18n.language === 'vi' && <PrivacyEn />}
      {i18n.language === 'en' && <PrivacyEn />}
      {i18n.language === 'kr' && <PrivacyEn />}
      {i18n.language === 'de' && <PrivacyDe />}
    </>
  );
}

export default Privacy;
