import React from 'react';

const PrivacyDe = () => {
  return (
    <>
      <section id="privacy" className="section noselect" >
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="titleSection">
                <h2>DATENSCHUTZERKLÄRUNG</h2>
                <div className="coloredLine"></div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-8 col-md-offset-2 col-sm-10 col-sm-offset-1 col-xs-12">
              <div className="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                <div className="panel panel-default">
                  <div className="panel-heading">
                    <h4 className="panel-title">Einführung</h4>
                  </div>
                  <div className="panel-body">
                    <p>Wenn du XtayPro nutzt, vertraust du uns deine Daten an. Wir wollen, dass dieses Vertrauen gerechtfertigt ist. Daher möchten wir dir zunächst helfen, unsere Datenschutzpraktiken zu verstehen.</p>
                    <p>Diese Richtlinie beschreibt, welche Informationen wir erfassen, wie wir diese nutzen und weitergeben und welche Möglichkeiten du hast, im Hinblick auf diese Informationen bestimmte Optionen auszuwählen.</p>
                    <p><span className="bold">Zuletzt geändert:</span> 07. April 2019</p>
                    <p><span className="bold">Datum des Inkrafttretens:</span> 07. April 2019</p>
                  </div>
                </div>
                <div className="panel panel-default">
                  <div className="panel-heading">
                    <h4 className="panel-title">Datenerfassung und Datennutzung</h4>
                  </div>
                  <div className="panel-body">
                    <p className="bold">Geltungsbereich</p>
                    <p>
                      In dieser Richtlinie wird beschrieben, wie XtayPro und unsere Parnterunternehmen personenbezogene Daten zur Bereitstellung unserer Dienste sammeln und nutzen. Diese Richtlinie gilt für alle Nutzer unserer Apps, Websites, Funktionen oder anderen Dienste weltweit, sofern keine gesonderten Datenschutzrichtlinien gelten. Diese Richtlinie gilt speziell für:
                    </p>
                    <ul>
                      <li><span className="bold">Xtayee:</span> Nutzer, die eine Beförderungsleistungen anfordern oder erhalten</li>
                      <li><span className="bold">Xtayer:</span>Nutzer, die selbstständig oder über Partner-Transportsunternehmen Beförderungsleistungen anbieten</li>
                      <li><span className="bold">Lieferempfänger:</span> Nutzer, die eine Lieferung von Produkten oder anderen Artikeln anfordern</li>
                    </ul>
                    <p>
                      Diese Richtlinie gilt auch für Nutzer, die XtayPro Informationen in Verbindung mit einer Anwendung zur Nutzung unserer Dienstleistungen bereitstellen oder deren Informationen XtayPro anderweitig in Verbindung mit seinen Dienstleistungen erhält. Alle unter diese Richtlinie fallenden Personen werden zum Zwecke dieser Richtlinie als „Nutzer“ bezeichnet.
                    </p>
                    <p>
                      Die in dieser Richtlinie beschriebenen Praktiken unterliegen den geltenden Gesetzen in den Märkten, in denen wir tätig sind. Das bedeutet, dass die in dieser Richtlinie beschriebenen Praktiken in einem bestimmten Land oder einer bestimmten Region nur dann zur Anwendung kommen, wenn sie nach den dort geltenden Gesetzen zulässig sind. Bitte kontaktiere uns, falls du Fragen zu unseren Praktiken in deinem Land bzw. deiner Region hast.
                    </p>
                  </div>
                  <div className="panel-body">
                    <p className="bold">Datenverantwortliche</p>
                    <p>XtayPro bietet seine Dienstleistungen Nutzern in aller Welt an. Wenn du unsere Dienste nutzt, ist der Datenverantwortliche:</p>
                    <p>XtayPro Joint Stock Company</p>
                    <p>77 Pham Viet Chanh</p>
                    <p>Distrikt 1, Ho Chi Minh Stadt</p>
                    <p>Vietnam</p>
                    <p>Wir verarbeiten personenbezogene Daten sowohl innerhalb als auch außerhalb der USA. Daten von Nutzern außerhalb der USA werden von XtayPro mithilfe von nach geltendem Recht zulässigen Verfahren übermittelt.</p>
                  </div>
                </div>
                <div className="panel panel-default">
                  <div className="panel-heading">
                    <h4 className="panel-title">Informationen, die wir erfassen</h4>
                  </div>
                  <div className="panel-body">
                    <p>Die folgenden Informationen werden von XtayPro oder in dessen Namen erfasst:</p>
                    <p className="bold">1. Von dir bereitgestellte Informationen</p>
                    <p>Dazu gehört möglicherweise:</p>
                    <ul>
                      <li>
                        <span className="bold">Nutzerprofil:</span> Wir erfassen Informationen, wenn du dein XtayPro Konto erstellst oder aktualisierst. Dazu gehören u. a. dein Name, deine E-Mail-Adresse, deine Telefonnummer, dein Benutzername und Passwort, deine Adresse, deine Zahlungs- oder Bankinformationen (einschließlich zugehöriger Zahlungsverifizierungsdaten), behördliche Ausweisnummern wie z. B. die deines Sozialversicherungsausweises, dein Pass (sofern vom Gesetz verlangt), dein Geburtsdatum, dein Foto und deine Unterschrift. Des Weiteren betrifft dies die für dein XtayPro Konto festgelegten Präferenzen und Einstellungen.
                      </li>
                      <li>
                        <span className="bold">Daten der Zuverlässigkeitsüberprüfung:</span> Wenn du dich als Xtayer oder Lieferpartner zur Nutzung der XtayPro Dienste registrierst, können wir Daten zur Zuverlässigkeitsüberprüfung über dich sammeln. Dazu können Daten wie z. B. ein Auszug aus dem Handelsregister oder ein Führungszeugnis (wo gesetzlich zulässig) gehören. Diese Informationen können von einem Dritten im Namen von XtayPro erfasst werden.
                      </li>
                      <li>
                        <span className="bold">Demografische Daten:</span> Wir können demografische Daten über dich erfassen, unter anderem durch Nutzerbefragungen. In einigen Ländern können wir außerdem demografische Daten über dich von Dritten erhalten.
                      </li>
                      <li>
                        <span className="bold">Nutzerinhalte:</span> Wir können Informationen erfassen, die du übermittelst, wenn du den XtayPro Kunden-Support kontaktierst, Bewertungen oder Komplimente für andere Nutzer abgibst oder XtayPro auf andere Weise kontaktierst.
                      </li>
                    </ul>
                  </div>


                  <div className="panel-body">
                    <p className="bold">2. Informationen, die bei deiner Nutzung unserer Dienste erstellt werden</p>
                    <p>When you use our Services, we collect information about you in the following general categories:</p>
                    <ul>
                      <li>
                        <p className="bold">Standortinformationen</p>
                        <p>
                          Abhängig von den von dir genutzten Serviceleistungen von XtayPro und den Einstellungen deiner App oder Zugriffsberechtigungen deines Gerätes kann XtayPro deine genauen oder ungefähren Standortinformationen, die aus Daten wie etwa GPS, IP-Adresse und WLAN ermittelt wurden, erfassen.
                        </p>
                        <ul>
                          <li>Wenn du ein Xtayer oder Lieferpartner bist, erfasst XtayPro Standortinformationen, wenn die XtayPro App auf deinem Gerät im Vordergrund (App geöffnet und auf dem Bildschirm) oder im Hintergrund (App geöffnet, aber nicht auf dem Bildschirm) läuft.</li>
                          <li>Wenn du ein Xtayee bist und der Verarbeitung deiner Standortdaten zugestimmt hast, erfasst XtayPro Standortinformationen, wenn die XtayPro App im Vordergrund läuft. In bestimmten Regionen darf XtayPro diese Informationen auch erfassen, wenn die XtayPro App auf deinem Gerät im Hintergrund läuft, sofern diese Erfassung durch deine App-Einstellungen oder die Zugriffsberechtigungen deines Gerätes zugelassen wird.</li>
                          <li>Xtayee und Lieferempfänger dürfen die XtayPro App nutzen, ohne XtayPro zu ermächtigen, ihre Standortinformationen zu erfassen. Dies kann allerdings die Funktionen deiner XtayPro App einschränken. Zum Beispiel musst du deine Abholadresse manuell eingeben, wenn du XtayPro nicht gestattest, deine Standortdaten zu erfassen. Zusätzlich werden während deiner Reise die Standortinformationen des Partner-Xtayers erfasst und mit deinem Konto verknüpft, auch wenn du XtayPro die Erfassung deiner Standortinformationen nicht erlaubt hast.</li>
                        </ul>
                      </li>
                      <li>
                        <p className="bold">Transaktionsinformationen</p>
                        <p>Wir erfassen Transaktionsdetails, die in Bezug zu deiner Nutzung unserer Serviceleistungen stehen. Dies umfasst die von dir angeforderten bzw. angebotenen Serviceleistungen, das Datum und die Uhrzeit der ausgeführten Serviceleistung, den fälligen Betrag, die zurückgelegte Strecke und andere damit in Verbindung stehende Transaktionsdetails. Sollte jemand deinen Aktionscode nutzen, dürfen wir zusätzlich deinen Namen mit dieser Person verknüpfen.</p>
                      </li>
                      <li>
                        <p className="bold">Nutzungsdaten</p>
                        <p>Wir erfassen Informationen über deine Interaktion mit unseren Diensten. Dazu gehören Informationen wie z. B. Zugangsdaten und -uhrzeiten, genutzte App-Funktionen oder besuchte Seiten, App-Ausfälle und andere Systemaktivitäten, der Browsertyp sowie Websites oder Dienstleistungen Dritter, die du vor deiner Interaktion mit unseren Diensten genutzt hast. In einigen Fällen sammeln wir diese Daten mithilfe von Cookies, Pixel Tags und ähnlichen Technologien, die eindeutige Identifikatoren erzeugen und beibehalten. Wenn du mehr über diese Technologien erfahren möchtest, sieh dir bitte unsere <a href="#cookie">Cookie Statement</a> an.</p>
                      </li>
                      <li>
                        <p className="bold">Geräteinformationen</p>
                        <p>Wir können Informationen zu den Geräten sammeln, über die du auf unsere Dienste zugreifst, einschließlich der Hardware-Modelle, Geräte-IP-Adressen, Betriebssysteme und -versionen, Software, Dateinamen und -versionen, Spracheinstellungen, eindeutigen Gerätekennungen, Advertising-Identifier, Seriennummern, Bewegungsdaten der Geräte und Mobilfunknetzinformationen.</p>
                      </li>
                      <li>
                        <p className="bold">Kommunikationsdaten</p>
                        <p>Wir ermöglichen es den Nutzern, über die Apps, Websites und andere Dienste von XtayPro untereinander und mit XtayPro zu kommunizieren. Zum Beispiel geben wir Partner-Xtayers und Xtayees sowie Lieferpartnern und -empfängern die Möglichkeit, einander anzurufen oder Nachrichten zu senden (in einigen Ländern ohne Offenlegung der jeweiligen Telefonnummern). Um diesen Service anbieten zu können, erhält XtayPro bestimmte Daten zu den Anrufen oder Textnachrichten, einschließlich Datum und Uhrzeit des Anrufs/der Nachricht und der Kommunikationsinhalte. XtayPro darf zudem diese Informationen für Kundendienst-Serviceleistungen (auch zur Lösung von Streitigkeiten zwischen Nutzern), zum Zwecke der Sicherheit, zur Verbesserung unserer Produkte und für Analysen nutzen.</p>
                      </li>
                    </ul>
                  </div>

                  <div className="panel-body">
                    <p className="bold">3. Informationen aus anderen Quellen</p>
                    <p>Dazu gehören möglicherweise:</p>
                    <ul>
                      <li>Nutzer-Feedback wie Bewertungen oder Komplimente.</li>
                      <li>Nutzer, die deine Informationen in Verbindung mit Empfehlungsprogrammen bereitstellen.</li>
                      <li>Nutzer, die Dienstleistungen für dich oder in deinem Namen bestellen.</li>
                      <li>Nutzer oder andere Dritte, die Informationen in Verbindung mit Forderungen oder Streitigkeiten bereitstellen.</li>
                      <li>Geschäftspartner von XtayPro, über die du dein XtayPro Konto erstellst oder darauf zugreifst. Dazu gehören unter anderem Zahlungsdienstleister, soziale Netzwerkdienste, On-demand-Musikdienste sowie Apps oder Websites, welche die APIs von XtayPro nutzen oder deren API XtayPro nutzt (z. B. wenn du eine Reise über <a href="https://help.uber.com/h/89075990-c368-4574-90f2-700f40358873">Google Maps</a> bestellst).</li>
                      <li>Versicherungsanbieter (wenn du ein Xtayer oder Lieferpartner bist).</li>
                      <li>Finanzdienstleister (wenn du ein Xtayer oder Lieferpartner bist).</li>
                      <li>Partner-Beförderungsunternehmen (wenn du ein Xtayer bist, der unsere Dienste über ein mit einem solchen Unternehmen verbundenes Konto nutzt).</li>
                      <li>Öffentlich verfügbare Quellen.</li>
                      <li>Vertriebsdienstleister.</li>
                    </ul>
                    <p>XtayPro kann die aus diesen Quellen gesammelten Informationen mit anderen Informationen in seinem Besitz kombinieren.</p>
                  </div>
                </div>

                <div className="panel panel-default">
                  <div className="panel-heading">
                    <h4 className="panel-title">So verwenden wir deine Informationen</h4>
                  </div>
                  <div className="panel-body">
                    <p>XtayPro verwendet die erfassten Informationen unter anderem zu folgenden Zwecken:</p>
                    <p className="bold">1. Bereitstellung von Dienstleistungen und Funktionen</p>
                    <p>XtayPro verwendet die erfassten Informationen, um unsere Produkte und Dienstleistungen bereitzustellen, zu personalisieren, zu erhalten und zu verbessern. Dies beinhaltet die Nutzung der Informationen:</p>
                    <ul>
                      <li>Um dein Konto zu erstellen und zu aktualisieren.</li>
                      <li>Um deine Identität zu bestätigen.</li>
                      <li>Um Beförderungs-, Liefer- und andere Dienstleistungen bereitzustellen. Dies beinhaltet die automatisierte Verarbeitung deiner Informationen für die <a href="https://help.uber.com/h/34212e8b-d69a-4d8a-a923-095d3075b487">dynamische Preisgestaltung</a>. Dabei wird der Preis einer Reise anhand sich stets verändernder Faktoren bestimmt, darunter die geschätzte Dauer und Entfernung der vorhergesagten Route, das geschätzte Nachfrage und die Anzahl der Xtayee und Xtayer, welche die XtayPro App zu einem bestimmten Zeitpunkt nutzen.</li>
                      <li>Um Zahlungen für diese Dienstleistungen zu verarbeiten oder zu erleichtern.</li>
                      <li>Um Versicherungs- oder Finanzierungslösungen in Verbindung mit unseren Dienstleistungen anzubieten, abzuschließen, bereitzustellen oder zu fördern.</li>
                      <li>Um den Fortschritt deiner Reise oder Lieferung zu verfolgen.</li>
                      <li>Um Funktionen zu ermöglichen, mit deren Hilfe du Informationen mit Dritten teilen kannst, zum Beispiel wenn du einem Xtayer ein Kompliment zukommen lässt, einen Freund als Partner-Xtayer empfiehlst, Fahrpreise teilst oder deine voraussichtliche Ankunftszeit weitergibst.</li>
                      <li>Um Funktionen zu ermöglichen, mit denen du dein XtayPro Konto personalisieren kannst. Dazu gehören das Erstellen von Lesezeichen für deine Lieblingsorte oder der Schnellzugriff auf vorherige Ziele.</li>
                      <li>Um Barrierefreiheitsfunktionen zu ermöglichen, mit denen Nutzer mit Einschränkungen unsere Dienstleistungen leichter verwenden können. Diese Funktionen ermöglichen beispielsweise, dass gehörlose oder schwerhörige Xtayer ihre Xtayee über ihre Einschränkungen informieren und mit ihnen über Textnachrichten kommunizieren können und mit Licht- statt Tonsignalen über eingehende Bestellungen informiert werden.</li>
                      <li>Um interne Vorgänge durchzuführen, die für die Bereitstellung unserer Dienste erforderlich sind. Dies umfasst das Melden von Software-Bugs und Betriebsfehlern, das Durchführen von Datenanalysen, Tests und Forschungen sowie die Überwachung und Analyse von Nutzungs- und Aktivitätstrends.</li>
                    </ul>
                  </div>

                  <div className="panel-body">
                    <p className="bold">2. Sicherheit</p>
                    <p>Wir nutzen deine Daten, um die Sicherheit und Integrität unserer Dienstleistungen und Nutzer zu gewährleisten. Dies umfasst zum Beispiel:</p>
                    <ul>
                      <li>Die Überprüfung von Xtayers, Xtayees und Lieferpartnern, bevor sie unsere Dienste zum ersten Mal nutzen sowie in regelmäßigen Abständen danach. Dies erfolgt zum Beispiel, soweit gesetzlich zulässig, in Form von Zuverlässigkeitsüberprüfungen, die gewährleisten, dass unsere Dienstleistungen nicht von Xtayers genutzt werden, die unsere Sicherheitsregeln nicht einhalten.</li>
                      <li>Unsere Identitätsprüfung in Echtzeit, bei der die Xtayer dazu aufgefordert werden, bevor sie online gehen ein Selfie von sich hochzuladen. Damit können wir sicherstellen, dass der Xtayer mit dem bei uns hinterlegten Profil übereinstimmt, und so Betrug verhindern und zum Schutz anderer Nutzer beitragen.</li>
                      <li>Die Verwendung von Geräte-, Standort-, Profil-, Nutzungs- und anderen Informationen, um Betrug oder unsichere Aktivitäten zu verhindern, aufzuspüren und zu bekämpfen. Dies beinhaltet in einigen Ländern auch die Verarbeitung solcher Daten, um Abläufe oder Muster zu erkennen, die auf Betrug oder Sicherheitsrisiken hindeuten. Dabei können auch Daten von Dritten einbezogen werden. Unter bestimmten Umständen können derartige Fälle über einen automatisierten Entscheidungsfindungsprozess zur Deaktivierung des Kontos führen. Nutzer innerhalb der EU haben das Recht, dieser Art von Datenverarbeitung zu widersprechen. Für weitere Informationen siehe Abschnitt II.I.1.d.</li>
                      <li>Die Verwendung von Nutzerbewertungen, um die Verbesserung von betroffenen Nutzern zu fördern und um Nutzer zu deaktivieren, deren Bewertungen unter einen bestimmten Mindestwert fallen, der in ihrer Region gilt. Die Berechnung und Deaktivierung kann über einen automatisierten Entscheidungsfindungsprozess erfolgen. Nutzer innerhalb der EU haben das Recht, dieser Art von Datenverarbeitung zu widersprechen. Für weitere Informationen siehe Abschnitt II.I.1.d.</li>
                    </ul>
                  </div>

                  <div className="panel-body">
                    <p className="bold">3. Kunden-Support</p>
                    <p>XtayPro verwendet die erfassten Informationen (darunter auch Aufzeichnungen von Telefonaten mit dem Kunden-Support, nachdem du darüber informiert wurdest und dein Einverständnis gegeben hast), um dich zu unterstützen, wenn du unseren Kunden-Support kontaktierst. Dies umfasst:</p>
                    <ul>
                      <li>Die Weiterleitung deiner Fragen an einen geeigneten Mitarbeiter des Kunden-Supports</li>
                      <li>Die Untersuchung und das Aufgreifen deiner Bedenken</li>
                      <li>Die Überwachung und Verbesserung der Antworten unseres Kunden-Supports</li>
                    </ul>
                  </div>

                  <div className="panel-body">
                    <p className="bold">4. Forschung und Entwicklung</p>
                    <p>Wir können die von uns erfassten Informationen zu Test-, Forschungs-, Analyse- und Produktentwicklungszwecken verwenden. Dies ermöglicht uns, die Sicherheit unserer Dienste zu verbessern und zu erweitern, neue Funktionen und Produkte zu entwickeln und Versicherungs- und Finanzierungslösungen in Verbindung mit unseren Diensten anzubieten.</p>
                  </div>

                  <div className="panel-body">
                    <p className="bold">5. Kommunikation unter den Nutzern</p>
                    <p>XtayPro verwendet die gesammelten Daten, um den Nutzern die Kommunikation untereinander zu ermöglichen. Ein Xtayer kann zum Beispiel einen Xtayee anschreiben oder anrufen, um einen Abholort zu bestätigen, und ein Lieferpartner kann einen Lieferempfänger anrufen, um ihm Informationen zu der Lieferung zu übermitteln.</p>
                  </div>

                  <div className="panel-body">
                    <p className="bold">6. Kommunikation von XtayPro</p>
                    <p>XtayPro kann die erfassten Informationen nutzen, um dich über Produkte, Dienstleistungen, Aktionen, Studien, Umfragen, Neuigkeiten, Updates und Veranstaltungen zu informieren.</p>
                    <p>XtayPro kann die Informationen auch nutzen, um Wettbewerbe und Gewinnspiele zu bewerben und zu verarbeiten, um damit verbundene Auszeichnungen zu vergeben und um dir relevante Werbeanzeigen und Inhalte zu unseren Dienstleistungen und Geschäftspartnern anzuzeigen. Einige dieser Mitteilungen erhältst du auf Grundlage deines Profils als XtayPro Nutzer. Nutzer innerhalb der EU haben das Recht, dieser Art von Datenverarbeitung zu widersprechen. Für weitere Informationen siehe Abschnitt II.I.1.d.</p>
                    <p>XtayPro kann die Informationen auch nutzen, um dich über Wahlen, Abstimmungen, Volksentscheide und andere politische und strategische Prozesse zu informieren, die mit unseren Dienstleistungen im Zusammenhang stehen.</p>
                  </div>

                  <div className="panel-body">
                    <p className="bold">7. Rechtliche Verfahren und Bestimmungen</p>
                    <p>Wir können die erfassten Informationen verwenden, um Forderungen oder Rechtsstreitigkeiten zu untersuchen oder anzusprechen, die im Zusammenhang mit deiner Nutzung der Dienstleistungen von XtayPro stehen oder anderweitig durch Regulierungsbehörden, staatliche Stellen und offizielle Untersuchungen angefragt werden.</p>
                  </div>
                </div>


                <div className="panel panel-default">
                  <div className="panel-heading">
                    <h4 className="panel-title">Cookies und Drittanbieter-Technologien</h4>
                  </div>
                  <div className="panel-body">
                    <p>Cookies sind kleine Textdateien, die auf deinem Browser oder Gerät mithilfe von Websites, Apps, Online-Medien und Werbeanzeigen gespeichert werden. <span className="bold">XtayPro verwendet Cookies und ähnliche Technologien, um zum Beispiel:</span></p>
                    <ul>
                      <li>Nutzer zu authentifizieren</li>
                      <li>Nutzervorlieben und -einstellungen zu speichern</li>
                      <li>Die Beliebtheit von Inhalten zu bestimmen</li>
                      <li>Die Effektivität von Werbekampagnen zu erfassen und zu bewerten</li>
                      <li>Seitenaufrufe und Trends zu analysieren und um ein allgemeines Verständnis des Online-Verhaltens und der Interessen der Personen zu entwickeln, die mit unseren Dienstleistungen interagieren Wir können auch anderen erlauben, uns Aufrufquoten und Analysedienstleistungen zur Verfügung zu stellen, in unserem Namen Werbung im Internet zu schalten und die Performance solcher Werbungen zu verfolgen und darüber zu berichten. Diese Einheiten dürfen Cookies, Web-Beacons, SDKs und andere Technologien nutzen, um dein Gerät zu identifizieren, wenn du unsere Seite besuchst und unsere Serviceleistungen nutzt, sowie wenn du andere Websites und Serviceleistungen besuchst. Weitere Informationen zur Nutzung von Cookies und anderen in diesem Abschnitt beschriebenen Technologien, einschließlich der von dir getroffenen Entscheidungen in Bezug auf solche Technologien, findest du in unseren <a href="https://www.uber.com/legal/privacy/cookies">Cookie Statement</a>.</li>
                    </ul>
                  </div>
                </div>

                <div className="panel panel-default">
                  <div className="panel-heading">
                    <h4 className="panel-title">Weitergabe und Offenlegung von Informationen</h4>
                  </div>
                  <div className="panel-body">
                    <p>Es ist möglich, dass XtayPro die erfassten Informationen teilt:</p>
                    <p className="bold">1. Mit anderen Nutzern</p>
                    <ul>
                      <li>Wenn du Xtayee bist, können wir zum Beispiel deinen Vornamen, deine von Xtayers vergebene Durchschnittsbewertung und deine Abhol- und/oder Ankunftsorte mit Xtayers teilen. Wenn du eine Reise über Pool mit einem anderen Xtayee teilst, kann dieser deinen Namen sowie deinen Abhol- und/oder Ankunftsort erfahren.</li>
                      <li>Wenn du Xtayer oder Lieferpartner bist, können wir u. a. folgende Informationen an deine Xtayee weitergeben: deinen Namen und dein Foto, Marke, Modell, Farbe, Kennzeichen und Foto deines Fahrzeugs, deinen Standort, deine von Xtayees vergebene Durchschnittsbewertung, die Gesamtzahl deiner Reisen, wie lange du schon die XtayPro App nutzt und deine Kontaktdaten (soweit gesetzlich zulässig). Wenn du dich dazu entscheidest, ein Nutzerprofil anzulegen, können wir außerdem alle damit verbundenen Daten weitergeben, einschließlich der Informationen, die du selbst angibst, und der Komplimente, die Xtayee über dich abgegeben haben. Der Xtayee/Lieferempfänger erhält ebenfalls einen Beleg, auf dem Informationen wie eine Aufschlüsselung des abgebuchten Betrags, dein Vorname und Foto enthalten sind.</li>
                    </ul>
                  </div>

                  <div className="panel-body">
                    <div className="bold">2. Auf deinen Wunsch</div>
                    <p>Dies umfasst das Teilen deiner Informationen:</p>
                    <ul>
                      <li><span className="bold">Auf deinen Wunsch mit anderen Personen.</span> Wir können zum Beispiel auf deinen Wunsch hin deine voraussichtliche Ankunftszeit und deinen Standort mit einem Freund teilen oder deine Reiseinformationen weitergeben, wenn du einen Fahrpreis mit einem Freund teilst.</li>
                      <li><span className="bold">Mit Geschäftspartnern von XtayPro.</span> Wenn du zum Beispiel eine Dienstleistung im Zuge einer Partnerschaft oder eines Aktionsangebots eines Dritten bestellt hast, kann XtayPro deine Informationen mit diesem Dritten teilen. Dazu gehören beispielsweise auch andere Apps oder Websites, die mit unseren APIs, Fahrzeuganbietern oder Diensten interagieren, oder solche, mit deren APIs oder Diensten wir interagieren, oder Geschäftspartner, mit denen XtayPro zusammenarbeitet, um eine Aktion, einen Wettbewerb oder eine bestimmte Dienstleistung umzusetzen.</li>
                    </ul>
                  </div>

                  <div className="panel-body">
                    <div className="bold">3. Mit der allgemeinen Öffentlichkeit, wenn du Inhalte in einem öffentlichen Forum teilst</div>
                    <p>Wir hören gerne von unseren Nutzern – zum Beispiel über öffentliche Foren wie XtayPro Blogs, soziale Medien und bestimmte Funktionen in unserem Netzwerk. Wenn du mit uns über diese Kanäle kommunizierst, sind deine Nachrichten möglicherweise öffentlich sichtbar.</p>
                  </div>

                  <div className="panel-body">
                    <div className="bold">4. Mit dem Inhaber eines XtayPro Kontos, das du möglicherweise nutzt</div>
                    <p>Wenn du ein mit einem Dritten verbundenes Profil nutzt, können wir deine Reiseinformationen mit dem Inhaber dieses Profils teilen. Das geschieht zum Beispiel, wenn du:</p>
                    <ul>
                      <li>Als Xtayer ein Konto nutzt, dessen Inhaber ein Partner-Beförderungsunternehmen ist.</li>
                      <li>Als Lieferpartner stellvertretend eine Lieferung ausführst (nur in Großbritannien).</li>
                    </ul>
                  </div>

                  <div className="panel-body">
                    <div className="bold">5. Mit Dienstleistungserbringern und Geschäftspartnern von XtayPro</div>
                    <p>Wir teilen Informationen mit unseren Tochter- und Beteiligungsgesellschaften, damit diese uns bei der Bereitstellung unserer Dienstleistungen unterstützen oder Daten in unserem Namen verarbeiten können.</p>
                  </div>

                  <div className="panel-body">
                    <div className="bold">6. Mit Dienstleistungserbringern und Geschäftspartnern von XtayPro</div>
                    <p>XtayPro kann Informationen mit seinen Lieferanten, Beratern, Marketingpartnern, Forschungsunternehmen und anderen Dienstleistungserbringern oder Geschäftspartnern teilen. Dies umfasst beispielsweise:</p>
                    <ul>
                      <li>Zahlungsverarbeiter und -vermittler</li>
                      <li>Anbieter von Zuverlässigkeitsüberprüfungen (nur Xtayer und Lieferpartner)</li>
                      <li>Anbieter von Cloud-Speichern</li>
                      <li>Marketingpartner und Anbieter von Marketingplattformen</li>
                      <li>Anbieter von Datenanalytik</li>
                      <li>Forschungspartner, darunter solche, die Umfragen oder Forschungsprojekte zusammen mit XtayPro oder in dessen Namen durchführen</li>
                      <li>Lieferanten, die XtayPro bei der Verbesserung der Sicherheit seiner Apps unterstützen</li>
                      <li>Berater, Anwälte, Wirtschaftsprüfer und andere professionelle Dienstleistungserbringer</li>
                      <li>Versicherungspartner</li>
                      <li>Flughäfen</li>
                      <li>Yandex Taxi und andere lokale Anbieter</li>
                    </ul>
                  </div>

                  <div className="panel-body">
                    <div className="bold">7. Aus rechtlichen Gründen oder im Falle von Streitigkeiten</div>
                    <p>XtayPro kann deine Informationen weitergeben, wenn wir glauben, dass es aufgrund von geltendem Recht, Bestimmungen, Betriebsvereinbarungen, Gerichtsverfahren oder behördlichen Anfragen erforderlich ist oder wenn die Offenlegung aufgrund von Sicherheits- oder sonstigen Bedenken angemessen ist.</p>
                    <p>Dies umfasst das Teilen deiner Informationen mit Strafverfolgungsbehörden, Regierungsbehörden, Flughäfen (falls von den Flughafenbehörden als Betriebsbedingung auf dem Flughafengelände gefordert) oder anderen Dritten als notwendiges Mittel, um unsere Nutzungsbedingungen, Nutzervereinbarungen oder andere Richtlinien umzusetzen, um die Rechte oder das Eigentum von XtayPro oder die Rechte, die Sicherheit oder das Eigentum anderer zu schützen oder im Falle einer Forderung oder Streitigkeit in Bezug auf deine Nutzung unserer Dienstleistungen. Wenn du die Kreditkarte einer anderen Person verwendest, sind wir möglicherweise rechtlich verpflichtet, Informationen (einschließlich der Reiseinformationen) mit dem Inhaber der Kreditkarte zu teilen.</p>
                    <p>Dies umfasst auch das Teilen deiner Informationen mit Dritten in Verbindung mit oder während der Verhandlung von einer Fusion, einem Verkauf von Firmenteilen, Konsolidierungen oder Restrukturierungen, Finanzierungen oder dem Erwerb unseres gesamten Unternehmens oder Teilen davon durch ein anderes Unternehmen.</p>
                  </div>

                  <div className="panel-body">
                    <div className="bold">8. Mit deinem Einverständnis</div>
                    <p>XtayPro kann deine Informationen auf andere Weise als in dieser Richtlinie beschrieben teilen, wenn wir dich darüber informieren und dein Einverständnis dazu einholen.</p>
                  </div>
                </div>

                <div className="panel panel-default">
                  <div className="panel-heading">
                    <h4 className="panel-title">Speichern und Löschen von Informationen</h4>
                  </div>
                  <div className="panel-body">
                    <p>XtayPro benötigt Informationen aus den Nutzerprofilen, um seine Dienstleistungen erbringen zu können, und speichert diese Informationen so lange, wie du dein XtayPro Konto behältst.</p>
                    <p>XtayPro speichert bestimmte Informationen, darunter solche zu Transaktionen, Standort, Gerät und Nutzungsinformationen, mindestens sieben Jahre lang im Zusammenhang mit aufsichtsrechtlichen, steuerlichen, versicherungsrechtlichen und anderen Anforderungen in den Ländern, in denen wir tätig sind. Wenn diese Informationen nicht länger benötigt werden, um die Dienstleistungen von XtayPro erbringen zu können, den Kundendienst zu ermöglichen, das Nutzererlebnis zu verbessern oder andere betriebliche Zwecke zu erfüllen, unternimmt XtayPro die notwendigen Schritte, um den Zugriff auf bzw. die Nutzung von solchen Informationen zu anderen Zwecken als der Erfüllung von diesen Anforderungen oder zu Zwecken der Sicherheit, Absicherung oder Verhinderung von Betrug zu verhindern.</p>
                  </div>
                  <p>Nutzer können die Löschung ihres Kontos jederzeit über e-mail an <a href="mailto:info@xtaypro.com">admin@xtaypro.com</a> oder über die XtayPro Website beantragen.</p>
                  <p>Nach einer solchen Aufforderung löscht XtayPro die Daten, zu deren Speicherung wir nicht verpflichtet sind. Unter bestimmten Umständen ist es möglich, dass XtayPro dein Konto nicht löschen kann, etwa wenn dein Konto noch ausstehende Beträge aufweist oder nicht beigelegte Ansprüche oder Streitigkeiten vorliegen. Nach Klärung des Problems, das die Löschung verhindert, wird XtayPro dein Konto wie zuvor beschrieben löschen.</p>
                  <p>Ebenso kann XtayPro bestimmte Informationen behalten, wie etwa zur Betrugsprävention und zur Erweiterung der Sicherheit des Nutzers, sofern dies für unsere legitimen Geschäftsinteressen nötig ist. Wenn XtayPro zum Beispiel das Konto eines Nutzers wegen unsicheren Verhaltens oder sicherheitsrelevanter Vorfälle schließt, kann XtayPro bestimmte Daten zu diesem Konto behalten, um zu verhindern, dass der Nutzer in Zukunft ein neues XtayPro Konto eröffnet.</p>
                </div>

                <div className="panel panel-default">
                  <div className="panel-heading">
                    <h4 className="panel-title">Besondere Informationen für Nutzer in der EU</h4>
                  </div>
                  <div className="panel-body">
                    <p>Seit dem 25. Mai 2018 unterliegt die Verarbeitung personenbezogener Daten von Nutzern in der Europäischen Union der <a href="https://eur-lex.europa.eu/legal-content/EN/TXT/?uri=uriserv:OJ.L_.2016.119.01.0001.01.ENG&toc=OJ:L:2016:119:TOC">Datenschutz-Grundverordnung der EU ('GDPR')</a>. Dieser Abschnitt enthält Informationen zu den Rechten von Nutzern in der EU und den Pflichten von XtayPro gemäß dieser Verordnung.</p>
                    <p className="bold">1. Rechte von Nutzern in der EU</p>
                    <p>Als Nutzer der XtayPro App in der EU hast du hinsichtlich der Verarbeitung deiner personenbezogenen Daten seitens XtayPro die folgenden Rechte. Im Folgenden erfährst du, wie du diese Rechte geltend machen kannst. Alternativ kannst du deine Anfrage hier einsenden.</p>
                    <p>Nutzer außerhalb der EU können Erklärungen, Korrekturen, Löschungen oder Kopien ihrer Daten ebenfalls auch anfordern.</p>
                    <p className="bold">a. Erklärung und Kopien deiner Daten</p>
                    <ul>
                      <li>Du hast das Recht, eine Erklärung zu den Informationen, die XtayPro über dich besitzt, und wie XtayPro diese verwendet, anzufordern.</li>
                      <li>Du hast ebenfalls das Recht, eine Kopie der Informationen, die XtayPro über dich sammelt – entweder auf Grundlage deiner Einwilligung oder da XtayPro die Informationen benötigt, um die von dir angeforderten Dienste bereitstellen zu können –, zu erhalten.</li>
                    </ul>

                    <p className="bold">b. Berichtigung</p>
                    <p>Sollte XtayPro Daten über dich besitzen, die deiner Ansicht nach falsch sind, so bist du berechtigt, die Berichtigung dieser Daten zu verlangen. Weitere Informationen zur Berichtigung bzw. zur Anforderung einer Berichtigung deiner Daten findest du weiter unten im Abschnitt „Auskunft, Kopien und Berichtigung“.</p>

                    <p className="bold">c. Löschung</p>
                    <ul>
                      <li>Nutzer können jederzeit die Löschung ihrer Konten beantragen. Nutzer können die Löschung über die Datenschutzeinstellungen über e-mail an <a href="mailto:info@xtaypro.com">admin@xtaypro.com</a> oder über die XtayPro Website beantragen. Möglicherweise speichern wir bestimmte Informationen über dich, sofern dies gesetzlich vorgeschrieben ist sowie zur Wahrung berechtigter geschäftlicher Interessen (soweit gesetzlich zulässig).</li>
                      <li>Weitere Informationen zu den Aufbewahrungs- und Löschpraktiken von XtayPro findest du im Abschnitt „Speichern und Löschen von Informationen“.</li>
                    </ul>

                    <p className="bold">d. Widerspruch und Beschwerden</p>
                    <ul>
                      <li>Nutzer in der EU sind berechtigt, der Verarbeitung personenbezogener Daten durch XtayPro, auch zu Marketingzwecken mittels Profiling und/oder automatisierter Entscheidungsfindung, zu widersprechen. Ungeachtet des Widerspruchs kann XtayPro deine Daten in dem gemäß der DSGVO zulässigen Umfang weiterhin verarbeiten.</li>
                      <li>
                        Nutzer in der EU sind außerdem berechtigt, eine Beschwerde bezüglich des Umgangs von XtayPro mit ihren personenbezogenen Daten bei der niederländischen Datenschutzbehörde „Autoriteit Persoonsgegevens“ einzureichen. Deren Adresse lautet wie folgt:<br />
                        Autoriteit Persoonsgegevens<br />
                        Postbus 93374<br />
                        2509 AJ DEN HAAG<br />
                        (+31) - (0)70 - 888 85 00
                      </li>
                      <li>Du kannst deine Fragen, Kommentare oder Beschwerden auch an info@xtaypro.com schicken.</li>
                    </ul>
                  </div>

                  <div className="panel-body">
                    <p className="bold">2. Gründe für die Verarbeitung</p>
                    <p>Laut der DSGVO benötigen Unternehmen, die personenbezogene Daten von Nutzern in der EU verarbeiten, hierfür konkrete rechtliche Gründe. Wie nachfolgend beschrieben, verarbeitet XtayPro die Daten von Nutzern in der EU aus einem oder mehreren der in der DSGVO aufgeführten Gründe:</p>

                    <p className="bold">a. Die Verarbeitung ist notwendig, um die von dir angeforderten Dienste und Funktionen bereitzustellen.</p>
                    <ul>
                      <li>
                        <p>XtayPro muss bestimmte Informationen sammeln und verwenden, um seine Dienste bereitstellen zu können. Dies umfasst:</p>
                        <ul>
                          <li>Informationen aus dem Nutzerprofil, die benötigt werden, um dein Konto zu erstellen und zu pflegen, einschließlich der Bestätigung deiner Identität; um das Senden von Nachrichten an dich bezüglich deiner Reisen, Bestellungen und Konten zu ermöglichen; und um Zahlungen und die Auszahlung von Umsätzen zu ermöglichen.</li>
                          <li>Informationen der Zuverlässigkeitsüberprüfung, die benötigt werden, damit Xtayer Reisen über die XtayPro App anbieten können.</li>
                          <li>Standortinformationen von Xtayers, die benötigt werden, um Xtayer mit Xtayees zu verbinden, Reisen nachzuverfolgen und Navigationshilfen bereitstellen zu können.</li>
                          <li>Informationen zu Transaktionen, die in Verbindung mit deiner Nutzung der Dienste von XtayPro erstellt und aufbewahrt werden müssen.</li>
                          <li>Informationen zur Nutzung, die benötigt werden, um die Dienste von XtayPro bereitzustellen, zu optimieren und zu verbessern, darunter die Festlegung von Zusatzleistungen, manchmal in Kombination mit anderen Informationen, das Verbinden von Xtayers und Xtayees und das Berechnen der Kosten von Reisen sowie der Umsätze von Xtayers.</li>
                        </ul>
                      </li>
                      <li>Das Sammeln und Verwenden dieser Informationen ist eine Voraussetzung für die Nutzung der Apps von XtayPro.</li>
                    </ul>

                    <p className="bold">b. Die Verarbeitung ist zum Schutz wesentlicher Interessen unserer Nutzer oder Dritter erforderlich.</p>
                    <p>XtayPro darf personenbezogene Daten verarbeiten – und gegebenenfalls an Strafverfolgungsbehörden weitergeben –, wenn die Sicherheit von Nutzern oder Dritten gefährdet ist.</p>

                    <p>c. Die Verarbeitung ist zur Wahrung der berechtigten Interessen von XtayPro erforderlich.</p>
                    <ul>
                      <li>
                        <p>XtayPro erfasst und nutzt personenbezogene Daten, soweit dies zur Wahrung der berechtigten Interessen von XtayPro notwendig ist. Dies umfasst die Erfassung und Verwendung von Informationen:</p>
                        <ul>
                          <li>Um den Schutz und die Sicherheit unserer Nutzer zu wahren und zu verbessern. Zum Beispiel erfassen wir die Daten der Zuverlässigkeitsüberprüfung (soweit gesetzlich zulässig), um zu verhindern, dass nicht vertrauenswürdige Nutzer Dienstleistungen über unsere Apps anbieten. Des Weiteren nutzen wir personenbezogene Daten, um zu verhindern, dass Nutzer, die durch unangemessenes oder gefährliches Verhalten aufgefallen sind, unsere Dienste nutzen. Dazu speichern wir beispielsweise die Daten gesperrter Nutzer, um zu vermeiden, dass sie die XtayPro Apps nutzen. Darüber hinaus verwenden wir Nutzungsdaten, um zu verhindern, dass Xtayee und Xtayer mit einem erhöhten Konfliktpotenzial (z. B. aufgrund früherer Beschwerden von anderen Nutzern) zusammengebracht werden.</li>
                          <li>Um Betrug in Verbindung mit der Nutzung unserer Dienstleistungen zu verhindern, aufzudecken und zu bekämpfen. Beispielsweise verwendet XtayPro das Nutzerprofil, den Standort sowie Geräte- und Nutzungsinformationen, um Betrugsversuche an XtayPro oder anderen Nutzern zu erkennen und zu verhindern.</li>
                          <li>Um Beamte der Strafverfolgungsbehörden über Gesetzesverstöße oder Bedrohungen der öffentlichen Sicherheit zu informieren.</li>
                          <li>Um Kunden-Support bereitzustellen.</li>
                          <li>Um unsere Dienste zu verbessern und neue Dienste zu entwickeln. Dies beinhaltet beispielsweise die Ermittlung der besten Abhol-/Ankunftsorte, die Empfehlung von (neuen) Funktionen sowie die Verbesserung der Navigation, der Preisgestaltung und der Verknüpfung von Xtayees und Xtayers bzw. Lieferempfängern und -partnern.</li>
                          <li>Zu Forschungs- und Analysezwecken. Dies beinhaltet beispielsweise die Analyse von Nutzungstrends zur Verbesserung des Nutzererlebnisses und der Sicherheit unserer Dienstleistungen.</li>
                          <li>Zu Direktmarketingzwecken. Dies beinhaltet beispielsweise die Analyse von Daten zur Ermittlung von Trends und zur Abstimmung von Werbebotschaften auf die Bedürfnisse der Nutzer.</li>
                          <li>Um die Nutzungsbedingungen von XtayPro durchzusetzen.</li>
                        </ul>
                      </li>
                    </ul>

                    <p className="bold">d. Die Verarbeitung ist zur Wahrung der berechtigten Interessen von anderen Personen oder Parteien erforderlich.</p>
                    <ul>
                      <li>XtayPro sammelt und nutzt persönliche Informationen in dem Umfang, der für die Wahrung der Interessen anderer Personen oder der Allgemeinheit erforderlich ist. Dies umfasst das Weitergeben von Informationen in Zusammenhang mit Rechts- oder Versicherungsansprüchen zum Schutz der Rechte und der Sicherheit Dritter.</li>
                      <li>XtayPro kann auf Grundlage der geltenden Gesetze persönliche Informationen auch verarbeiten, sofern dies im Hinblick auf ein wichtiges öffentliches Interesse erforderlich ist.</li>
                    </ul>

                    <p className="bold">e. Die Verarbeitung ist zur Erfüllung der rechtlichen Verpflichtungen von XtayPro erforderlich.</p>
                    <ul>
                      <li>XtayPro unterliegt den gesetzlichen Bestimmungen der Staaten, in denen wir tätig sind, welche die Erfassung, Verarbeitung, Offenlegung und Speicherung deiner personenbezogenen Daten erfordern. Zum Beispiel ist XtayPro in vielen Städten und Ländern gesetzlich verpflichtet, Daten zu deinen Reisen zu erfassen und zu speichern, derartige Daten über einen längeren Zeitraum aufzubewahren und Kopien dieser Daten an staatliche oder andere Behörden weiterzugeben. XtayPro nutzt deine Informationen, um entsprechende Gesetze einzuhalten, sofern diese für deine Nutzung der XtayPro Apps gelten.</li>
                      <li>XtayPro kann Informationen auch an Strafverfolgungsbehörden oder im Hinblick auf Anfragen Dritter, die in Zusammenhang mit Rechtsprozessen stehen, weitergeben.</li>
                    </ul>

                    <p className="bold">f. Einwilligung</p>
                    <ul>
                      <li>XtayPro darf deine Daten auf Grundlage deiner Einwilligung erfassen und nutzen. Du kannst deine Einwilligung jederzeit widerrufen. Wenn du deine Einwilligung widerrufst, kannst du keine Dienste oder Funktionen nutzen, für welche die Erfassung oder Nutzung der Informationen erforderlich ist, die wir auf Grundlage deiner Einwilligung erfasst oder genutzt haben.</li>
                      <li>
                        <p>XtayPro beruft sich auf die Einwilligung in Verbindung mit der Erfassung oder Nutzung von Daten, die benötigt werden, um das Nutzererlebnis zu verbessern, optionale Dienstleistungen oder Funktionen zu ermöglichen oder mit dir zu kommunizieren. Wenn du ein Nutzer in der EU bist, werden auf Grundlage deiner Einwilligung auf die folgenden Arten von Daten erfasst und genutzt.</p>
                        <ul>
                          <li>Standortinformationen</li>
                          <li>Live-Standort teilen</li>
                          <li>Mitteilungen: Konto- und Reiseaktualisierungen</li>
                          <li>Mitteilungen: Preisnachlässe und Neuigkeiten</li>
                          <li>Barrierefreiheitsfunktionen</li>
                        </ul>
                      </li>
                      <li>Weitere Informationen zu diesen Arten der Datenerfassung und -nutzung, zur Zustimmung bzw. Ablehnung und zu den Folgen einer Ablehnung für deine Nutzung der XtayPro Apps findest du nachstehend im Abschnitt „Optionen und Transparenz“.</li>
                      <li>XtayPro darf außerdem mithilfe freiwilliger Umfragen personenbezogene Daten über dich erfassen. Deine Antworten auf solche Umfragen werden auf Grundlage deiner Einwilligung erfasst. Sie werden gelöscht, sobald sie nicht mehr für die Zwecke benötigt werden, zu denen sie gesammelt wurden.</li>
                    </ul>
                  </div>
                </div>

                <div className="panel panel-default">
                  <div className="panel-heading">
                    <h4 className="panel-title">Wahlmöglichkeiten und Transparenz</h4>
                  </div>
                  <div className="panel-body">
                    <p className="bold">A. DATENSCHUTZEINSTELLUNGEN</p>
                    <p>Im Menü „Einstellungen“ in der XtayPro App haben Nutzer die Möglichkeit, ihre Präferenzen für die Weitergabe von Kontaktdaten sowie ihre Präferenzen für den Erhalt mobiler Benachrichtigungen von XtayPro festzulegen bzw. zu ändern. Informationen zu diesen Einstellungen, zu deren Festlegung und Änderung sowie zu den Folgen einer Deaktivierung dieser Einstellungen findest du nachfolgend.</p>
                    <p className="bold">Benachrichtigungen</p>
                    <ul>
                      <li>XtayPro sendet seinen Nutzern Benachrichtigungen zum Reisestatus und aktuelle Informationen zu ihrem Konto. Diese Benachrichtigungen sind wichtiger Bestandteil der Nutzung der XtayPro App und können nicht deaktiviert werden. Du kannst jedoch im Menü „Datenschutzeinstellungen“ der XtayPro App wählen, auf welchem Weg du diese Benachrichtigungen erhalten möchtest.</li>
                      <li>Du kannst XtayPro gestatten, dir Push-Benachrichtigungen zu Preisnachlässen und Neuigkeiten von XtayPro zu senden. Du kannst diese Benachrichtigungen jederzeit über die Datenschutzeinstellungen in der XtayPro App aktivieren oder deaktivieren.</li>
                    </ul>
                  </div>

                  <div className="panel-body">
                    <p className="bold">B. ZUGRIFFSBERECHTIGUNG FÜR GERÄTE</p>
                    <p>In den meisten mobilen Betriebssystemen (iOS, Android etc.) sind bestimmte Arten von Gerätedaten festgelegt, auf die Apps ohne deine Zustimmung nicht zugreifen können. Diese Plattformen nutzen unterschiedliche Zugriffsberechtigungssysteme, um deine Zustimmung einzuholen. Unter iOS wirst du gewarnt, wenn die XtayPro App zum ersten Mal eine Zugriffsberechtigung für bestimmte Arten von Daten verlangt. Du hast dann die Möglichkeit, dieser Anfrage zuzustimmen (oder nicht). Android-Geräte informieren dich vor der ersten Nutzung der XtayPro App über die gewünschten Zugriffsberechtigungen. Deine Nutzung der App gilt als Zustimmung.</p>
                  </div>

                  <div className="panel-body">
                    <p className="bold">C. AUFRUFEN VON BEWERTUNGEN</p>
                    <p>Nach jeder Reise können Xtayer und Xtayee einander bewerten und auch ein Feedback darüber abgeben, wie die Reise verlief. Durch dieses beidseitige System müssen alle Verantwortung für ihr Verhalten übernehmen. Diese Verantwortung trägt zu einer respektvollen, sicheren Umgebung für Xtayer und Xtayee bei.</p>
                    <p>Deine Bewertung ist im Tab „Bewertungen“ der XtayPro App verfügbar.</p>
                  </div>

                  <div className="panel-body">
                    <p className="bold">D. AUSKUNFT, KOPIEN UND BERICHTIGUNG</p>
                    <p>Du kannst von XtayPro verlangen:</p>
                    <ul>
                      <li>Dir detailliert Auskunft darüber zu geben, welche Daten XtayPro über dich erfasst hat und wie diese Daten genutzt werden.</li>
                      <li>Dir eine Kopie der Daten auszuhändigen, die XtayPro über dich erfasst hat.</li>
                      <li>
                        <p>Fehlerhafte Daten, die XtayPro über dich hat, zu berichtigen.</p>
                        <p>Dies kannst du beantragen, indem du XtayPro kontaktierst.</p>
                        <p>Du kannst außerdem den Namen, die Telefonnummer und die E-Mail-Adresse, die mit deinem Konto verknüpft sind, in den Einstellungen der XtayPro Apps ändern. Darüber hinaus kannst du in den XtayPro Apps deine vergangenen Reisen, Bestellungen und Lieferungen einsehen.</p>
                      </li>
                    </ul>
                  </div>

                  <div className="panel-body">
                    <p className="bold">E. ABLEHNUNG VON WERBUNG</p>
                    <p>Du kannst den Erhalt von Werbe-E-Mails von XtayPro ablehnen, indem du e-mail an <a href="mailto:info@xtaypro.com">admin@xtaypro.com</a> schreibst. Du kannst außerdem den Erhalt von E-Mails und anderen Mitteilungen von XtayPro beenden, indem du die darin enthaltenen Anweisungen befolgst. Bitte beachte, dass wir dir nach der Ablehnung weiterhin werbefreie Mitteilungen, wie z. B. Belege zu deinen Reisen oder Informationen zu deinem Konto, senden können</p>
                  </div>
                </div>

                <div className="panel panel-default">
                  <div className="panel-heading">
                    <h4 className="panel-title">Aktualisierungen der Richtlinie</h4>
                  </div>
                  <div className="panel-body">
                    <p>Wir dürfen gelegentlich diese Richtlinie aktualisieren. Sollten wir signifikante Änderungen vornehmen, werden wir dich durch die XtayPro-App oder auf anderem Wege, wie etwa E-Mail, über die Änderungen informieren. Im durch geltendes Recht erlaubten Umfang stimmst du nach einer solchen Benachrichtigung unseren Aktualisierungen dieser Richtlinie durch die Nutzung unserer Serviceleistungen zu.</p>
                    <p>Wir empfehlen dir, regelmäßig diese Richtlinie im Hinblick auf die jüngsten Informationen zu unseren Datenschutzpraktiken zu überprüfen. Wir werden zudem frühere Versionen unserer Datenschutzrichtlinien zur Einsichtnahme verfügbar machen.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="cookie" className="section" >
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="titleSection">
                <h2>COOKIE STATEMENT (GLOBAL)</h2>
                <div className="coloredLine"></div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-8 col-md-offset-2 col-sm-10 col-sm-offset-1 col-xs-12">
              <div className="panel-group">
                <div className="panel panel-default">
                  <div className="panel-heading" role="tab" id="headingOne">
                    <h4 className="panel-title">
                      COOKIE STATEMENT
                    </h4>
                  </div>
                  <div className="panel-body">
                    Diese Seite verwendet für eine personalisierte und sichere Nutzung Cookies. Cookies ermöglichen es uns, das Nutzerverhalten zu analysieren und die Seite zu verbessern. Möglicherweise beauftragen wir externe Parteien damit, Cookies auf dieser Webseite zu platzieren,damit wir dir auf anderen Webseiten Werbung zeigen können. Mehr darüber, wie wir Cookies verwenden und wer diese externen Parteien sind, erfährst du in unserer Cookie-Datenschutzerklärung. Hier kannst du auch der Nutzung zu Werbezwecken widersprechen. Indem du diese Seite benutzt, stimmst du unserer Verwendung von Cookies zu.
                  </div>
                </div>

                <div className="panel panel-default">
                  <div className="panel-heading" role="tab" id="headingOne">
                    <h4 className="panel-title">
                      COOKIE-DATENSCHUTZERKLÄRUNG (GLOBAL)
                    </h4>
                  </div>
                  <div className="panel-body">
                    <p>Datum des Inkrafttretens 01. Mai 2018</p>
                    <p>XtayPro, unsere Partnerunternehmen, externe Parteien und andere Partner nutzen Cookies und ähnliche Identifizierungstechnologien auf unseren Webseiten, für mobile Anwendungen, E-Mail-Kommunikation, Werbung und andere Online-Dienste (zusammen „die Dienste“) für verschiedene Zwecke wie unter anderem die Authentifizierung von Nutzern, die Speicherung von Nutzerpräferenzen und -einstellungen, die Ermittlung der Beliebtheit von Inhalten, die Bereitstellung und die Messung der Wirksamkeit von Werbekampagnen, die Analyse von Webseiten-Verkehr und -Trends sowie zum Erlangen eines generellen Verständnisses von Online-Verhalten und -Interessen der Personen, die mit unseren Dienstleistungen interagieren. Hier kannst du mehr darüber erfahren, welche Art von Cookies wir verwenden, warum wir sie verwenden und wie du deine Auswahl treffen kannst.</p>
                  </div>
                </div>
                <div className="panel panel-default">
                  <div className="panel-heading" role="tab" id="headingOne">
                    <h4 className="panel-title">
                      Überblick über Cookies und verwandte Technologien
                    </h4>
                  </div>
                  <div className="panel-body">
                    Cookies sind kleine Textdateien, die von Webseiten, Apps, Online-Medien und Werbung in deinem Browser oder Gerät abgelegt und dazu verwendet werden, deinen Browser oder dein Gerät während des Besuchs von Webseiten und über den Besuch von Webseiten hinweg zu speichern. Wir verwenden darüber hinaus andere Technologien, die eventuell dich oder die Geräte, die du benutzt, identifizieren. Sogenannte „Pixel Tags“, auch bekannt als „Beacons“, sind kleine Code-Blöcke, die von einer Webseite, einer App oder einer Werbung installiert (oder aufgerufen) werden und bestimmte Informationen über dein Gerät oder deinen Browser abrufen können. Dazu zählen unter anderem Gerätetyp, Betriebssystem, Browsertyp und -version, besuchte Webseite, Zeit des Besuchs der Webseite, IP-Adresse, Werbe-Identifier und andere ähnliche Informationen einschließlich der kleinen Textdatei (dem „Cookie“), die das Gerät eindeutig identifiziert. Pixel ermöglichen es externen Dritten, Browser-Cookies von einer Domain aus zu setzen und zu lesen, die sie nicht selbst betreiben, und Daten über Besucher dieser Domain zu erfassen – für gewöhnlich mit Erlaubnis des Domaininhabers. „Lokaler Speicher“ bezieht sich im Allgemeinen auf andere Orte in einem Browser oder auf einem Gerät, wo typischerweise Informationen von Webseiten, Ads oder externen Parteien abgelegt werden können, wie zum Beispiel lokaler HTML5-Speicher und der Browser-Cache, „Software-Development-Kits“ (kurz SDKs) funktionieren wie Pixel und Cookies, operieren aber im Kontext mobiler Apps, in dem Pixel und Cookies nicht immer funktionieren. Der primäre App-Entwickler kann Code-Teile (das SDK) von Partnern in der App installieren und es dem Partner so ermöglichen, bestimmte Daten über die Benutzerinteraktion mit der App und Daten über das benutzte Gerät sowie zum Netzwerk zu sammeln.
                  </div>
                </div>
                <div className="panel panel-default">
                  <div className="panel-heading" role="tab" id="headingOne">
                    <h4 className="panel-title">
                      Werbesynchronisierung und -relevanz
                    </h4>
                  </div>
                  <div className="panel-body">
                    <p>Um möglichst relevante Anzeigen bereitzustellen, arbeitet XtayPro mit verschiedenen Dienstanbietern zusammen, die uns dabei unterstützen, für Endnutzer über alle Geräte und Plattformen ähnliche Anzeigen bereitzustellen. So arbeiten wir beispielsweise mit Social-Media-Werbediensten zusammen, um dir anhand deiner Aktivitäten mit XtayPro über ihre Medienkanäle relevante Werbung anzuzeigen. Zudem können wir Dienstanbieter verwenden, um dir auf einer mobilen Webseite oder App eine ähnliche Anzeige zu präsentieren wie auf einer klassischen Webseite. Im Folgenden findest du weitere Informationen hinsichtlich deiner Auswahlmöglichkeiten zur Beschränkung dieser Arten von Werbung.</p>
                  </div>
                </div>

                <div className="panel panel-default">
                  <div className="panel-heading" role="tab" id="headingOne">
                    <h4 className="panel-title">
                      Du entscheidest
                    </h4>
                  </div>
                  <div className="panel-body">
                    <p>Du hast das Recht, Cookies zu akzeptieren oder abzulehnen. Sie sind jedoch ein wichtiger Bestandteil der Funktionen unserer Dienste, daher solltest du dir darüber im Klaren sein, dass es die Funktionsfähigkeit und Verfügbarkeit des Dienstes beeinflussen kann, falls du Cookies ablehnst oder löschst.</p>
                    <p>Die meisten Browser sind standardmäßig so eingestellt, dass sie Cookies akzeptieren. Wenn du möchtest, kannst du aber deinen Browser normalerweise so einstellen, dass er Cookies entfernt oder ablehnt. Befolge bitte hierfür die Anweisungen deines Browsers, die du in der Regel im Menü „Hilfe“ oder „Einstellungen“ finden kannst. Manche externen Anbieter bieten auch die Möglichkeit, ihre Cookies direkt durch das Anklicken eines Opt-out-Links abzulehnen. Welche Anbieter dies ermöglichen, haben wir in der Tabelle unten aufgelistet.</p>
                    <p>Das Löschen oder Ablehnen von Browser-Cookies wirkt sich nicht zwangsläufig auf Flash-Cookies externer Anbieter aus, die wir oder unsere Partner möglicherweise in Verbindung mit unseren Diensten verwenden. Mehr Informationen zur Löschung oder Deaktivierung von Flash-Cookies findest du auf dieser Seite. Weitere Informationen zu Cookies und dazu, wie du dir anzeigen lassen kannst, welche Cookies auf deinem Gerät gesetzt worden sind, und wie du sie verwalten und löschen kannst, findest du unter https://youradchoices.com/ und für europäische Besucher unter www.youronlinechoices.eu.</p>
                    <p>Benutzer von Mobilgeräten können über die Einstellungen im Betriebssystem ihres Geräts auswählen, ob sie Cookies zulassen oder ablehnen oder ihre Werbe-ID mit Unternehmen wie XtayPro oder unseren Werbe-Dienstanbietern teilen möchten. Informationen zur Bedienung des Mobilgeräts deiner Wahl findest du unter www.networkadvertising.org/mobile-choices.</p>
                    <p>Um bestimmte Anzeigen in mobilen Anwendungen zu kontrollieren oder zu blockieren, kannst du die mobile DAA-APP https://youradchoices.com/appchoices herunterladen und verwenden.</p>
                  </div>
                </div>

                <div className="panel panel-default">
                  <div className="panel-heading" role="tab" id="headingOne">
                    <h4 className="panel-title">
                      Arten und Zielsetzungen von Cookies
                    </h4>
                  </div>
                  <div className="panel-body">
                    <p>Die folgende Tabelle legt die verschiedenen Kategorien von Cookies dar, die unsere Serviceleistungen nutzen und warum wir sie nutzen. Die Aufstellung der fremden Cookie-Anbieter ist nicht abschließend und dient lediglich illustrativen Zwecken.</p>

                    <table className="table table-striped table-bordered">
                      <thead>
                        <tr>
                          <td>Cookie-Typ</td>
                          <td>Zweck</td>
                          <td>Wer platziert (zum Beispiel)</td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Authentifikationscookies</td>
                          <td>Diese Cookies (einschließlich lokaler Speicherplatz- und vergleichbarer Technologien) benachrichtigen uns, wenn Sie angemeldet sind, damit wir Ihnen geeignete Nutzererlebnisse und Features, wie etwa Informationen zu Ihrem Konto und Ihren zurückgelegten Strecken, vermitteln und Ihnen zeigen können, wie Sie Ihre Kontoeinstellungen ändern können.</td>
                          <td>
                            <ul>
                              <li>XtayPro</li>
                            </ul>
                          </td>
                        </tr>
                        <tr>
                          <td>Sicherheits- und Website-Integritätscookies</td>
                          <td>Wir setzen diese Cookies ein, um Sicherheits-Features zu unterstützen oder zu aktivieren, die dafür sorgen sollen, dass XtayPro sicher und geschützt bleibt. Sie ermöglichen uns beispielsweise, darauf aufmerksam zu werden, wenn Sie sich in einem sicheren Bereich der Serviceleistungen angemeldet haben, undsind dabei behilflich, Ihr Konto vor dem Zugang anderer Personen als Sie selbst zu schützen.</td>
                          <td>
                            <ul>
                              <li>XtayPro</li>
                            </ul>
                          </td>
                        </tr>
                        <tr>
                          <td>Lokalisierung</td>
                          <td>Diese Cookies helfen XtayPro, ein standortspezifisches Nutzererlebnis zu bieten. Wir können zum Beispiel Informationen in einem Cookie speichern, das in Ihrem Browser oder Gerät platziert ist, damit Sie die Website in der von Ihnen bevorzugten Sprache sehen können.</td>
                          <td>
                            <ul>
                              <li>XtayPro</li>
                            </ul>
                          </td>
                        </tr>
                        <tr>
                          <td>Website-Features und Serviceleistungen</td>
                          <td>Diese Cookies weisen Funktionen auf, die uns dabei helfen, Produkte und Leistungen zu liefern. Cookies helfen Ihnen beispielsweise bei der Anmeldung durch das Vorausfüllen von Feldern. Wir können Cookies und ähnliche Technologien auch dazu nutzen, uns dabei zu unterstützen, Ihnen und anderen Personen Social Plugins und andere spezifische Inhalte und Erlebnisse zu vermitteln, indem wir etwa Ihnen und anderen Personen Vorschläge unterbreiten.</td>
                          <td>
                            <ul>
                              <li>XtayPro</li>
                              <li>Facebook</li>
                              <li>Twitter</li>
                              <li>Google</li>
                            </ul>
                          </td>
                        </tr>
                        <tr>
                          <td>Analyse und Forschung</td>
                          <td>Diese Cookies werden dazu eingesetzt, Produkte und Leistungen zu verstehen, zu verbessern und zu erforschen, etwa wenn Sie auf die XtayPro-Website und verbundene Websites und Apps von einem Computer oder mobilen Endgerät aus zugreifen. Wir können beispielsweise Cookies dazu benutzen, um zu verstehen, wie Sie Website-Features nutzen, und um Zielgruppen für das Testen von Features zu segmentieren. Wir und unsere Partner können diese Technologien und die von uns erhaltenen Informationen verwenden, um besser zu werden und die Art und Weise, auf die Sie Websites, Apps, Serviceleistungen und Werbeanzeigen nutzen, zu verstehen.</td>
                          <td>
                            <ul>
                              <li>Google</li>
                              <li>MixPanel</li>
                              <li>Optimizely</li>
                            </ul>
                          </td>
                        </tr>
                        <tr>
                          <td>Werbung</td>
                          <td>Mittel wie Cookies und Pixels werden eingesetzt, um relevante Werbeanzeigen zu platzieren und die Performance und Effektivität von Werbekampagnen zu verfolgen. Beispielsweise können wir und unsere Werbepartner uns auf Informationen verlassen, die mithilfe dieser Cookies beschafft werden, um Werbung für Sie, die Ihr Interesse finden kann, auf anderen Websites zu platzieren. In gleicher Weise können unsere Partner Cookies, Zuordnungsdienste oder andere vergleichbare Technologien nutzen, um zu ermitteln, ob wir eine Werbeanzeige platziert haben und wie effektiv sie war, oder um uns Informationen darüber zukommen zu lassen, wie Sie mit ihnen interagieren. Wenn Sie weitere Informationen zu interessenbasierten Anzeigen erhalten oder Ihre Zustimmung zur Benutzung von Informationen über Ihr Browsen im Internet für Zwecke verhaltensgesteuerter Werbung zurückziehen möchten, besuchen Sie bitte <a href="https://www.aboutads.info/choices">www.aboutads.info/choices</a>, falls Sie in den USA ansässig sind, oder <a href="https://youronlinechoices.eu/">https://youronlinechoices.eu/</a>, falls Sie außerhalb der USA ansässig sind.</td>
                          <td>
                            <ul>
                              <li>XtayPro</li>
                              <li>Google</li>
                              <li>Facebook</li>
                              <li>AOL</li>
                              <li>Microsoft</li>
                              <li>Yahoo</li>
                              <li>Drawbridge</li>
                              <li>Indeed</li>
                              <li>Recruitics</li>
                              <li>RocketFuel</li>
                              <li>Simplyhired</li>
                              <li>Twitter</li>
                              <li>Ziprecruiter</li>
                              <li>Mixpanel</li>
                              <li>HasOffers/Tune</li>
                              <li>Adjust</li>
                              <li>AdRoll</li>
                              <li>Quantcast</li>
                              <li>Kenshoo</li>
                            </ul>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="panel panel-default">
                  <div className="panel-heading" role="tab" id="headingOne">
                    <h4 className="panel-title">
                      Ansprechpartner
                    </h4>
                  </div>
                  <div className="panel-body">
                    Wenn Sie Fragen dazu haben, wie wir Cookies verwenden, schauen Sie sich bitte die Datenschutzerkärung im Abschnitt „ Cookies und Drittanbieter-Technologien\ an. Über E-Mail an <a href="mailto:info@xtaypro.com">info@XtayPro.com</a> oder unsere Webseite können Sie auch Ihre Fragen einreichen.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default PrivacyDe;
