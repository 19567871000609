import React from 'react';
import NewsList from '../../components/NewsList/NewsList';

const News = () => {
  return (
    <section className="section">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="titleSection">
              <h2>Tin Tức</h2>
              <div className="coloredLine"></div>
            </div>
          </div>
        </div>
        <div className="row">
          <NewsList />
        </div>
      </div>
    </section>
  );
}

export default News;
