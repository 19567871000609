import React from 'react';

export default {
  expandButtonText: {
    false: 'Alle Antworten anzeigen',
    true: 'Schließen Sie alle Antworten'
  },
  promise: "Wenn wir weitere Fragen bekommen, aktualisieren wir diese Tabelle, um Euch besser zu dienen!",
  faqs: [
    {
      question: 'Was bedeuten "Xtayer" und "Xtayee"?',
      answer: 'Ein "Xtayer" ist jemand, der reist und die Waren trägt. Ein "Xtayee" ist jemand, der die Lieferung von Gegenständen anfordert. Normalerweise werden die Xtayees auch die Xtayers bitten, die Gegenstände an ihrer Stelle einzukaufen.'
    },
    {
      question: 'Wie suche ich jemanden, der etwas aus einer bestimmten Route anfordert/liefert?',
      answer: <>Bitte beachten Sie unsere detaillierte Anleitung <a href="https://www.facebook.com/pg/xtayprocom/photos/?tab=album&album_id=1655155991166638" target="_blank" rel="nofollow noopener noreferrer">hier</a>.</>
    },
    {
      question: 'Ich kann niemanden finden, der meinen Suchkriterien entspricht. Was soll ich jetzt tun?',
      answer: "Sie können auf die Taste X in der unteren rechten Ecke klicken, um eine neue Anfrage/ein neues Angebot zu erstellen. Jemand wird es sehen und später Sie kontaktieren. Immer noch keine Neuigkeiten? Sind Sie sich sicher, dass Sie nicht etwas vom Nordpol verlangen?"
    },
    {
      question: 'Welche Zahlungsarten werden von XtayPro unterstützt?',
      answer: 'Wir sind momentan nicht am Bezahlvorgang beteiligt. Es liegt ganz bei Ihnen und den anderen Benutzern. Sie können Bar bezahlen oder eine Überweisung im Voraus machen. Sie können sogar nur eine Tasse Kaffee und eine Tour durch die Stadt von der anderen Person verlangen. Schließlich ist diese Plattform dafür geschaffen, Menschen miteinander zu verbinden und Kulturen zu teilen. Wir bieten jedoch einen ESCROW-Service zum Schutz Ihrer Transaktionen, der im Folgenden genauer beschrieben wird.'
    },
    {
      question: 'Ich habe jemanden gefunden, wie kann ich sie kontaktieren?',
      answer: <>Wenn Sie eine Anfrage/Angebot sehen, welches Ihren Kriterien entspricht, können Sie nach unten scrollen und auf die Schaltfläche "Verhandlung mit diesem Nutzer beginnen" klicken. Machen Sie sich bitte keine Sorgen, eine Verhandlung bleibt immer unverbindlich. Solange Sie auf die Schaltfläche ACCEPT noch nicht klicken, sind Sie zur Bezahlung <strong>nicht verpflichtet</strong>.</>
    },
    {
      question: 'Wo soll ich anderer Benutzer zur Abholung/Zustellung treffen?',
      answer: 'Die sichersten Orte, um sich zu treffen, sind öffentliche Räume wie Flughäfen, Cafés, Parks usw. Sie können die Sendung auch bei unseren Sammelzentren sammeln und abgeben.'
    },
    {
      question: 'Kann ich die Standorte und das Datum leer lassen?',
      answer: 'Manchmal ist es Xtayees egal, ob Sie die Artikel aus dem Land A oder Land B mitbringen oder wann Sie sie liefern können, solange es sich um authentische Produkte handelt und der Preis stimmt. Anfragen und Angebote mit bestimmten Standorten und Daten bekommen in unseren Suchergebnissen jedoch einen höheren Rang.'
    },
    {
      question: 'Wie hoch sollen angemessene Versandkosten sein?',
      answer: 'Es hängt stark von der Route, der Jahreszeit und der Professionalität anderer Benutzer ab. Sie haben die Wahl, Ihren Preis zu nennen, zu verhandeln und den zu wählen, den Sie angemessen finden.'
    },
    {
      question: 'Warum kann ich keine neuen Anfragen/Angebote posten oder eine Transaktion mit jemandem starten?',
      answer: 'Bitte stellen Sie sicher, dass Sie Ihr Benutzerkonto erfolgreich aktiviert haben, indem Sie den Anweisungen folgen, die an Ihre E-Mail-Adresse versendet wurden.'
    },
    {
      question: 'Muss ich unbedingt ein neues Benutzerkonto eröffnen?',
      answer: 'Sie können sich auch mit Ihrem Facebook-Konto anmelden.'
    },
    {
      question: 'Kann ich Ihren Service ohne ein Benutzerkonto nutzen?',
      answer: 'Wenn Sie auf "Ohne Konto verwenden" klicken, können Sie alle Inhalte unseres Dienstes einsehen. Sie können jedoch nichts posten oder andere Benutzer kontaktieren, ohne sich tatsächlich anzumelden.'
    },
    {
      question: 'Werde ich bestraft, wenn ich das Geschäft außerhalb von XtayPro abgeschlossen habe?',
      answer: <>
                Natürlich nicht. Wir haben diese Plattform entwickelt, um allen zu helfen, miteinander in Kontakt zu treten und einander zu helfen, nicht um Benutzer zu horten, da wir auch unseren eigenen Service nutzen werden. Wir empfehlen jedoch dringend, dass Sie Ihre Transaktionen mit unserem Service abschließen, da:
                <ul>
                  <li>Nach jeder erfolgreichen Transaktion erhalten Sie in Ihrem Profil eine Bewertung. Basierend auf dieser Bewertung können andere Nutzer grobschätzen und sich entscheiden, zukünftig mit Ihnen Geschäfte zu machen und umgekehrt.</li>
                  <li>Wir werden die Beweise haben, um Ihnen im Falle von Betrug oder Streit zu helfen. Ein Chatverlauf oder Screenshot außerhalb unseres Systems können wir als Beweise leider nicht betrachten. Ohne konkrete Beweise werden wir Ihnen nicht helfen können.</li>
                  <li>Sie können unseren ESCROW-Service für Ihre Transaktionen verwenden, bei denen wir vorübergehend einen Geldbetrag von einer oder beiden Seiten halten, bis die Transaktion als erfolgreich bestätigt wurde.</li>
                </ul>
              </>
    },
    {
      question: 'Können wir wirklich die Bedingungen einer Transaktion verhandeln?',
      answer: <>Das ist natürlich der Kern unseres Service. Es steht Ihnen frei, die Bedingungen jeder Transaktion zu verhandeln, <strong>bevor</strong> Sie die ACCEPT-Taste drücken. Nachdem beide Seiten akzeptiert haben, sind Sie verpflichtet, Ihre Worte zu halten!</>
    },
    {
      question: 'Wie kann ich sicherstellen, dass andere Benutzer ehrlich sind und mich nicht betrügen werden?',
      answer: <>Überprüfen Sie bitte ihre Profile, um zu sehen, wie viele Transaktionen erfolgreich ausgeführt wurden und wie andere Benutzer ihren Service bewerten. Ehrliche Benutzer neigen dazu, eine anständige Selbsteinleitung zu schreiben und sind bereit, mehr über sich selbst zu erzählen. Eine gute Faustregel ist, ihren Benutzerrang zu überprüfen. Normalerweise ist ein Benutzer mit dem Rang Gold glaubhaft genug. Diese <a href="/ranking" target="_blank">link</a> zeigt, wie sich die Benutzerränge unterscheiden. Wenn Sie immer noch ganz sicher sein wollen, dass nichts schief geht, nutzen Sie unseren ESCROW-Service, das hierunten im Detail beschrieben wird.</>
    },
    {
      question: 'Was ist der ESCROW Service?',
      answer: <>Um beide Seiten zu schützen und Kreditkartenbetrug zu verhindern, bietet XtayPro den ESCROW Service an, in dem der Transaktionsbetrag sicher in XtayPro's Bankkonto aufbewahrt wird, bis die Transaktion erfolgreich abgeschlossen ist. Im Streitfall wird XtayPro jeden Fall sorgfältig prüfen und diesen Betrag zum Ausgleich der Schäden verwenden. Derzeit wird dieser Service kostenlos zur Verfügung gestellt. Bitte für Zahlungsreferenz folgende Angabe benutzen: [Transaktion ID] [Benutzer-ID][Zusatzinformationen falls vorhanden]. Bitte diesen <a href="/escrow" target="_blank">Link</a> für unseren Bankverbindungen.</>
    },
    {
      question: 'Wie bekomme ich den ESCROW-Betrag zurück?',
      answer: 'Wenn Sie unseren Escrow-Service zum ersten Mal nach der Transaktion nutzen, werden wir Sie kontaktieren und um Ihre Bankverbindung bitten, diesen Betrag zurück zu überweisen. Für weitere Transaktionen von Ihnen wird dieser Vorgang automatisch durchgeführt. Sie können auch Bargeld erhalten, wenn Sie zu unseren Sammelstellen kommen. Trotzdem bevorzugen wir Banküberweisungen.'
    },
    {
      question: 'Ich habe fälschlicherweise eine Transaktion und/oder einen anderen Benutzer gemeldet. Ist es schlimm?',
      answer: 'Wir glauben, dass es nicht Ihre Absicht war. Wir bitten um Ihr Verständnis, dass wir Falschmeldungen und Betrug sehr ernst nehmen. Wenn Sie immer wieder denselben Fehler machen, müssen wir Ihr Konto sperren, um die Integrität unserer Community zu schützen.'
    },
    {
      question: 'Woher weiß ich, dass der Shipper mir echte Produkte und keine gefälschten Produkte aus irgendeinem Land geben wird?',
      answer: <>Wenn Sie den Shipper bitten, für Sie einzukaufen und zu versenden, fragen Sie bitte nach dem Originalbeleg. Bevor der Shipper zu Ihnen reist, sollen Sie ein Foto von der Quittung anfordern, vorzugsweise mit dem Gesicht des Shippers darauf. Stellen Sie sicher, dass das Foto in unserer App geteilt wird, sonst können wir Ihnen im Streitfall nicht weiterhelfen. Wenn der Shipper den Artikel nur liefert ohne ihn für Sie einzukaufen, sollen Sie jemanden bitten, den Artikel irgendwie zu markieren, bevor er ihn übergibt. Dann suchen Sie dieses Zeichen, sobald Sie den Artikel erhalten haben. Letztendlich überprüfen Sie bitte die Profile und Benutzerrang immer sorgfältig, bevor Sie verhandeln, um Ihr Leben ein wenig einfacher zu machen! Mit unserer <strong>Null-Toleranz-Politik</strong> gegenüber Betrug sind wir verpflichtet, Betrüger nach nur einem einzigen Verstoß zu bestrafen.</>
    },
    {
      question: 'Was ist mit Steuern und Zollgebühren?',
      answer: 'Sie sollten dies berücksichtigen, wenn Sie mit der anderen Partei verhandeln. Normalerweise werden sich die Xtayers darum kümmern und diese Ausgaben in ihren Dienst aufnehmen. Der Preis, den beide Seiten beim Drücken der Taste ACCEPT vereinbaren, sollte der Endpreis sein.'
    },
    {
      question: 'Die Sendung wird vom Zoll angehalten, was soll ich tun?',
      answer: 'Bevor Sie eine Sendung in ein anderes Land bringen oder ihn annehmen, machen Sie sich bitte mit den Gesetzen und Vorschriften aller beteiligten Länder vertraut. Fordern oder bringen Sie bitte keine illegalen Waren und beachten Sie bitte die Gesetze. Wir werden auf jeden Fall persönlich moderieren und sehen, ob wir Ihnen helfen können.'
    },
    {
      question: 'Was passiert, nachdem ich den Artikel geliefert/abgeholt habe?',
      answer: 'Nach Lieferung/Abholung des Artikels, sollten beide Seiten sicherstellen, dass alles gemäß den Bedingungen der Transaktion ausgeführt wurde, einschließlich die Aktualität der Lieferung, Qualität des Artikels, Versandgebühren. Wenn Sie die FINISH-Taste drücken, betrachten wir diese Transaktion als erfolgreich abgeschlossen und geben die Kaution an Sie zurück, wenn Sie unseren ESCROW-Service genutzt haben. Wenn Sie mit dem anderen Benutzer zufrieden sind, sollten Sie ihm eine 5-Sterne-Bewertung und ein positives Feedback geben. Der Shipper sollte Sie gleich so nett bewerten!'
    },
    {
      question: 'Ich habe gehört, dass ich jemanden werben kann und beide werden eine Belohnung bekommen?',
      answer: <>Ja, aber es kommt auf unsere Werbekampagnen an. Bitte überprüfen Sie für weitere Informationen auf unserer <a href="/rewards" target="_blank">Website</a>.</>
    },
    {
      question: 'Wie bekomme ich eine User Rank Promotion?',
      answer: <>Momentan können Sie sich für eine Rangspromotion entweder per E-Mail oder Telefon bewerben. Wenn Sie E-Mails bevorzugen, senden Sie diese bitte an <a href="mailto:admin@xtaypro.com">admin@xtaypro.com</a> mit dem Betreff [XtayPro rank promotion request] und geben Sie Ihre UserID an. Wir werden Sie dann mit detaillierten Anweisungen kontaktieren. Wir verwenden auch viele beliebten Nachrichtendienste wie Facebook, Zalo, Viber, LINE, WhatsApp usw. Unsere Telefonnummer lautet +841234567890. Unsere Facebook-Seite finden Sie unten <a href="https://www.facebook.com/xtaypro.com" rel="nofollow noopener noreferrer" target="_blank">www.facebook.com/XtayPro.com</a></>
    },
    {
      question: 'Ich poste meine Reisen und Anfragen in der Regel auf Facebook, jetzt muss ich einen weiteren Beitrag auf Ihrer Plattform veröffentlichen?',
      answer: 'Nein! Wir sparen Ihnen den Ärger und ermöglichen Ihnen gleichzeitig, auf XtayPro und anderen sozialen Netzwerken zu posten. Sie müssen Ihre Beiträge nur teilen, indem Sie in unserer App auf die hilfreiche Schaltfläche "Teilen" klicken. Wir arbeiten auch mit vielen Facebook-Gruppen zusammen, um Ihre Wahrscheinlichkeit zu erhöhen, ein Match zu bekommen.'
    },
    {
      question: 'Ihre App ist cool und ich möchte Ihnen weitere Funktionen vorschlagen. An wen kann ich mich wenden?',
      answer: <>Sie können uns eine E-Mail an <a href="mailto:info@xtaypro.com">info@xtaypro.com</a> mit dem Betreff [XtayPro improvement] senden. Sie können uns auch über Facebook Messenger unten <a href="https://facebook.com/xtaypro.com" target="_blank" rel="nofollow noopener noreferrer">https://facebook.com/xtaypro.com</a> oder andere Nachrichtendienste unter +84987654321 erreichen. Da wir auch unseren eigenen Service nutzen, schätzen wir Ihr Feedback, um es besser zu machen!</>
    },
    {
      question: 'Ich versende ein Geburtstagsgeschenk an einen Freund und der Shipper will es auspacken. Hat er Recht?',
      answer: 'Es liegt ganz an Ihrer Verhandlung, bevor Sie die ACCEPT Taste drücken. Wenn der Shipper nicht explizit zugestimmt hat, das Päckchen nicht zu öffnen, muss er es trotzdem vor dem Flug zu seiner eigenen Sicherheit gemäß den Vorschriften und Gesetzen auspacken. Bitte fühlen Sie sich nicht beleidigt, wir sind sicher, dass der Shipper nur so wegen eigener Sicherheit handelt.'
    },
    {
      question: 'Ihr Geschäftsmodell ist dem von Uber recht ähnlich, worin liegt der Unterschied?',
      answer: 'Interessante Frage. Es ist nicht alltäglich, dass wir diese Art von Frage erhalten und es liegt außerhalb des Rahmens dieser FAQ. Diese Frage allein verdient einen vollständigen Beitrag, aber da Sie gefragt haben, können wir einen offensichtlichen Unterschied hervorheben: die Abwertung des Vermögenswerts (und Opportunitätskosten, wenn Sie darin wirklich vertieft sind, aber lass uns es einfach halten). Wenn jemand mit Uber arbeitet, kann er frei wählen, wann er eine Fahrt anbietet, solange sein Zeitplan es zulässt. Aber wenn Sie reisen und Ihre Freigepäckmenge nicht aufgebraucht haben, verschwenden Sie sie. In vielen Fällen wissen wir, dass es sich sogar lohnen wird, zusätzliches Freigepäck zu kaufen um mehr Gegenstände mitzubringen.'
    },
    {
      question: 'Muss ich meinen echten Namen verwenden?',
      answer: 'Das sollten Sie machen. Es sind im Allgemeinen bewährte Praktiken, wenn Sie in Ihrem Profil echte Namen verwenden und weitere Informationen über Sie oder Ihre Firma angeben. Es hilft, Vertrauen aufzubauen und unsere Gemeinschaft zu stärken.'
    },
    {
      question: 'Erweitern Sie Ihr Affiliate-Netzwerk?',
      answer: 'XtayPro strebt danach, seinen Nutzern größtmöglichen Komfort zu bieten. Ja, es wäre nett, wenn wir zusammenarbeiten könnten. Wenn Sie an unserem Geschäftsmodell interessiert sind, wenden Sie sich bitte direkt an unser Management.'
    },
    {
      question: 'Jemand in meiner Gegend fragt nach einem Produkt, das ich momentan auf Lager habe. Kann ich sie ansprechen, um sie direkt zu verkaufen?',
      answer: 'Warum nicht? Es spart Ihnen die Lieferzeit und möglicherweise auch zusätzliche Kosten, sodass Sie Ihr Produkt schneller verkaufen können. Bitte denken Sie daran, dass wir eine Null-Toleranz-Politik gegen Betrug haben.'
    },
    {
      question: 'Hat Ihr Service eine direkte Bedrohung für die derzeitigen Postdienste?',
      answer: 'Gute Frage! Wir sind der Meinung, dass sich unser Service auf die Versandmethoden auswirken wird, aber es hängt stark davon ab, wie die aktuellen Logistikunternehmen darauf reagieren und sich darauf einstellen werden. Es ist ähnlich wie kostenlose Instant-Nachrichtendienste wie Viber, WhatsApp, etc. die Mobilfunkanbieter beeinflusst haben. Allerdings sollten Mobilfunkanbieter die Möglichkeit erkennen, ihren Umsatz aus Datenpaketen zu steigern und kostenlose Nachrichtendienste in diesem Sinne als strategische Partner zu betrachten. Wir heißen also alle Postdienste willkommen, wenn sie unsere langfristigen strategischen Partner werden wollen.'
    },
    {
      question: 'Ihre Lösung klingt toll, aber fördern Sie damit Schmuggel und andere illegalen Aktivitäten?',
      answer: 'Nein. Das ist genauso wie der Fall, wenn Terroristen Facebook-Messenger und G-mail zum Kommuzieren verwenden, werden Facebook und Google für die "Unterstützung des Terrorismus" verantwortlich gemacht. Oder Wüsthof zur Rechenschaft ziehen, wenn jemand mit seinem Brotmesser einen anderen angreift!'
    }
  ]
}
