import React from 'react';
import CustomsList from '../../components/CustomsList/CustomsList';
import { useTranslation, Trans } from 'react-i18next';

const Customs = () => {
  const { t } = useTranslation();

  return (
    <section className="section">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="titleSection">
              <h2>{t('customs.heading')}</h2>
              <div className="coloredLine" />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-offset-1 col-sm-10 col-xs-12">
            <p className="text-justify">
              <Trans i18nKey="customs.content1" />
            </p>
            <br/>
            <p className="text-justify">
              <Trans i18nKey="customs.content2" />
            </p>
            <br/>
            <p className="text-justify">
              <Trans i18nKey="customs.content3" />
            </p>
            <br/>
            <p className="text-justify">
              <Trans i18nKey="customs.content4" />
            </p>
            <br/><br/>
            <CustomsList />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Customs;
