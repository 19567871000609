import React from 'react';
import './LanguageSwitcher.css';
import { useTranslation } from 'react-i18next';
import { flags } from '../../config/constants';

const LanguageSwitcherItem = ({ locale, flagClass }) => {
  const { i18n } = useTranslation();

  return (
    <span className={`flag-icon flag-icon-${flagClass}`}
          onClick={() => i18n.changeLanguage(locale)} />
  );
}

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const currentLocale = i18n.language;
  const filteredFlags = flags.filter(flag => flag.locale !== currentLocale);
  const renderFlags = filteredFlags.map((flag, index) => (
    <LanguageSwitcherItem key={index}
                          locale={flag.locale}
                          flagClass={flag.flagClass} />
  ));

  return (
    <div className='version'>
      {renderFlags}
    </div>
  );
}

export default LanguageSwitcher;
