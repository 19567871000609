import React from 'react';

export default {
  expandButtonText: {
    false: 'View all answers',
    true: 'Close all answers'
  },
  promise: "As our users ask questions, we continue to update this FAQ section to serve you better!",
  faqs: [
    {
      question: 'What do “xtayer” and “xtayee” mean?',
      answer: 'A “xtayer” is someone who travels and carries the goods. A “xtayee” is someone who requests the delivery of some items. Usually, xtayees will also ask xtayers to purchase the items in their stead.'
    },
    {
      question: 'How do I search for someone who is going/requesting that route?',
      answer: <>Please refer to our detailed guides <a href="https://www.facebook.com/pg/xtayprocom/photos/?tab=album&album_id=1655150811167156" target="_blank" rel="nofollow noopener noreferrer">here</a>.</>
    },
    {
      question: 'I can’t find anyone who matches my search criteria, what to do now?',
      answer: <>Bạn có thể bấm nút X góc dưới bên phải để tạo đơn hàng/chuyến đi mới. Sẽ có người thấy và liên hệ với bạn sau thôi. Còn nếu không có thì đành “<span role="img" aria-label="emoji">🎼</span>Nghẹn ngào giây phút ta chấp nhận sống không cần nhauuu <span role="img" aria-label="emoji">♬</span>” thôi, hix</>
    },
    {
      question: 'What types of payment does XtayPro support?',
      answer: 'We don’t actually take part in the payment process. It is totally up to you and the other party; you can pay cash on delivery, you can do bank transfer in advance, you can even ask for just a cup of coffee and a tour around the city by the other person. After all, this platform is built to connect people and to share cultures. However, we do provide an ESCROW service to protect your transactions, which is described in details below.'
    },
    {
      question: 'I have found someone, how do I contact them?',
      answer: <>When you see a request/offer that matches your criteria, you can scroll to the bottom of the screen and click the button that says “Start negotiating with this user”. Don’t worry, before clicking the ACCEPT button inside, you are <strong>not obliged</strong> to do anything at all.</>
    },
    {
      question: 'Where should I meet them for collection/delivery?',
      answer: 'The safest places to meet up are public spaces such as airports, cafés, parks etc. You can also collect/drop off the items at our collection centers.'
    },
    {
      question: 'Can I leave the locations and the dates blank?',
      answer: 'As a matter of facts, sometimes xtayees don’t care whether you get the items from country A or country B or when you can deliver them, as long as they are genuine products and the price is right. However, requests and offers with specified locations and dates will have higher priority in our search results.'
    },
    {
      question: 'What should be the reasonable shipping fees?',
      answer: 'It depends greatly on the route, the time of the year and the professionalism of other users. You are free to name your price, negotiate and choose the one you like the most.'
    },
    {
      question: 'Why can’t I post new requests/offers or start a transaction with someone?',
      answer: 'Please make sure that you have successfully activated your account by following the instructions sent to your email address.'
    },
    {
      question: 'Do I have to register a new account?',
      answer: 'You can also sign in using your Facebook account. We treat our users all the same.'
    },
    {
      question: 'Can I use your service without any accounts at all?',
      answer: 'Clicking on “Use without an account” enables you to view all content of our service. However, you will not be able to post anything or contact other users without actually logging in.'
    },
    {
      question: 'Will I get penalized for finishing the business outside XtayPro?',
      answer: <>
                Of course not. We’ve built this platform to help everyone get in touch and help each other, not to hoard users, since we will also use our own service. However, we strongly suggest that you complete your transactions using our service, because:
                <ul>
                  <li>You will get a +1 in your profile after every successful transaction. Based on this rating, other users will be able to evaluate and choose to do business with you, and vice versa.</li>
                  <li>We will have the evidence to assist you in case of fraud or dispute. For how otherwise can we solve a case with only a chat log or a screenshot not from our system?</li>
                  <li>You can use our ESCROW service for your transactions in which we will temporarily hold an amount of money from one or both sides until the transaction is confirmed successful.</li>
                </ul>
              </>
    },
    {
      question: 'Can we really negotiate the terms of a deal?',
      answer: <>Of course, that’s the essence of our service. You are free to negotiate the terms of each transaction <strong>before pressing</strong> the ACCEPT button. After both sides have accepted, you are obligated to keep your words!</>
    },
    {
      question: 'How can I be sure that the other party is honest and will not cheat me?',
      answer: <>Check their profiles to see how many transactions they have successfully carried out and what other users are talking about them. Also, honest users tend to write a decent self-introduction and are willing to share more about themselves. A good rule of thumb is to check their user rank; usually, a user of rank Gold is safe enough to do transactions with. Click <a href="ranking" target="_blank">here</a> to see how user ranks differ. If you still want to be completely sure that nothing will go wrong, use our ESCROW service, which is described in details below.</>
    },
    {
      question: 'How exactly does the ESCROW method work?',
      answer: <>It’s simple: Both parties will deposit an amount of money into our bank accounts for safekeeping until the transaction is complete. It may be 20% or 100% of the item’s value, depending on what you agree with each other. After the transaction has been carried out successfully, XtayPro will give the full amount back to both users, totally free of charge. When you transfer the deposit to us, please write the description according to this structure: Xtaypro TransactionID YourUserID. Click <a href="/escrow" target="_blank">here</a> to see our bank accounts.</>
    },
    {
      question: 'How do I get back the escrow amount?',
      answer: 'If you are using our escrow service for the first time, after the transaction, we will contact you and ask for your bank account details to transfer back this amount. For further transactions from you, this process will happen automatically. You can also choose to receive cash if you come to our collection centers. We still prefer bank transfer though.'
    },
    {
      question: 'I have mistakenly reported a transaction and/or another user. Is it bad?',
      answer: '*frowns* Hmm, we believe that it was not your intention to do so. However, please do understand that we treat false reports and fraud very seriously. If you continue to make the same mistake over and over again, we’ll be forced to lock your account to protect the integrity of our community.'
    },
    {
      question: 'How can I know that they will give me genuine goods and not fake products from some country?',
      answer: <>If you’re asking them to purchase and ship for you, ask for the original receipt. Even before they travel to you, ask them to take a photo of the receipt after getting the item, preferably with their face in it. Make sure that the photo is sent within our app, or else we will not be able to help you in case of dispute. If you’re asking them to ship an item to you without purchasing, ask someone to place a mark on the item somehow before they get it and check for this mark when you receive the item. Again, check their profiles and user rank first before dealing to make your life a bit easier! With our <strong>zero-tolerance</strong> policy towards fraud, we are bound to punish cheaters after just ONE violation.</>
    },
    {
      question: 'What about taxes and customs fees?',
      answer: 'You should take this into consideration when dealing with the other party. Normally, xtayers will take care of it and include these expenses into their service. The price that both sides agree upon when pressing the ACCEPT button should be the final price.'
    },
    {
      question: 'The items are held by the customs, what should we do?',
      answer: 'Before requesting or accepting to bring any item to another country, please make yourself familiar with the laws and regulations of all countries involved. Do not request or bring illegal goods and please abide by the laws. We will moderate each case personally and see if there is anything we can help you with.'
    },
    {
      question: 'What happens after I have delivered/collected the item?',
      answer: 'After delivering/collecting the item, both sides should make sure that everything has gone according to the terms of the transaction, including but not limited to, timeliness of the delivery, quality of item, shipping fee. When you press the FINISH button, we will consider this transaction successfully completed and proceed to give back the deposit to you if you have used our ESCROW service. If you are content with the other user, consider giving them a 5-star rating and a positive feedback. They should also rate you the same for being nice!'
    },
    {
      question: 'I’ve heard that you can refer someone and both will get a reward?',
      answer: <>Yes, but it depends on our promotion campaigns. Please check for more info <a href="/rewards" target="_blank">here</a>.</>
    },
    {
      question: 'How do I get a user rank promotion?',
      answer: <>At this moment, you can apply for a rank promotion either by email or telephone. If you prefer emails, please send one to <a href="mailto:admin@xtaypro.com">admin@xtaypro.com</a> with the subject [XtayPro rank promotion request] and provide your UserID, we will contact you back with detailed instructions. We are also using many popular messaging service such as Facebook, Zalo, Viber, LINE, Whatsapp etc. Our phone number is +841234567890. Our Facebook Page is <a href="https://www.facebook.com/xtaypro.com" rel="nofollow noopener noreferrer" target="_blank">www.facebook.com/XtayPro.com</a></>
    },
    {
      question: 'I usually post my trips and requests on Facebook, now I have to post another one on your platform? Save me the hassle!',
      answer: 'No! We are actually saving you the hassle while enabling you to dual-post on XtayPro and other social networks as well. You just have to share your posts by clicking the helpful little Share button on our app. We are also working with many Facebook groups to increase your probability of getting a match.'
    },
    {
      question: 'Your app is cool and I want to suggest some more features, who do I contact?',
      answer: <>You can drop us an email at <a href="mailto:info@xtaypro.com">info@xtaypro.com</a> with the subject [XtayPro improvement]. You can also reach us via Facebook messenger at <a href="https://facebook.com/xtaypro.com" target="_blank" rel="nofollow noopener noreferrer">https://facebook.com/xtaypro.com</a> or other instant messaging services at +84987654321. Since we also use our own service, we do appreciate your feedback to make it better!</>
    },
    {
      question: 'I am sending a birthday present to a friend and they want to unwrap it?',
      answer: 'Well it is totally up to your negotiation before hitting the ACCEPT button. But unless the other party has specifically agreed not to open it, they will often have to unwrap it before the flight for their own safety, according to regulations and laws. Please do not feel offended, we’re sure they don’t mean any.'
    },
    {
      question: 'Your model sure looks like Uber, what’s the difference?',
      answer: 'Oh, this is an interesting one. It’s not everyday that we receive this kind of question and it’s out of the scope of this FAQ. This question alone deserve a full presentation, but since you’ve asked, we can point out an obvious one: the depreciation of asset (and cost of opportunity if you delve enough into it, but let’s keep it simple). When someone works with Uber, they can freely choose when to offer a ride as their schedule allows. But when you travel and are not using up your baggage allowance, you are wasting it. In many cases that we know, it’s even worth paying for extra baggage.'
    },
    {
      question: 'Do I have to use my real name?',
      answer: 'You should. It is generally a good practice to use real names and give some more information about you or your company in your profile. It helps build up trust and strengthen our community.'
    },
    {
      question: 'Are you expanding your affiliate network?',
      answer: 'XtayPro strives to provide its users with the greatest degree of convenience possible. So, yes, it would be our pleasure to work with you. If you are interested in our business model, please contact our Management directly.'
    },
    {
      question: 'Someone in my area is requesting a product that I currently have in stock. Can I approach them to sell it directly?',
      answer: 'We don’t see why not. It saves them the shipping time and possibly some additional costs as well, so they might opt to buy it from you. Just please remember that we have a ZERO-tolerance policy against cheating and frauds.'
    },
    {
      question: 'Does your service pose a direct threat to current postal services?',
      answer: 'Yet another tricky question! We do believe that our service will have an impact on shipping methods, but it greatly depends on how current logistics companies will react and adapt to this. It is similar to how free instant messaging services such as Viber, Whatsapp, etc. have affected the cellular network providers. However, the latter should also see that as an opportunity to raise their revenue from data packages and in that sense, be a strategic partner to the former. We welcome all postal services to become our long-term strategic partners as well.'
    },
    {
      question: 'Your solution is neat, but does it encourage smuggling and other illegal activities?',
      answer: 'No. This is just like saying if terrorists use Facebook messenger and Gmail to communicate, Facebook and Google will be held responsible for “supporting terrorism”. Or holding Wüsthof accountable when someone assaults another one with their bread knife!'
    }
  ]
}
