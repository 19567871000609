import React from 'react';
import { useTranslation } from 'react-i18next';

const Ranking = () => {
  const { t } = useTranslation();

  return (
    <section className="section noselect">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="titleSection">
              <h2>{t('ranking.heading')}</h2>
              <div className="coloredLine"></div>
            </div>
          </div>
        </div>
        <div className="row">
          <img src="/images/XtayPro_userranks_05.png" className="img-responsive" alt="XtayPro Ranking" />
        </div>
        <div className="row">
          <img src="/images/XtayPro_userranks_07_greentick.png" className="img-responsive" alt="XtayPro Greentick" />
        </div>
      </div>
    </section>
  );
}

export default Ranking;
