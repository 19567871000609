import React from 'react';
import i18n from 'i18next';

import vi from './vi';
import en from './en';
import de from './de';
import kr from './kr';
import FaqItem from '../../components/FaqItem/FaqItem';

class FAQ extends React.Component {

  faqsData = { vi, en, de, kr };

  state = {
    allExpanded: false
  }

  toggleExpandAll = () => {
    this.setState(prevState => {
      return {allExpanded: !prevState.allExpanded}
    })
  }

  render() {
    const { allExpanded } = this.state;
    const { promise, expandButtonText, faqs } = this.faqsData[i18n.language];

    return (
      <section className="section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="titleSection">
                <h2>FAQs</h2>
                <p>{promise}</p>
                <div className="coloredLine"></div>
                <button className="btn-new xtay-background"
                        onClick={this.toggleExpandAll}>
                  {expandButtonText[allExpanded]}
                </button>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-8 col-md-offset-2 col-sm-10 col-sm-offset-1 col-xs-12">
              <div className="panel-group">
                {faqs.map((faq, index) => (
                  <FaqItem key={index}
                           question={faq.question}
                           answer={faq.answer}
                           allExpanded={allExpanded} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default FAQ;
