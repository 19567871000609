import React from 'react';
import Slider from 'react-slick';
import { useTranslation, Trans } from 'react-i18next';

const FbStartSection = () => {
  const { t } = useTranslation();

  return (
    <section className="fbstart-section">
      <div className="background">
        <div className="overlay-fbstart"></div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-sm-6 col-xs-12">
            <div className="align-slider">
              <Slider autoplay={true} arrows={false} dots={true} adaptiveHeight={true} className="fbstart-list slick-dotted">
                <div className="fbstart-item">
                  <h3>{t('home.fbstartTitle1')}</h3>
                  <p><Trans i18nKey="home.fbstartContent1" /></p>
                </div>
                <div className="fbstart-item">
                  <h3>{t('home.fbstartTitle2')}</h3>
                  <p>{t('home.fbstartContent2')}</p>
                </div>
                <div className="fbstart-item">
                  <h3>{t('home.fbstartTitle3')}</h3>
                  <p><Trans i18nKey="home.fbstartContent3" /></p>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FbStartSection;
