import React from 'react';
import './Escrow.css'
import InformationBlock from '../../components/InformationBlock/InformationBlock';
import { useTranslation, Trans } from 'react-i18next';

const Escrow = () => {
  const { t } = useTranslation();

  return (
    <section className="section" >
      <div className="container">
        <div className="row">
          <div className="title-custom">
            <p><Trans i18nKey="escrow.heading" /></p>
          </div>
        </div>

        <div className="row information-block-row">
          <div className="col-sm-12">
            <p>{t('escrow.explain')}</p>
          </div>
        </div>

        <div className="row information-block-row">
          <div className="col-sm-6">
            <img src={t('escrow.imageLink1')} style={{width: '100%'}} alt="escrow_1"/>
          </div>
          <div className="col-sm-6">
            <img src={t('escrow.imageLink2')} style={{width: '100%'}} alt="escrow_1"/>
          </div>
        </div>

        <hr style={{width: '50%', marginTop: '40px'}} />

        <div className="row information-block-row">
          <div className="col-xs-12">
            <p className="text-center" style={{marginBottom: '50px', color: 'black'}}>{t('escrow.listLogoText')}</p>
            <div className="col-xs-12 col-sm-6 col-md-4">
              <img class="img-responsive escrow-logo-item" src="/images/logo_wire.svg" alt=""/>
            </div>
            {/* <div className="col-xs-12 col-sm-6 col-md-4"> */}
            {/*   <img class="img-responsive escrow-logo-item" src="/images/logo_grabpay.png" alt=""/> */}
            {/* </div> */}
            <div className="col-xs-12 col-sm-6 col-md-4">
              <img class="img-responsive escrow-logo-item" src="/images/logo_megapay.png" alt=""/>
            </div>
            <div className="col-xs-12 col-sm-6 col-md-4">
              <img class="img-responsive escrow-logo-item"src="/images/logo_paypal.png" alt=""/>
            </div>
          </div>

          <div className="col-sm-8 col-sm-offset-2 col-xs-12" style={{marginTop: '100px'}}>
            <InformationBlock title={t('escrow.generalTitle')} bodyClass="custom">
              <Trans i18nKey="escrow.generalContent" />
            </InformationBlock>
          </div>

          <div className="col-xs-12" style={{marginTop: '50px'}}>
            <InformationBlock title={t('escrow.tipsTitle')}>
              <p><strong>{t('escrow.tipsHeader')}</strong></p>
              <ul style={{listStyleType: 'circle'}}>
                <li>{t('escrow.tip1')}</li>
                <li>{t('escrow.tip2')}</li>
                <li>{t('escrow.tip3')}</li>
                <li>{t('escrow.tip4')}</li>
              </ul>
            </InformationBlock>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Escrow;
