import React from 'react';
import { useTranslation, Trans } from 'react-i18next';

import DownloadButtonList from '../../../components/DownloadButtonList/DownloadButtonList';

const IntroSection = () => {
  useTranslation();

  return (
    <section id="intro" style={{backgroundImage: 'url(/images/hand_E.png)'}} alt="XtayPro">
      <div className="container">
        <div className="row">
          <img src="/images/hand_V.png" alt="XtayPro" className="intro-mobile img-responsive"/>
          <div className="col-md-6 col-md-offset-6 col-sm-8 col-sm-offset-4 col-xs-12 content">
            <h1><Trans i18nKey="home.welcome1" /></h1>

            <h3><Trans i18nKey="home.welcome2" /></h3>

            <p><Trans i18nKey="home.welcome3" /></p>

            <DownloadButtonList />
          </div>
        </div>
      </div>
    </section>
  );
}

export default IntroSection;
