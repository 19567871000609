import React from 'react';
import MemberCard from '../../components/MemberCard/MemberCard';
import members from './membersData'

const About = () => {
  return (
    <section className="section">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="titleSection">
              <h2>About Us</h2>
              <p>A brief introduction</p>
              <div className="coloredLine"></div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-offset-1 col-sm-10 col-xs-12">
            <p className="text-justify">
              The Earth doesn‘t stand still, so do people and good things happen when people travel, whether across the globe or towards their goals. Opportunities arise for everybody with our mobility.  And we want to make the most of not only of our mobility, but also of that spare luggage space we carry with us along the way. Founded in late 2016, XtayPro’s mission is to create a crowd-sourced delivery platform for small packages by tapping into the unused luggage space of travelers. We want to enable everyone to make use of their mobility and also create a world where people can build more human connection as they move around the world to all kinds of places in all kinds of way, every day.
            </p><br/>
            <h4><b>Small Parcels and Beyond</b></h4>
            <p className="text-justify">
              XtayPro leverages technology to economically empower travelers around the world to unlock and monetize their luggage spaces. In addition to helping people get their packages and small parcels from one place to another faster and cheaper than traditional logistics services, we're working to help people in the less developed countries fight problems of counterfeit and low-quality products, helping people get or bring products from overseas quickly and affordably, removing barriers to better products and goods, creating new freight-booking solutions, and helping travelers get a seamless travel experience with less hassle at airport transit and tax return declaration. Also with new human connections bridges via our platforms, people can make new friends and enjoy their trips in the most local, authentic, diverse ways. Logistics isn’t the only thing we’re changing through our people-to-people platform. We’re building a culture and a community that benefits all its members, including travelers, requesters, employees and the economies in which XtayPro operates.
            </p>
            <p className="text-justify">
              As an Acknowledgement for the goods we are bringing to the world via our platforms, we were recognized by Facebook with its Social Goods Distinction and we were selected by the South Korean Government as one of the Top 3 best startups out of 1770 Startups around the world participating in the K-Startup Grand Challenge 2018 competition.
            </p>
            <br/><br/>
          </div>
        </div>
        <div className="row">
          <div className="col-md-8 col-md-offset-2 col-sm-10 col-sm-offset-1 col-xs-12">
            <img src="/images/third.png" className="img-responsive" alt="XtayPro Third Prize"/>
          </div>
        </div>

        <br/><br/>

        <div className="row">
          <div className="col-md-12">
            <div className="titleSection">
              <h2>Members</h2>
              <div className="coloredLine" />
            </div>
          </div>
        </div>
        <div className="row text-center">
          <div className="col-md-12 col-md-offset-3">
            {members.slice(0, 2).map((member, index) => {
              const { name, roles, avatar, desc } = member;

              return <MemberCard key={index}
                                 name={name}
                                 roles={roles}
                                 avatar={{src: avatar.src,
                                         alt: avatar.alt}}
                                 desc={desc} />
            })}
          </div>
        </div>
        <div className="row text-center">
          {members.slice(2, 6).map((member, index) => {
            const { name, roles, avatar, desc } = member;

            return <MemberCard key={index}
                               name={name}
                               roles={roles}
                               avatar={{src: avatar.src,
                                       alt: avatar.alt}}
                               desc={desc} />
          })}
        </div>
        <div className="row text-center">
          {members.slice(6, 10).map((member, index) => {
            const { name, roles, avatar, desc } = member;

            return <MemberCard key={index}
                               name={name}
                               roles={roles}
                               avatar={{src: avatar.src,
                                       alt: avatar.alt}}
                               desc={desc} />
          })}
        </div>
      </div>
    </section>
  );
}

export default About;
