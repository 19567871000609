import React from 'react';

class InformationBlock extends React.Component {
  render() {
    const { backgroundImage, title, titleIconClass, bodyClass, children } = this.props,
      style = { backgroundImage: `linear-gradient(rgba(255,255,255,0.8),rgba(255,255,255,0.8)), url(${backgroundImage})` };

    return (
      <div className="information-block" style={style}>
        <div className="title">
          <h3>
            {title + (titleIconClass ? ' ' : '')}
            {titleIconClass && <i className={titleIconClass}></i>}
          </h3>
        </div>

        <div className={['content', bodyClass].join(' ')}>
          {children}
        </div>
      </div>
    );
  }
}

export default InformationBlock;
