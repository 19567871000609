export default [
  {
    name: 'Hieu Nguyen',
    roles: ['Founder', 'CEO'],
    avatar: {
      src: '/images/hieu.jpg',
      alt: 'hieu'
    },
    desc: 'Hieu Nguyen is Founder and CEO of XtayPro, the leading sharing economy platform for the transportation of small and medium-sized parcels, disrupting the market, globally and locally and will further push the globalization of products by tapping into the huge spare luggage space of travelers. Hieu is a dedicated leader with a dream to enable millions consumers having access to better, authentic products at a more affordable price.'
  },
  {
    name: 'Hoan Le',
    roles: ['Co-Founder', 'CTO'],
    avatar: {
      src: '/images/hoan.jpg',
      alt: 'hoan'
    },
    desc: "Hoan is in charge of developing XtayPro’s mobile applications and features roadmap for our platform and its development, as such, he knows the platform up to the cloud, down to pixel. Before co-founding XtayPro, Hoan completed his master degree in Computer Science in South Korea and worked on mobile applications development for Quantum Invention in Singapore."
  },
  {
    name: 'Cang Ta',
    roles: ['Co-Founder', 'Chief Systems Architect'],
    avatar: {
      src: '/images/cang.jpg',
      alt: 'cang'
    },
    desc: "Cang leads all of XtayPro's engineering teams including infrastructure and frontend development, working with designers and engineers to build the backbone of our platform. During his career, Cang has helped create web, desktop, and mobile applications for many businesses, including Uber and Grab."
  },
  {
    name: 'Long Nguyen',
    roles: ['Co-Founder'],
    avatar: {
      src: '/images/long.jpg',
      alt: 'long'
    },
    desc: "Long works with XtayPro’s leadership team to ensure departmental alignment across the company, including operations, business support, planning and analysis. He is also in charge of developing business in Western Europe. Previously, Long gathered experiences at some of the world's largest logistics corporations in Germany, USA and Japan."
  },
  {
    name: 'Thanh Nguyen',
    roles: ['Business Development Advisor'],
    avatar: {
      src: '/images/thanh.jpg',
      alt: 'thanh'
    },
    desc: "Thanh leads XtayPro’s strategic partnership initiatives as well as developing our business in Singapore market. He has years of experience working the market in Vietnam and Singapore as well as helped building Vatgia, one of the first and leading e-commerce platform in Vietnam."
  },
  {
    name: 'Mai Nguyen',
    roles: ['Product Manager'],
    avatar: {
      src: '/images/mai.jpg',
      alt: 'mai'
    },
    desc: "Aim to offer XtayPro's users the best products and experiences, Mai works closely with engineering, marketing, business and other of XtayPro customer related functions. She is responsible for product related business, incl. product strategy, road map, etc. Before joining XtayPro, Mai has gained extensive experiences in software engineering and quality management at Mercedes-Benz in Germany."
  },
  {
    name: 'Hiep Nguyen',
    roles: ['Operations Advisor'],
    avatar: {
      src: '/images/hiep.jpg',
      alt: 'hiep'
    },
    desc: "Hiep advises XtayPro’s executive team on legal, compliance, and privacy issues, product strategy as well as business opportunities, whereas he helps developing business in Germany. He has years of experience leading automation projects accross the globe."
  },
  {
    name: 'Kyue Young Wee',
    roles: ['Head of East Asia'],
    avatar: {
      src: '/images/kyue.jpg',
      alt: 'kyue'
    },
    desc: "Kyue Young is in charge of developing East Asia market for our platform. With in-depth knowledge of the culture gained from years living in South Korea, UK, Hong Kong, he and his team aligns product strategy with marketing execution to make our platform more user-friendly and tailored to suit the market' needs. He also speaks many languagues, which proves vital to building connections and partnerships in East Asian countries."
  },
  {
    name: 'Gitae Kim',
    roles: ['Head of South Korea'],
    avatar: {
      src: '/images/gitae.jpg',
      alt: 'gitae'
    },
    desc: "As managing director of our South Korea Branch, Gitea oversees all operations within the countries and is responsible for developing all aspects of the country orginization as well as cooperates with other regions to ultilize the best practices and create synergy."
  },
]
