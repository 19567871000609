import React from 'react';
import './NewsList.css';

const newsAPI = 'https://blog.xtaypro.com/wp-json/wp/v2/posts?_embed&per_page=100&categories=99';

const cutString = (s, n) => {
  const cut = s.indexOf(' ', n);
  if(cut === -1) return s;
  return s.substring(0, cut);
}

const decodeHtml = string => {
  const txt = document.createElement("textarea");
  txt.innerHTML = string;
  return txt.value;
}

const NewsItem = ({ news }) => {
  return (
    <div className="row"
         style={{marginBottom: '30px'}}>
      <div className="col-xs-5">
        <a href={news.link}
           target="_blank"
           rel="noopener noreferrer">
          <img className="img-responsive"
               src={news._embedded['wp:featuredmedia'][0].source_url}
               alt=""/>
        </a>
      </div>
      <div className="col-xs-7">
        <a href={news.link}
           target="_blank"
           rel="noopener noreferrer">
          <b>{decodeHtml(news.title.rendered)}</b>
        </a>
        <p style={{marginTop: '10px'}}>
          {decodeHtml(cutString(news.content.rendered.replace(/\[.*?\]|<[^>]*>/g, ""), 200) + '...')}
          <a href={news.link}
             target="_blank"
             rel="noopener noreferrer">
            <b>Xem thêm</b>
          </a>
        </p>
      </div>
    </div>
  );
}

class NewsList extends React.Component {
  state = {
    newsItems: [],
    isLoading: true
  };

  componentDidMount() {
    fetch(newsAPI)
      .then(response => response.json())
      .then(data => this.setState({
        newsItems: data,
        isLoading: false
      }));
  };


  render() {
    const { newsItems, isLoading } = this.state;

    if (isLoading) {
      return (
        <div className="text-center">
          <i className="fa fa-spinner fa-spin fa-3x fa-fw" />
        </div>
      );
    }

    return (
      <div className="news col-xs-10 col-xs-offset-1">
        {
          newsItems.map((news, index) => {
            return (
              <NewsItem key={index}
                        news={news}/>
            );
          })
        }
      </div>
    );
  }
}

export default NewsList;
